import React from "react";
import { CheckIconCircle } from "../../components/icons/CheckIconCircle";

export const IndividualsProfessionalDescList = () => {
  return (
    <div className={"flex justify-start flex-col"}>
     <div className={"flex justify-start flex-col"}>
        <div className={"flex justify-start"}>
        <span
          className={"justify-start text-[12px] text-[#83899F] text-center"}
        >
          Ideal for active investors with many transactions that require a closer look and extended services.
        </span>
      </div>
    </div>
    </div>
  );
};
