import React from "react";
import {ToggleWithText} from "../../components/ToggleWithText";
import {useNavigate} from "react-router";

export const TermsAndConditionsContentCompany = () => {

    const navigate = useNavigate()

    return (
        <div className={'justify-start w-full min-w-full p-4 md:p-0'}>
            <div className={'flex md:justify-end justify-center md:mb-0 mb-[30px]'}>
                <ToggleWithText
                    onChange={() => navigate('/legal/terms-and-conditions/company')}
                    firstOption="Individual"
                    secondOption="Company"
                    checked={true}
                    id="terms-company"
                    name="terms-company"
                />
            </div>


            <h1 className={'text-xl font-bold mb-3'}>General Terms and Conditions – B2C
                ("GTC")</h1>

            <h3 className={'text-base font-semibold mb-3'}>1. General</h3>
            <p className={'text-sm text-justify pb-5'}>1.1 3Folio GmbH, FN 572991i, Ernst Melchior Gasse 24/DG, 1020 Vienna, Austria, ("<b>3Folio</b>" or "<b>we</b>") offers crypto monitoring, accounting and reporting services via its proprietary platform accessible under the domain 3folio.finance (the "<b>Platform</b>").
            </p>
            <p className={'text-sm text-justify pb-5'}>1.2 The Platform consists of a web interface that facilitates the compilation, visualization and analysis of investment data, and 3Folio offers subscriptions to access and use the Platform.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.3 The use of the Platform by any consumer (<i>Verbraucher</i>) within the meaning of <a href={'https://www.ris.bka.gv.at/Dokumente/Bundesnormen/NOR12041200/NOR12041200.html'} target={'_blank'} className={'text-accent'}>Art 1 Austrian Consumer Protection Act</a> (<i>Konsumentenschutzgesetz</i>) (the "<b>Client</b>" or "<b>you</b>") is subject to these GTC and the terms of use of the Platform (the "<b>ToU</b>"), available at <a href={'https://3folio.finance/legal/terms-of-use'} target={'_blank'} className={'text-accent'}>3folio.finance/legal/terms-of-use</a>. If the Client does not agree to these GTC and/or the ToU during registration, the Client is not authorized, and it is not technically possible for the Client to use the Platform.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.4 3Folio and the Client are hereinafter collectively referred to as "<b>Parties</b>", and each individually as "<b>Party</b>".
            </p>

            <h3 className={'text-base font-semibold mb-3'}>2. Services</h3>
            <p className={'text-sm text-justify pb-5'}>2.1 The functionalities accessible via the Platform depend on the type of subscription chosen by the Client and are described in detail at <a href={'https://www.3folio.finance/pricing'} target={'_blank'} className={'text-accent'}>www.3folio.finance/pricing</a> (the "<b>Services</b>").
            </p>
            <p className={'text-sm text-justify pb-5'}>2.2 The Platform is still under development. 3Folio is therefore entitled to change or modify the Services, including the user interface of the Platform, provided that all originally agreed upon functionalities of the Services remain in place.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.3 After concluding am agreement with 3Folio on the basis of these GTC (the "Agreement"), the Client will receive a contract confirmation via email. This email will include all relevant contractual information, enabling the Client to review the contractual conditions and the features included in the Client's subscription at any time.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>3. Registration, Subscriptions and User Accounts
            </h3>
            <p className={'text-sm text-justify pb-5'}>3.1 In order to access the Platform and use the Services, the Client must register and set up a user account (the "<b>User Account</b>"). During the registration process, the Client will be asked to enter his personal information, select a subscription type, and accept these GTC and the ToU by ticking the corresponding checkboxes. Before completing the registration process, the Client will be provided with an overview of his personal and contractual details and the opportunity to correct any errors.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.2 By pressing the button "order with obligation to pay" the Client confirms his entries and is forwarded to the payment service provider. After completion of the payment process, a legally binding contract is concluded.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.3 The Client will then receive an email with a confirmation link to the email address provided by the Client. By clicking on the confirmation link, the Client will be redirected to the Platform. This will complete the registration process and the Client's User Account will be created in the 3Folio system. The Client will receive from 3Folio a confirmation of the conclusion of the Agreement in accordance with section 3.2 above to the email address provided by the Client.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.4 3Folio grants to the Client a subscription (the "<b>Subscription</b>") which entitles the Client to access and use the Platform and Services with the Client's User Account in accordance with the type of subscription selected by the Client.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.5 The Client may upgrade the subscription type via the Platform.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.6 The Client may only register a User Account in his own name.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.7 The Client can update his personal details at any time via the Platform's settings menu. If the Client knows or suspects that his User Account has been misused, the Client may notify 3Folio at the following email address: <a href={'mailto:legal@3folio.finance'} className={'text-accent'}>legal@3folio.finance</a>
            </p>

            <h3 className={'text-base font-semibold mb-3'}>4. Withdrawal
            </h3>
            <p className={'text-sm text-justify pb-5'}>4.1 Right of withdrawal
            </p>
            <p className={'text-sm text-justify pb-5'}>You have the right to withdraw from the Agreement within 14 days without giving any reason. The withdrawal period will expire after 14 days from the day of the conclusion of the Agreement. To exercise the right of withdrawal, you must inform us (3Folio GmbH, FN 572991i, Ernst Melchior Gasse 24/DG, 1020 Vienna, Austria, +43 676 366 80 26,  <a href={'mailto:info@3folio.finance'} className={'text-accent'}>info@3folio.finance</a>) of your decision to withdraw from the Agreement by an explicit statement to that effect (sent eg by letter via post or e-mail or by phone). You may use the attached model withdrawal form <a className={'text-accent'} href={`${process.env.PUBLIC_URL}/pdf/GTC_B2C_3FOLIO_V1_ANEX1.pdf`} download>Annex 1</a>, but that is not obligatory. To meet the withdrawal deadline, it is sufficient for you to send your communication concerning your exercise of the right of withdrawal before the withdrawal period has expired.
            </p>
            <p className={'text-sm text-justify pb-5'}>4.2 Effects of withdrawal
            </p>
            <p className={'text-sm text-justify pb-5'}>If you withdraw from this Agreement, we shall reimburse to you all payments received from you, including the costs of delivery (with the exception of the supplementary costs resulting from your choice of a type of delivery other than the least expensive type of standard delivery offered by us), without undue delay and in any event not later than 14 days from the day on which we are informed about your decision to withdraw from this Agreement. We will carry out such reimbursement using the same means of payment as you used for the initial transaction, unless you have expressly agreed otherwise; in any event, you will not incur any fees as a result of such reimbursement. If you requested to begin the performance of services during the withdrawal period, you shall pay us an amount which is in proportion to what has been provided until you have communicated us your withdrawal from this Agreement, in comparison with the full coverage of this Agreement.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>5. License
            </h3>
            <p className={'text-sm text-justify pb-5'}>For the term of the Subscription and subject to these GTC, 3Folio grants the Client a revocable, limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Platform and the Services as set out at <a href={'https://www.3folio.finance/pricing'} target={'_blank'} className={'text-accent'} >www.3folio.finance/pricing</a> OR in in the price plans section at the Platform.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>6. Workspaces and Financial Data
            </h3>
            <p className={'text-sm text-justify pb-5'}>6.1 Financial data provided on or through the Platform will be organized and displayed in workspaces (the "<b>Workspace(s)</b>").
            </p>
            <p className={'text-sm text-justify pb-5'}>6.2 Each Workspace is managed by at least one administrator (the "<b>Workspace Admin(s)</b>"). By default, the User Account which sets up a Workspace will be designated as its Workspace Admin. Workspace Admins manage access of User Accounts to the respective Workspace and may selectively restrict or grant permissions within the Workspace's settings and administrative areas.
            </p>
            <p className={'text-sm text-justify pb-5'}>6.3 Each User Account can be a member of multiple Workspaces. To join a Workspace, other User Accounts require an invitation from the responsible Workspace Admin.
            </p>
            <p className={'text-sm text-justify pb-5'}>6.4 Each Workspace functions as a self-contained unit. Financial data will not be automatically transferred or shared between Workspaces.
            </p>
            <p className={'text-sm text-justify pb-5'}>6.5 Financial data provided by 3Folio will be visually highlighted or otherwise recognizable on the Platform's interface to ensure differentiation from financial data provided by the Client.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>7. No Professional Advice
            </h3>
            <p className={'text-sm text-justify pb-5'}>7.1 The services of 3Folio to be rendered to the Client in the course of this contractual relationship (including the Services) do not include or constitute investment, legal or financial advice of whatsoever nature or any other (regulated or non-regulated) services that are explicitly the responsibility of a financial advisor, tax advisor, legal advisor, investment banker or accountant, and such services shall also not be construed accordingly.
            </p>
            <p className={'text-sm text-justify pb-5'}>7.2 Nothing on the Platform should be construed as a recommendation by 3Folio to buy, sell, or hold any particular asset or asset class.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>8. Pricing and Payment
            </h3>
            <p className={'text-sm text-justify pb-5'}>8.1 3Folio offers different subscription types. The detailed scope and price of each subscription type are specified at <a href={'https://www.3folio.finance/pricing'} target={'_blank'} className={'text-accent'}>www.3folio.finance/pricing</a>.</p>
            <p className={'text-sm text-justify pb-5'}>8.2 The Client shall make monthly payments within 14 days after receipt of the invoice from 3Folio.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>9. Term and Termination for Convenience
            </h3>
            <p className={'text-sm text-justify pb-5'}>9.1 Unless specified otherwise at <a href={'https://www.3folio.finance/pricing'} target={'_blank'} className={'text-accent'}>www.3folio.finance/pricing</a>, the Agreement shall be concluded for an indefinite period of time.</p>
            <p className={'text-sm text-justify pb-5'}>9.2 Either Party may terminate the Agreement for convenience by giving written notice at least one month prior to the end of any calendar month.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>10. Termination for Good Cause
            </h3>
            <p className={'text-sm text-justify pb-5'}>Either Party may further terminate the Agreement with immediate effect for good cause (<i>aus wichtigem Grund</i>).
            </p>

            <h3 className={'text-base font-semibold mb-3'}>11. Maintenance
            </h3>
            <p className={'text-sm text-justify pb-5'}>11.1 3Folio has the right to carry out maintenance work on weekdays in the period from 20:00 to 06:00 UTC and at weekends from 00:00 to 24:00 UTC (hereinafter: "<b>Maintenance Window</b>") for a total of 10 hours per calendar month. Activities in the Maintenance Window shall be announced to the Client in advance.
            </p>
            <p className={'text-sm text-justify pb-5'}>11.2 3Folio is entitled to postpone or extend the Maintenance Window in exceptional cases to eliminate or prevent serious faults.
            </p>
            <p className={'text-sm text-justify pb-5'}>11.3 During the Maintenance Window, the Platform may not be available or may have limited availability.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>12. Warranty
            </h3>
            <p className={'text-sm text-justify pb-5'}>12.1 The statutory warranty provisions shall apply.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.2 Nothing in these GTC shall be construed as limiting the Client's statutory warranty rights.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>13. Alternative Dispute Resolution
            </h3>
            <p className={'text-sm text-justify pb-5'}>The European Commission provides a platform for online dispute resolution, which can be found at <a href={'http://ec.europa.eu/consumers/odr/'} target={'_blank'} className={'text-accent'}>http://ec.europa.eu/consumers/odr/</a>. 3Folio is not obliged to participate in dispute resolution proceedings before a consumer arbitration board and does not participate voluntarily.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>14. Contact
            </h3>
            <p className={'text-sm text-justify pb-5'}>3Folio's contact information is:
            </p>
            <p className={'text-sm text-justify pb-5'}>3Folio GmbH, FN 572991i
            </p>
            <p className={'text-sm text-justify pb-5'}>Ernst Melchior Gasse 24/DG, 1020 Vienna, Austria
            </p>

            <p className={'text-sm text-justify pb-5'}>+43 676 366 80 26, <a href={'mailto:info@3folio.finance'} className={'text-accent'}>info@3folio.finance</a>
            </p>

            <h3 className={'text-base font-semibold mb-3'}>15. Final Provisions
            </h3>
            <p className={'text-sm text-justify pb-5'}>15.1 The Agreement and all rights and obligations of the Parties arising out of or in connection with the Agreement shall be governed by and construed in accordance with Austrian law excluding the conflict of law rules and excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG). This choice of law does not deprive the Client of the protection afforded to him by provisions that cannot be derogated from by agreement by virtue of the law of the country where the Client has his habitual residence. The applicability of such provisions remains unaffected by this choice of law.
            </p>
                <p className={'text-sm text-justify pb-5'}>15.2 These GTC can be accessed, saved, and printed out at any time under [<a className={'text-accent'} href={`${process.env.PUBLIC_URL}/pdf/GTC_B2C_3FOLIO_V1.pdf`} download>Download</a>].</p>
            <p className={'text-sm text-justify pb-5'}>15.3 The contractual language shall be English.
            </p>

            <p className={'text-sm text-justify pb-5 pt-6'}>January, 2024</p>

        </div>
    )
}