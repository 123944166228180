import React, { useState } from "react";
import { T } from "@tolgee/react";
import { CryptoFundsIcon } from "../../components/icons/CryptoFundsIcon";
import { IndividualIcon } from "../../components/icons/IndividualIcon";
import {
  FeaturedTabsListItem,
  ListItemIconType,
} from "../../components/FeaturesTabListItem";

export const FeaturesTabs: React.FC = () => {
  const [isIndividual, setIsIndividual] = useState(true);
  const defaultHoverImage = "funds-illustration.svg";
  const [hoverImage, setHoverImage] = useState(defaultHoverImage);

  type ListItemProps = {
    title: string;
    content: string;
    icon: string;
    hoverImage?: string;
    link?: string;
  };

  const CryptoFundsData = [
    {
      title: "features_card_1_title",
      content: "features_card_1_content",
      icon: ListItemIconType.CLIENT_MANAGEMENT,
      hoverImage: "funds-illustration.svg",
    },
    {
      title: "features_card_2_title",
      content: "features_card_2_content",
      icon: ListItemIconType.CLIENT_COMMUNICATION,
      hoverImage: "page-integrations.png",
    },
    {
      title: "features_card_3_title",
      content: "features_card_3_content",
      icon: ListItemIconType.FEE_CALCULATION,
      hoverImage: "contact.svg",
    },
  ];

  const IndividualFundsData = [
    {
      title: "individual_card_1_title",
      content: "individual_card_1_content",
      icon: ListItemIconType.ACTION_LOGGING,
      hoverImage: "funds-illustration.svg",
    },
    {
      title: "individual_card_2_title",
      content: "individual_card_2_content",
      icon: ListItemIconType.DATA_RECORDING,
      hoverImage: "page-integrations.png",
    },
    {
      title: "individual_card_3_title",
      content: "individual_card_3_scontent",
      icon: ListItemIconType.MONITOR_METHODS,
      hoverImage: "contact.svg",
    },
  ];

  const CryptoFundsView = () => {
    return (
      <div className="flex items-center justify-center gap-4 mb-8 pt-5">
        <div className="flex flex-col gap-3">
          {CryptoFundsData.map((item: ListItemProps, index: number) => (
            <FeaturedTabsListItem
              key={index + item.title}
              title={item.title}
              content={item.content}
              icon={item.icon}
              link={item.link}
              hoverImage={item.hoverImage}
              setHoverImage={setHoverImage}
            />
          ))}
        </div>

        <div className="hidden lg:flex items-center justify-center min-w-[530px]">
          <img src={`/DEMO/images/${hoverImage}`} className="max-w-[480px]" />
        </div>
      </div>
    );
  };

  const IndividualsView = () => {
    return (
      <div className="flex items-center justify-center gap-4 pt-5">
        <div className="flex flex-col gap-3">
          {IndividualFundsData.map((item: ListItemProps, index: number) => (
            <FeaturedTabsListItem
              key={index + item.title}
              title={item.title}
              content={item.content}
              icon={item.icon}
              link={item.link}
              hoverImage={item.hoverImage}
              setHoverImage={setHoverImage}
            />
          ))}
        </div>

        <div className="hidden lg:flex items-center justify-center min-w-[530px]">
          <img src={`/DEMO/images/${hoverImage}`} className="max-w-[480px]" />
        </div>
      </div>
    );
  };

  return (
    <div className="m-auto flex flex-col items-center justify-center px-4">
      <h3 className="text-2xl font-bold text-[#344054]">
        <T keyName="features_tab_heading" />
      </h3>
      <div className="flex gap-[6px] mt-5 mb-4 md:mb-14">
        <div
            onClick={() => {
              setIsIndividual(true);
            }}
            className={`flex items-center justify-center gap-2 rounded-[10px] cursor-pointer px-[20px] py-[10px] text-sm font-semibold ${
                isIndividual
                    ? "bg-[#275EF7] text-white"
                    : "text-[#485066] shadow shadow-[4px_5px_40px_0_rgba(74, 105, 165, 0.12)]"
            }`}
        >
          <IndividualIcon color={isIndividual ? "white" : "#485066"} />
          <T keyName="features_individuals" />
        </div>
        <div
          onClick={() => {
            setIsIndividual(false);
          }}
          className={`flex items-center justify-center gap-2 rounded-[10px] cursor-pointer px-[20px] py-[10px] text-sm font-semibold ${
            isIndividual
              ? "text-[#485066] shadow shadow-[4px_5px_40px_0_rgba(74, 105, 165, 0.12)]"
              : "bg-[#275EF7] text-white"
          }`}
        >
          <CryptoFundsIcon color={isIndividual ? "#485066" : "white"} />
          <T keyName="features_funds" />
        </div>
      </div>

      <div className="min-h-fit lg:min-h-[650px] mb-8">
        {isIndividual ? <IndividualsView /> : <CryptoFundsView />}
      </div>
    </div>
  );
};
