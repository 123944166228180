import React, {useRef, useState} from 'react';
import { useTolgee } from '@tolgee/react';
import {USFlagIcon} from "../icons/USFlagIcon";
import {DeutschFlagIcon} from "../icons/DeutschFlagIcon";
import {CheckBlackIcon} from "../icons/CheckBlackIcon";
import {useOnClickOutside} from "../../hooks/useOnClickOutside";

export const LangSelector: React.FC = () => {
    const tolgee = useTolgee(['language']);
    const wrapperRef = useRef(null)
    const [isActive, setIsActive] = useState(false)


    useOnClickOutside(wrapperRef, () => {
        if (isActive) setIsActive(false)
    })

    return (
        <>
            <div className="relative  -left-3 pl-4 py-3" ref={wrapperRef}>
                <div
                    className="flex justify-center items-center "
                    onClick={() => setIsActive(!isActive)}
                >
                    <div className="flex  items-center text-xs px-[5px]  font-semibold py-[2px] rounded">
                        <button
                            type="button"
                            className="flex w-fit items-center justify-between transition duration-75 rounded-lg group"
                        >

                            <span className=" ml-1 text-left text-sm font-semibold w-fit xl:w-[85px] text-ellipsis overflow-hidden">
								{tolgee.getLanguage() === 'en' ? <USFlagIcon /> : <DeutschFlagIcon />}
							</span>

                            <svg  className={` ml-3 top-5.5 absolute left-[50px] ${!isActive && '-rotate-180'} transition-all flex`} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M10.0137 6.25088C10.3052 6.25031 10.5877 6.35202 10.8121 6.53838L17.0494 11.5384C17.3041 11.7505 17.4643 12.0554 17.4947 12.386C17.5251 12.7165 17.4233 13.0456 17.2115 13.3009C16.9998 13.5561 16.6955 13.7167 16.3657 13.7472C16.0358 13.7776 15.7074 13.6756 15.4526 13.4634L10.0137 9.11338L4.57483 13.2634C4.44723 13.3672 4.30041 13.4447 4.1428 13.4915C3.9852 13.5383 3.81993 13.5534 3.65647 13.536C3.49302 13.5186 3.33462 13.469 3.19037 13.39C3.04611 13.311 2.91886 13.2043 2.81592 13.0759C2.70168 12.9473 2.61515 12.7966 2.56177 12.633C2.50839 12.4694 2.4893 12.2965 2.5057 12.1252C2.52209 11.9538 2.57362 11.7878 2.65705 11.6373C2.74049 11.4869 2.85403 11.3553 2.99056 11.2509L9.22783 6.46338C9.45871 6.30648 9.73541 6.23166 10.0137 6.25088Z" fill="#485066"/>
                            </svg>

                        </button>
                    </div>
                </div>
                <div
                    className={`${
                        !isActive && 'hidden'
                    } whitespace-nowrap absolute   -left-[80px] t-3 z-10 min-w-[170px] w-[170px] top-16 rounded-[8px] bg-white shadow-lang focus:outline-none`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                >
                    <div className="justify-start w-full flex-1 w-full" role="none">
                        <button
                            onClick={() => tolgee.changeLanguage('de')}
                            role="menuitem"
                            className="flex py-3 self-stretch  justify-start items-center px-4 text-xs transition-colors duration-200 rounded-md dark:text-gray-400 dark:hover:text-light hover:text-gray-700"
                        >
                            <DeutschFlagIcon />
                            <span className="flex pl-2.5 text-[14px] font-medium">Deutsch</span>
                            {tolgee.getLanguage() === 'de' && <span className={'flex items-center justify-end  pl-6'}>
                                <CheckBlackIcon />
                            </span>}
                        </button>
                        <button
                            onClick={() => tolgee.changeLanguage('en')}
                            role="menuitem"
                            className="flex flex-1 min-w-full w-full self-stretch py-3 justify-start items-center px-4 text-xs  transition-colors duration-200 rounded-md dark:text-gray-400 dark:hover:text-light hover:text-gray-700"
                        >
                            <USFlagIcon />
                            <span className="flex pl-2.5 text-[14px] font-medium">English</span>
                            {tolgee.getLanguage() === 'en' && <span className={'flex items-center justify-end text-end absolute right-4 pl-6'}>
                                <CheckBlackIcon />
                            </span>}
                        </button>
                    </div>
                </div>
            </div>
        </>
        // <select
        //     className="flex p-3"
        //     onChange={(e) => tolgee.changeLanguage(e.target.value)}
        //     value={tolgee.getLanguage()}
        // >
        //     <option className={'p-3'} value="en"><USFlagIcon /> English</option>
        //     <option value="de"><DeutschFlagIcon /> Deutsch</option>
        // </select>
    );
};