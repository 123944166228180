import React from "react";

import { EnterpriceSuiteList } from "../sections/price-list/EnterpriceSuiteList";
import {
  cryptoFundsCoreFunctions,
  cryptoFundsImportOptions,
  cryptoFundsAssetOptions,
  cryptoFundsFunctionsTracking,
  cryptoFundsPayPerUse,
  cryptoFundsFunctionsAccounting,
  cryptoFundsFunctionsReporting,
} from "../utils";
import { InfoCircleIcon } from "./icons/InfoCircleIcon";
import { ListItem } from "./ListItem";
import { PriceListCard } from "./PriceListCard";
import { StarterDescList } from "../sections/price-list/StarterDescList";
import { AdvancedPlanList } from "../sections/price-list/AdvancedPlanList";

type PriceListFundsProps = {
  isMonthly: boolean;
  referralId?: string;
};

export const CryptoFundsPriceList = ({
  isMonthly,
  referralId,
}: PriceListFundsProps) => {
  return (
    <div className="mx-auto flex max-w-screen-xl container w-full justify-center flex-row xl  max-h-max max-lg:px-2 pb-20">
      <div
        className="w-1/4 hidden md:flex flex-col mr-3 relative min-w-[307px] before:absolute before:top-[396px] before:h-[440px] before:bg-[#F5F7FD] before:-left-4 before:w-[110%] before:-z-10 before:border-y-2 before:border-y-[#9CB5FB]"
      >
        <div className="flex flex-col mt-12">
          <h3 className="text-[#373A45] font-bold pb-2">Featured Status</h3>
          <ListItem key={1} color={"#1AB200"} text={"Online"}></ListItem>
          <ListItem key={2} color={"#F76527"} text={"Beta Phase"}></ListItem>
          <ListItem key={3} color={"#686F87"} text={"Coming 2025"}></ListItem>
        </div>

        <div
          className="mt-[76px] pb-[30px] text-[16px] font-medium text-[#83899F]"
        >
          <span className="text-accent"> Please note! </span>
          All our fees and prices are before VAT.
          <br />
        </div>

        <div className="flex flex-col mt-[75px]">
          <>
            <h3 className="text-[#373A45] font-bold">Core Functions & Limits</h3>
            {cryptoFundsCoreFunctions.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`core-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <span className="font-bold pt-3 text-xs">
            * for supported countries AT, DE
            </span>
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Import Options
            </h3>
            {cryptoFundsImportOptions.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`import-options-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
             <span className="font-bold pt-3 text-xs">
             *please check our support level of integrations
            </span>
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Supported types of Asset
            </h3>
            {cryptoFundsAssetOptions.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`import-options-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Tracking Module
            </h3>
            {cryptoFundsFunctionsTracking.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`funcTracking-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Fund Module
            </h3>
            {cryptoFundsPayPerUse.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`funcReporting-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Accounting & Tax Module
            </h3>
            {cryptoFundsFunctionsAccounting.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`funcAccounting-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <span className="font-bold pt-3 text-xs">
              *for supported countries AT, DE
            </span>
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Reporting Module
            </h3>
            {cryptoFundsFunctionsReporting.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`funcReporting-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
          </>
        </div>
      </div>

      <div
        className="md:w-3/4 w-full flex md:flex-row flex-col overflow-x-auto"
      >
        <div className="w-full md:w-1/4 min-w-[230px]">
          <PriceListCard
            desc={<StarterDescList />}
            startup
            type="Crypto Funds Starter"
            packageName="Fund Pioneer"
            btnText="Start 14 day trial!"
            price={`${isMonthly ? "€350" : "3850"}`}
            pricePlanId={isMonthly ? "starter_mon" : "starter_anu"}
            isMonthly={isMonthly}
            referralId={referralId}
          />
        </div>
        <div className="w-full md:w-1/4 min-w-[230px]">
          <PriceListCard
            desc={<AdvancedPlanList />}
            type={"Fund Orbit"}
            price={`${isMonthly ? "€590" : "€6490"}`}
            btnText="Start 14 day trial!"
            pricePlanId={isMonthly ? "copilot-pro_mon" : "copilot-pro_anu"}
            isMonthly={isMonthly}
            referralId={referralId}
          />
        </div>
        <div className="w-full md:w-2/4 min-w-[400px]">
          <PriceListCard
            type="Enterprise Suite"
            desc={<EnterpriceSuiteList />}
            btnText="Contact us"
            isMonthly={isMonthly}
            pricePlanId={isMonthly ? "enterprise_mon" : "enterprise_anu"}
            referralId={referralId}
            trial={
              <div className={"flex justify-center"}>
                <InfoCircleIcon />
                <p className={`pl-1 text-[#485066] text-[11px] font-[500]`}>
                  12 months
                  <span
                    className={"pl-1 text-[#83899F] text-[11px] font-[500]"}
                  >
                    contract commitment
                  </span>
                </p>
              </div>
            }
            enterprise
            textDesc
            popular
          />
        </div>
      </div>
    </div>
  );
};
