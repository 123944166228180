import React from "react";
import { CheckIconCircle } from "../../components/icons/CheckIconCircle";

export const IndividualsAdvancedDescList = () => {
  return (
    <div className={"flex justify-start flex-col"}>
       <div className={"flex justify-start flex-col"}>
        <div className={"flex justify-start"}>
        <span
          className={"justify-start text-[12px] text-[#83899F] text-center"}
        >
          Perfect for small and medium-sized businesses to ensure compliance with regulatory requirements.
        </span>
      </div>
    </div>
    </div>
  );
};
