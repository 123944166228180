import Subheader from "../Subheader";

function ImprintContent() {
    return(
        <>
            <Subheader title="Imprint" subtitle="Here you will find information according to § 25 Mediengesetz." />
            <div className="container legal text-left max-w-screen-lg mx-auto mt-20 max-lg:px-5">
                <p>Offenlegung gem. § 25 Mediengesetz</p>
 
                <p><strong>Medieninhaber und Herausgeber</strong></p>
                <p>3Folio GmbH</p>

                <p><strong>Anschrift</strong></p>
                <p>3Folio GmbH<br />Ernst Melchior Gasse 24/DG<br />1020 Wien</p>
                <p>Telefon: +43 676 366 80 26</p>

                <p><strong>Unternehmensgegenstand</strong></p>
                <p>Wahrnehmung von Aufgaben der Informations- und Kommunikationstechnologie (IT)</p>

                <p><strong>Geschäftsführung</strong></p>
                <p>Michael Schöngruber</p>

                <p><strong>Urheberrecht</strong></p>
                <p>Redaktionelle Beiträge, Layout und Gesamtgestaltung sind urheberrechtlich geschützt.<br /><br />Eine öffentliche Verwendung einzelner Inhalte darf nur mit Zustimmung der 3Folio GmbH und unter Nennung der Quelle erfolgen. Die Verlinkung auf die Homepage der 3Folio GmbH oder auf Inhalte unter gleichzeitigem Quellenhinweis steht jedermann frei und bedarf keiner gesonderten Genehmigung.</p>

                <p><strong>Hinweis</strong></p>
                <p>Links zu anderen Websites wurden sorgfältig ausgewählt. Auf den Inhalt dieser verlinkten Seiten hat die 3Folio GmbH keinen Einfluss und ist daher auch für deren Inhalt nicht verantwortlich bzw. kann für deren Inhalt nicht verantwortlich gemacht werden.</p>
                <p>Angaben gem. § 14 Unternehmensgesetzbuch (UGB)</p>
                <p>Firmenbuchnummer: FN572991i<br />Firmenbuchgericht: Handelsgericht Wien<br />Sitz der Gesellschaft: Wien</p>
                <p><strong>Informationen gem. § 5 E-Commerce-Gesetz (ECG)</strong></p>
                <p>Siehe Wirtschaftskammer Österreichs, Informationen zum Unternehmen lt. § 5 ECG</p>
                <p><strong>Ergänzende Informationen</strong></p>
                <p>UID-Nr.: ATU77844736</p>
                <p>IBAN: AT703418000001969633</p>
                <p>BIC: RZOOAT2L180</p>
            </div>
        </>
    )
}

export default ImprintContent;