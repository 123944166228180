import React from "react";

export const ReportingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
    >
      <rect
        opacity="0.1"
        x="0.937256"
        width="35"
        height="35"
        rx="8"
        fill="#275EF7"
      />
      <path
        d="M23.1782 26.9798H13.699C12.1283 26.9798 10.8552 25.7068 10.8552 24.1361V10.8652C10.8552 9.29454 12.1283 8.02148 13.699 8.02148H17.0207C19.1424 8.02148 21.1773 8.86434 22.6776 10.3646L23.6788 11.3658C25.1791 12.8661 26.0219 14.901 26.0219 17.0227V24.1361C26.0219 25.7068 24.7489 26.9798 23.1782 26.9798Z"
        fill="#275EF7"
      />
      <path
        d="M20.3357 11.8132C20.3357 10.4139 22.0274 9.7132 23.0168 10.7026L23.3421 11.0279C24.3315 12.0173 23.6308 13.709 22.2315 13.709C21.1841 13.709 20.3357 12.8606 20.3357 11.8132Z"
        fill="#D5D5D5"
        fill-opacity="0.5"
      />
      <path
        d="M14.2739 22.2676C14.079 22.2676 13.8833 22.2002 13.7259 22.0619C13.3794 21.7587 13.3445 21.2324 13.6468 20.8867L16.5618 17.5553C16.7217 17.3704 16.9333 17.2788 17.1989 17.2704C17.443 17.2738 17.6728 17.3829 17.8294 17.5694C18.5676 18.4556 19.9329 18.442 20.6532 17.5412L21.9521 15.917C22.2411 15.5589 22.7633 15.4989 23.1231 15.7871C23.4821 16.0753 23.5404 16.5991 23.253 16.9581L19.9216 21.1224C19.765 21.3173 19.5293 21.4323 19.2794 21.4348C19.0188 21.4722 18.7914 21.3265 18.6315 21.1358C17.8664 20.2172 16.4614 20.2 15.6741 21.0996L14.9011 21.9828C14.7362 22.1718 14.5055 22.2676 14.2739 22.2676Z"
        fill="#EDECFF"
      />
    </svg>
  );
};
