export const WEBSITE_SHOW_PRICE_LIST = true;
export const WEBSITE_SHOW_TESTIMONIALS = true;

export function infoBackground(status: string) {
  switch (status) {
    case "Information":
      return "#EFF6FF";
    case "Issue":
      return "#dcb0b04d";
    case "Maintenance":
      return "#e3a92314";
    default:
      return "#EFF6FF";
  }
}

export function infoBtnBackground(status: string) {
  switch (status) {
    case "Information":
      return "#DBEAFE";
    case "Issue":
      return "#EAD2D2";
    case "Maintenance":
      return "#F5EBD4";
    default:
      return "#DBEAFE";
  }
}

export function infoBtnColor(status: string) {
  switch (status) {
    case "Information":
      return "#3B82F6";
    case "Issue":
      return "#A43333";
    case "Maintenance":
      return "#CF9A20";
    default:
      return "#3B82F6";
  }
}

export function consensusColor(status: string) {
  switch (status) {
    case "Automated":
    case "Ok":
      return "#148800";
    case "Manually":
      return "#275EF7";
    case "Maintenance":
      return "#1F2937";
    case "Issue":
      return "#8D0000";
    default:
      return "#373A45";
  }
}

export function consensusBackground(status: string) {
  switch (status) {
    case "Automated":
    case "Ok":
      return "#E8F3E6";
    case "Manually":
      return "#EAF2FF";
    case "Maintenance":
      return "#EDEEF3";
    case "Issue":
      return "#F4E6E6";
    default:
      return "#fff";
  }
}

// INDIVIDUALS PLANS

export const individualCoreFunctions = [
  {
    color: "#1AB200",
    title: "Limi Sources (active)",
    tooltip:
      "Limitation of wallets, exchange and bank accounts that can be used in a workspace at the same time. Upgrades to higher levels are possible at any time. ",
  },
  {
    color: "#1AB200",
    title: "Stored Transactions",
    tooltip:
      "Limitation of total recorded transactions in a workspace over time. When reached, an upgrade to a higher level is required. Upgrades to higher levels are possible at any time.",
  },
  {
    color: "#1AB200",
    title: "Historical portfolio data",
    tooltip:
      "When working in a team and or with the tax consultant, it is important that user actions are recorded in order to document changes to data in a traceable manner.",
  },
  {
    color: "#1AB200",
    title: "Tracking Module",
    tooltip:
      "A daily overview of your valued assets, including historical records and basic documentation.",
  },
  {
    color: "#F76527",
    title: "Accounting & Tax Module*",
    tooltip:
      "Preparation of the recorded transactions for accountanting.*",
  },
  {
    color: "#F76527",
    title: "Reporting Module",
    tooltip:
      "Preparation of the recorded transactions for taxation.",
  },
  {
    color: "#1AB200",
    title: "Support level",
    tooltip:
      "We offer various ways to get in touch with us if you have any questions or problems or if you need consulting.",
  }
];

export const individualImportOptions = [
  {
    color: "#1AB200",
    title: "+60 supported integrations*",
    tooltip:
      "We are constantly striving to expand our range. You can find a current list under Integrations on our website.",
  },
  {
    color: "#1AB200",
    title: "Manual import via .csv",
    tooltip:
      "We offer various .csv-Importer to add transactions manually.",
  }
];

export const individualAssetOptions = [
  {
    color: "#1AB200",
    title: "+50,000 assets",
    tooltip: "We have a huge asset database that has been built up since 2017.",
  },
  {
    color: "#1AB200",
    title: "Self-serviced assets",
    tooltip:
      "If you cannot find an asset in our broad asset catalog, you can simply create your own.",
  },
  {
    color: "#1AB200",
    title: "Derivates",
    tooltip: "We are constantly striving to expand our database.",
  },
  {
    color: "#1AB200",
    title: "Commodities",
    tooltip: "We are constantly striving to expand our database.",
  },
  {
    color: "#1AB200",
    title: "Non-Fungible Token (NFT)",
    tooltip: "We are constantly striving to expand our database.",
  },
  {
    color: "#1AB200",
    title: "Foreign Currencies (Fiat)",
    tooltip:
      "We support a wide range of foreign currencies with daily rating information.",
  },
  {
    color: "#1AB200",
    title: "+3 years of historical price data",
    tooltip: "A historical valuation of assets is possible.",
  }
];

export const individualFunctionsTracking = [
  {
    color: "#1AB200",
    title: "Portfolio dashboard",
    tooltip:
      "You will find key indications to portfolio performance, allocation and value development.",
  },
  {
    color: "#1AB200",
    title: "Historical portfolio data",
    tooltip:
      "The net asset value of a portfolio is recorded daily and stored for a certain period of time.",
  },
  {
    color: "#1AB200",
    title: "Daily scheduled sync jobs",
    tooltip:
      "Please note that our import options may vary. You can find an overview of supported integrations and the level of automation on our website.",
  },
  {
    color: "#1AB200",
    title: "Bank Account integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Blockchain integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Crypto Wallet integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Crypto Exchange integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Manual Wallet integrations",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Daily Net Asset Value (NAV)",
    tooltip:
      "The net asset value of your portfolio is calculated automatically on a daily basis.",
  },
  {
    color: "#1AB200",
    title: "Asset prices with reference",
    tooltip:
      "Our price lists have a source reference and are based on consensus pricing.",
  },
  {
    color: "#1AB200",
    title: "Asset inventory report",
    tooltip:
      "Export the asset holdings of your portfolio at any time during the recording.",
  },
];

export const individualFunctionsAccounting = [
  {
    color: "#1AB200",
    title: "Smart transaction book",
    tooltip:
      "We combine fiat and crypto transactions and enable complex mapping.",
  },
  {
    color: "#1AB200",
    title: "Customizable transaction labels",
    tooltip:
      "Mark your transactions so that they can be valued correctly according to company and tax law.",
  },
  {
    color: "#1AB200",
    title: "Tax advisor collaboration*",
    tooltip:
      "Invite your tax advisor to your workspace to get your transactions faster ready for tax reporting.",
  },
  {
    color: "#1AB200",
    title: "Company & Tax Ledger",
    tooltip:
      "We store the documentation of the profit and loss account under company and tax law.",
  },
  {
    color: "#F76527",
    title: "Valuation according to company law*",
    tooltip:
      "Automated calculation of asset acquisition costs based on transactions.",
  },
  {
    color: "#F76527",
    title: "Valuation according to tax law*",
    tooltip:
      "Automated calculation of asset acquisition costs based on transactions.",
  },
  {
    color: "#F76527",
    title: "Data locking",
    tooltip:
      "Mark your transactions so that they can be valued correctly according to company and tax law.",
  },
  {
    color: "#F76527",
    title: "Csv. data export",
    tooltip:
      "Different data such as asset lists, asset inventory, financial statements and transaction lists can be exported if included in your subscription.",
  },
  {
    color: "#F76527",
    title: "Interface to ERP (Datev and more)",
    tooltip:
      "Different data such as asset lists, asset inventory, financial statements and transaction lists can be exported if included in your subscription.",
  },
  {
    color: "#F76527",
    title: "User action logging",
    tooltip:
      "When working in a team and with the tax consultant, it is important that user activities are recorded in order to document changes.",
  },
];

export const individualFunctionsReporting = [
  {
    color: "#686F87",
    title: "AI-driven reports",
    tooltip:
      "A suitable importer for every requirement. Contact our Sales Team for more information.",
  }
  ,
  {
    color: "#686F87",
    title: "Report Designer",
    tooltip:
      "A suitable importer for every requirement. Contact our Sales Team for more information.",
  }
];

export const individualPayPerUse = [
  {
    color: "#1AB200",
    title: "Client management",
    tooltip:
      "Manage your clients and their shares within our tool for evaluations at client level.",
  },
];

// CRYPTO FUNDS

export const cryptoFundsCoreFunctions = [
  {
    color: "#1AB200",
    title: "Limi Sources (active)",
    tooltip:
      "Limitation of wallets, exchange and bank accounts that can be used in a workspace at the same time. Upgrades to higher levels are possible at any time. ",
  },
  {
    color: "#1AB200",
    title: "Stored Transactions",
    tooltip:
      "Limitation of total recorded transactions in a workspace over time. When reached, an upgrade to a higher level is required. Upgrades to higher levels are possible at any time.",
  },
  {
    color: "#F76527",
    title: "Historical portfolio data",
    tooltip:
      "When working in a team and or with the tax consultant, it is important that user actions are recorded in order to document changes to data in a traceable manner.",
  },
  {
    color: "#1AB200",
    title: "Tracking Module",
    tooltip:
      "A daily overview of your valued assets, including historical records and basic documentation.",
  },
  {
    color: "#1AB200",
    title: "Fund Module",
    tooltip:
      "This Co-Pilot processes your qualified data in reports so that they can also be used as preparation for the proof of origin.",
  },
  {
    color: "#F76527",
    title: "Accounting Module*",
    tooltip:
      "Preparation of the recorded transactions for the taxation and accountanting.*",
  },
  {
    color: "#F76527",
    title: "Reporting Module",
    tooltip:
      "Multiple data exports to easily share information with third parties.",
  },
  {
    color: "#1AB200",
    title: "Support level",
    tooltip:
      "We offer various ways to get in touch with us if you have any questions or problems or if you need consulting.",
  }
];

export const cryptoFundsImportOptions = [
  {
    color: "#1AB200",
    title: "+60 supported integrations*",
    tooltip:
      "We are constantly striving to expand our range. You can find a current list under Integrations on our website.",
  },
  {
    color: "#1AB200",
    title: "Manual import via .csv",
    tooltip:
      "We offer various .csv-Importer to add transactions manually.",
  },
  {
    color: "#1AB200",
    title: "Customized Importer",
    tooltip:
      "We can optionally create individual data importers for your needs.",
  },
];

export const cryptoFundsAssetOptions = [
  {
    color: "#1AB200",
    title: "+50.000 assets",
    tooltip: "We have a huge asset database that has been built up since 2017.",
  },
  {
    color: "#1AB200",
    title: "Self-serviced assets",
    tooltip:
      "If you cannot find an asset in our broad asset catalog, you can simply create your own.",
  },
  {
    color: "#1AB200",
    title: "Derivates",
    tooltip: "We are constantly striving to expand our database.",
  },
  {
    color: "#1AB200",
    title: "Commodities",
    tooltip: "We are constantly striving to expand our database.",
  },
  {
    color: "#1AB200",
    title: "Non-Fungible Token (NFT)",
    tooltip: "We are constantly striving to expand our database.",
  },
  {
    color: "#1AB200",
    title: "Foreign Currencies (Fiat)",
    tooltip:
      "We support a wide range of foreign currencies with daily rating information.",
  },
  {
    color: "#1AB200",
    title: "+3 years of historical price data",
    tooltip: "A historical valuation of assets is possible.",
  }
];

export const cryptoFundsFunctionsTracking = [
  {
    color: "#1AB200",
    title: "Portfolio dashboard",
    tooltip:
      "You will find key indications to portfolio performance, allocation and value development.",
  },
  {
    color: "#1AB200",
    title: "Historical portfolio data",
    tooltip:
      "The net asset value of a portfolio is recorded daily and stored for a certain period of time.",
  },
  {
    color: "#1AB200",
    title: "Daily scheduled sync jobs",
    tooltip:
      "Please note that our import options may vary. You can find an overview of supported integrations and the level of automation on our website.",
  },
  {
    color: "#1AB200",
    title: "Bank Account integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Blockchain integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Crypto Wallet integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Crypto Exchange integrations.",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Manual Wallet integrations",
    tooltip: "Simply manage your sources of wealth in one central location.",
  },
  {
    color: "#1AB200",
    title: "Daily Net Asset Value (NAV)",
    tooltip:
      "The net asset value of your portfolio is calculated automatically on a daily basis.",
  },
  {
    color: "#1AB200",
    title: "Asset prices with reference",
    tooltip:
      "Our price lists have a source reference and are based on consensus pricing.",
  },
  {
    color: "#1AB200",
    title: "Asset inventory report",
    tooltip:
      "Export the asset holdings of your portfolio at any time during the recording.",
  },
];

export const cryptoFundsFunctionsAccounting = [
  {
    color: "#1AB200",
    title: "Smart transaction book",
    tooltip:
      "We combine fiat and crypto transactions and enable complex mapping.",
  },
  {
    color: "#1AB200",
    title: "Customizable transaction labels",
    tooltip:
      "Mark your transactions so that they can be valued correctly according to company and tax law.",
  },
  {
    color: "#1AB200",
    title: "Tax advisor collaboration*",
    tooltip:
      "Invite your tax advisor to your workspace to get your transactions faster ready for tax reporting.",
  },
  {
    color: "#1AB200",
    title: "Company & Tax Ledger",
    tooltip:
      "We store the documentation of the profit and loss account under company and tax law.",
  },
  {
    color: "#F76527",
    title: "Valuation according to company law*",
    tooltip:
      "Automated calculation of asset acquisition costs based on transactions.",
  },
  {
    color: "#F76527",
    title: "Valuation according to tax law*",
    tooltip:
      "Automated calculation of asset acquisition costs based on transactions.",
  },
  {
    color: "#F76527",
    title: "Data locking",
    tooltip:
      "Mark your transactions so that they can be valued correctly according to company and tax law.",
  },
  {
    color: "#F76527",
    title: "Csv. data export",
    tooltip:
      "Different data such as asset lists, asset inventory, financial statements and transaction lists can be exported if included in your subscription.",
  },
  {
    color: "#F76527",
    title: "Interface to ERP (Datev and more)",
    tooltip:
      "Different data such as asset lists, asset inventory, financial statements and transaction lists can be exported if included in your subscription.",
  },
  {
    color: "#F76527",
    title: "User action logging",
    tooltip:
      "When working in a team and with the tax consultant, it is important that user activities are recorded in order to document changes.",
  },
];

export const cryptoFundsFunctionsReporting = [
  {
    color: "#686F87",
    title: "AI-driven reports",
    tooltip:
      "A suitable importer for every requirement. Contact our Sales Team for more information.",
  }
  ,
  {
    color: "#686F87",
    title: "Report Designer",
    tooltip:
      "A suitable importer for every requirement. Contact our Sales Team for more information.",
  }
];

export const cryptoFundsPayPerUse = [
  {
    color: "#1AB200",
    title: "Client management",
    tooltip:
      "Manage your clients and their shares within our tool for evaluations at client level.",
  },
  {
    color: "#1AB200",
    title: "Share class management",
    tooltip:
      "Activate additional seats for team members if needed. Downgrade on monthly base possible.",
  },
  {
    color: "#1AB200",
    title: "Daily Fee calculation",
    tooltip:
      "We store your management and performance fee model and enable automated calculation of the commission.",
  },
  {
    color: "#1AB200",
    title: "Daily Share Price determination",
    tooltip:
      "We recalculate the share price on a cyclical basis, taking into account the commission incurred. ",
  },
  {
    color: "#1AB200",
    title: "Integration for Clients",
    tooltip: "Share portfolio insides with your clients.",
  },
];

export const cardsData = {
  cryptoFundsStarter: {
    coreFunctions: [
      { index: "25" },
      { index: "15 000" },
      { index: "3 years" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "Key Account Manager" },
    ],
    importOptions: [
      { index: "check" },
      { index: "check" },
      { index: "optional" },
    ],
    assetOptions: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsTracking: [
      { index: "check" },
      { index: "3 years" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsAccounting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsReporting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    payPerUse: [{ index: "coming Q3, 2025" }, { index: "coming Q3, 2025" }],
  },
  cryptoFundsAdvanced: {
    coreFunctions: [
      { index: "60" },
      { index: "60 000" },
      { index: "7 years" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "Key Account Manager" },
    ],
    importOptions: [
      { index: "check" },
      { index: "check" },
      { index: "optional" },
    ],
    assetOptions: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsTracking: [
      { index: "check" },
      { index: "7 years" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsAccounting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsReporting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    payPerUse: [{ index: "coming Q3, 2025" }, { index: "coming Q3, 2025" }],
  },
  cryptoFundsEnterprise: {
    coreFunctions: [
      { index: "individual" },
      { index: "Optional up to 500.000+" },
      { index: "unlimited" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "Key Account Manager, SLA" },
    ],
    importOptions: [
      { index: "check" },
      { index: "check" },
      { index: "optional" },
    ],
    assetOptions: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsTracking: [
      { index: "check" },
      { index: "unlimited" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsAccounting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsReporting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    payPerUse: [{ index: "coming Q3, 2025" }, { index: "coming Q3, 2025" }],
  },
  individualsCommunity: {
    coreFunctions: [
      { index: "5" },
      { index: "-" },
      { index: "7 days" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "Helpdesk" },
    ],
    importOptions: [
      { index: "check" },
      { index: "check" },
    ],
    assetOptions: [
      { index: "check" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "check" },
      { index: "check" },
    ],
    functionsTracking: [
      { index: "check" },
      { index: "7 days" },
      { index: "-" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsAccounting: [
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
    
    ],
    functionsReporting: [
      { index: "-" },
      { index: "-" },
    ],
    payPerUse: [],
  },
  individualsStarter: {
    coreFunctions: [
      { index: "10" },
      { index: "500" },
      { index: "2 years" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "E-Mail, Helpdesk" },
    ],
    importOptions: [
      { index: "check" },
      { index: "check" },
    ],
    assetOptions: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsTracking: [
      { index: "check" },
      { index: "2 year" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsAccounting: [
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
      { index: "-" },
    ],
    functionsReporting: [
      { index: "-" },
      { index: "-" },
    ],
    payPerUse: [],
  },
  individualsAdvanced: {
    coreFunctions: [
      { index: "30" },
      { index: "4 500" },
      { index: "5 years" },
      { index: "check" },
      { index: "*Beta Phase AT,DE*" },
      { index: "check" },
      { index: "E-Mail, In-App Chat" },
    ],
    importOptions: [
      { index: "check" },
      { index: "check" },
    ],
    assetOptions: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsTracking: [
      { index: "check" },
      { index: "5 years" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsAccounting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "5 years" },
      { index: "*Beta Phase AT,DE*" },
      { index: "*Beta Phase AT,DE*" },
      { index: "check" },
      { index: "check" },
      { index: "coiming Q3 2025" },
      { index: "check" },
    ],
    functionsReporting: [
      { index: "coming 2025" },
      { index: "coming 2025" },
    ],
    payPerUse: [],
  },
  individualsProfessional: {
    coreFunctions: [
      { index: "60" },
      { index: "25 000" },
      { index: "7 years" },
      { index: "check" },
      { index: "*Beta Phase AT,DE*" },
      { index: "check" },
      { index: "E-Mail, In-App Chat" },
    ],
    importOptions: [
      { index: "check" },
      { index: "check" },
    ],
    assetOptions: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsTracking: [
      { index: "check" },
      { index: "7 years" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "check" },
    ],
    functionsAccounting: [
      { index: "check" },
      { index: "check" },
      { index: "check" },
      { index: "7 years" },
      { index: "*Beta Phase AT,DE*" },
      { index: "*Beta Phase AT,DE*" },
      { index: "check" },
      { index: "check" },
      { index: "coiming Q3 2025" },
      { index: "check" },
    ],
    functionsReporting: [
      { index: "coming 2025" },
      { index: "coming 2025" },
    ],
    payPerUse: [],
  },
};

export type BoardMembersType = {
  name: string;
  job?: string;
  position: string;
  image: string;
  linkedin: string;
  twitter?: string;
};

export const teamMembers: BoardMembersType[] = [
  {
    name: "Michael Schöngruber",
    position: "Managing Director",
    image: "https://homepage3folio.z1.web.core.windows.net/images/michael_s.jpeg",
    linkedin: "https://www.linkedin.com/in/mschoengruber/",
  },
  {
    name: "Peter Lehner",
    position: "Partners & Realizations",
    image: "https://homepage3folio.z1.web.core.windows.net/images/peter_l.png",
    linkedin: "https://www.linkedin.com/in/peterlehner/",
  }
];

export const boardMembers: BoardMembersType[] = [
  {
    name: "Matthias Steger",
    job: "Crypto Tax Law",
    position: "Crypto Tax Advisor",
    image: "https://homepage3folio.z1.web.core.windows.net/images/matthias_s.jpeg",
    linkedin: "https://www.linkedin.com/in/matthias-steger-a55165153/",
  },
  {
    name: "Martin Roy",
    job: "Merger & Acquisition",
    position: "Manager, Investor",
    image: "https://homepage3folio.z1.web.core.windows.net/images/martin_r.jpeg",
    linkedin: "https://www.linkedin.com/in/martin-roy-31711b1/",
  },
  {
    name: "Franz Großmann",
    job: "Cyber Security",
    position: "CEO Schoeller Network Control",
    image: "https://homepage3folio.z1.web.core.windows.net/images/franz_g.jpeg",
    linkedin:
      "https://www.linkedin.com/in/franz-grossmann-schoellernetworkcontrol/",
  },
  {
    name: "Samuel Brandstätter",
    job: "B2B Compliance",
    position: "Enterpreneur, Investor",
    image: "https://homepage3folio.z1.web.core.windows.net/images/samuel_b.jpeg",
    linkedin: "https://www.linkedin.com/in/samuelbrandstaetter/"
  },
  {
    name: "Florian Wimmer",
    job: "Crypto Tax Reporting",
    position: "Founder Blockpit AG",
    image: "https://homepage3folio.z1.web.core.windows.net/images/florian_w.jpeg",
    linkedin: "https://www.linkedin.com/in/florian-wimmer/",
    twitter: "https://twitter.com/FlorianWimmerAT%20%20",
  },
  {
    name: "Peter Augustin",
    job: "Crypto Innovation",
    position: "Founder Inode and more",
    image: "https://homepage3folio.z1.web.core.windows.net/images/peter_a.jpeg",
    linkedin: "https://www.linkedin.com/in/peter-augustin-293475186/",
  },
  {
    name: "Moritz Schildt",
    job: "Financial Services",
    position: "Global Strategy",
    image: "https://homepage3folio.z1.web.core.windows.net/images/moritz_s.jpeg",
    linkedin: "https://www.linkedin.com/in/moritz-schildt-05b0a04",
  }
];

export const TestimonialsArray = [
  {
    name: "Tigris Web3 Fund",
    quote: "3Folio sets new standards for institutional crypto investors. Their focus on innovation and simplicity makes them an indispensable partner for crypto funds like us.",
    link: "https://tigrisweb3.com/",
    avatarUrl:
      "https://tigrisweb3.com/wp-content/uploads/2024/10/Tigris-Icon.svg",
    position: "Crypto Fund (AT)",
  }
];
