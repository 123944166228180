import React from "react";

export const ActionLoggingIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.8"
        x="14.1743"
        y="12.3506"
        width="6.26879"
        height="12.6389"
        rx="3.13439"
        fill="#275EF7"
      />
      <rect
        opacity="0.5"
        x="6"
        y="15"
        width="6.26879"
        height="9.76641"
        rx="3.13439"
        fill="#275EF7"
      />
      <rect width="35" height="35" rx="8" fill="#F0F5FF" />
      <path
        d="M26 18L14 18M26 12L14 12M26 24L14 24M10 18C10 18.5523 9.55228 19 9 19C8.44772 19 8 18.5523 8 18C8 17.4477 8.44772 17 9 17C9.55228 17 10 17.4477 10 18ZM10 12C10 12.5523 9.55228 13 9 13C8.44772 13 8 12.5523 8 12C8 11.4477 8.44772 11 9 11C9.55228 11 10 11.4477 10 12ZM10 24C10 24.5523 9.55228 25 9 25C8.44772 25 8 24.5523 8 24C8 23.4477 8.44772 23 9 23C9.55228 23 10 23.4477 10 24Z"
        stroke="#317BFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
