import React from "react";

export const CookiePolicyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" fill="none">
            <g clip-path="url(#clip0_2889_5063)">
                <path d="M24.3015 24.0711L22.8873 22.6569M24.3015 24.0711C20.3963 27.9764 14.0646 27.9764 10.1594 24.0711M24.3015 24.0711C28.2068 20.1659 28.2068 13.8342 24.3015 9.92898M17.2305 27V25M11.5736 11.3432L10.1594 9.92898M10.1594 9.92898C6.25416 13.8342 6.25416 20.1659 10.1594 24.0711M10.1594 9.92898C14.0646 6.02373 20.3963 6.02373 24.3015 9.92898M17.2305 9.00005V7.00005M22.8873 11.3432L24.3015 9.92898M25.2305 17L27.2305 17M10.1594 24.0711L11.5736 22.6569M7.23047 17L9.23047 17M20.0589 14.1716V19.8285H14.402V14.1716H20.0589Z" stroke="#275EF7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2889_5063">
                    <rect width="24" height="24" fill="white" transform="translate(17.2305 0.029541) rotate(45)"/>
                </clipPath>
            </defs>
        </svg>
    )
}