import React from "react";


export const TermsOfUseContent = () => {



    return (
        <div className={'justify-start w-full min-w-full p-4 md:p-0'}>
            <h1 className={'text-xl font-bold mb-3'}>Terms Of Use</h1>
            <h2 className={'text-lg mb-[30px] font-semibold'}>I. General</h2>


            <h3 className={'text-base font-semibold mb-3'}>1. Introduction</h3>
                <p className={'text-sm text-justify pb-5'}>1.1 3Folio GmbH, FN 572991i, Ernst Melchior Gasse 24/DG, 1020 Vienna, Austria, ("<b>3Folio</b>" or "<b>we</b>") offers crypto monitoring, accounting and reporting services via its proprietary platform accessible under the domain 3folio.finance (the "<b>Platform</b>").</p>
                <p className={'text-sm text-justify pb-5'}>1.2 These Terms of Use ("<b>ToU</b>") apply to the use of the Platform.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.3 When setting up a user account on the Platform, you will be required to accept these ToU.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>2. Obligations of Users and Restrictions on the Use of the Platform</h3>
            <p className={'text-sm text-justify pb-5'}>2.1 You may use the Platform only for its intended purposes and in accordance with these ToU.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.2 Keep your user account's password confidential and do not disclose it to any third party.</p>
            <p className={'text-sm text-justify pb-5'}>2.3 You must not use the Platform to save, publish, transmit and/or distribute any data, material or information that (i) is misleading, incorrect or fraudulent; (ii) infringes any copyright, trademark, trade secret, or other proprietary right of any other person; (iii) is racist, insulting, discriminating, libelous or glorifies violence; (iv) contains viruses, Trojan horses, worms, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware; or (v) is otherwise illegal.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.4 You must not use the Platform to send unsolicited or unauthorized advertisements, generate spam, junk mail, chain letters or similar content or messages.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.5 You must not carry out any disrupting interferences, by use of technical or electronic aids, such as hacking attempts, brute force-attacks, planting of viruses, Trojan horses, worms and other disrupting attempts regarding the Platform.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.6 You must not rent, lease, loan, trade or sell/re-sell or otherwise provide access to the Platform, unless this is provided for as part of the intended use of the Platform (eg inviting other users to your workspace on the Platform).
            </p>
            <p className={'text-sm text-justify pb-5'}>2.7 You may inform us via email (<a className={'text-accent'} href={'mailto:support@3folio.finance'}> support@3folio.finance</a>) of any detected breaches of the aforementioned obligations.
            </p>

            <h2 className={'text-lg mb-[30px] font-semibold'}>II. Additional Terms for Non-Subscribers
            </h2>
                <p className={'text-sm text-justify pb-5'}>The following terms apply only if you are invited to join another user's Workspace (as defined below in section II.1) and do not currently have an active subscription with 3Folio. If you become a subscriber and accept our General Terms and Conditions (the "<b>GTC</b>", available at <a href={'http://3folio.finance/legal/terms-and-conditions'} className={'text-accent'}>3folio.finance/legal/terms-and-conditions</a>) during the registration process, the terms of this section II will be superseded by the terms of the GTC.
            </p>
            <h3 className={'text-base font-semibold mb-3'}>1. Workspaces</h3>
            <p className={'text-sm text-justify pb-5'}>1.1 Financial data provided on or through the Platform will be organized and displayed in workspaces (the "<b>Workspace(s)</b>").
            </p>
            <p className={'text-sm text-justify pb-5'}>1.2 You can collaborate with other users in their Workspace after you received an invitation.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.3 In order to create your own Workspace and invite other users to collaborate with you, you will need a subscription. Please refer to <a className={'text-accent'} href='https://www.3folio.finance/pricing' target='_blank'>www.3folio.finance/pricing</a> for more information on the types of subscriptions we offer.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.4 If you are invited to another user's Workspace, you do not need an active subscription to join that Workspace. However, you will not be able to create your own Workspace, nor will you be able to invite other users, unless the Workspace administrator has granted you administrator rights. For avoidance of doubt, these ToU apply when using another user's Workspace.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.5 The administrator of a Workspace manages access of user accounts to the respective Workspace and may selectively restrict or grant permissions.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.6 The administrator and other members of a Workspace that you join will be able to see data that you publish while working together in that Workspace.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.7 If the administrator of a Workspace restricts your access to the Workspace, deletes the Workspace, or the administrator's subscription with 3Folio expires, you will no longer have access to that Workspace.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>2. Registration</h3>
            <p className={'text-sm text-justify pb-5'}>2.1 Before you can join a Workspace, you must register and set up a user account.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.2 You will not incur any costs for registering, setting up a user account or joining a Workspace upon receipt of an invitation. We will only charge you if you purchase a paid subscription.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.3 After you have entered your email address, chosen a password and entered your first and last name (optional), we will send you an email containing a confirmation link. By clicking on the confirmation link you will be redirected to the Platform. This will complete the registration process and your user account will be created in the 3Folio system.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>3. Financial Data provided on the Platform
            </h3>
            <p className={'text-sm text-justify pb-5'}>3.1 Financial data provided by 3Folio will be visually highlighted or otherwise recognizable on the Platform's interface to ensure differentiation from financial data provided by you or other users.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.2 Although 3Folio takes all reasonable efforts to ensure the accuracy of financial data it provides on the Platform, 3Folio cannot warrant correctness, validity or completeness of any data, and the marking "Confirmed" shall not be construed as confirmation of the correctness, validity or completeness of any data by 3Folio.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.3 Markets and prices change continuously, so the data provided by 3Folio may not be up-to-date and should not be relied upon.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.4 Any data could be inaccurate and/or corrupted due to uncontrollable circumstances, such as technical instabilities, in particular unstable Internet connections, third-party services unavailability or interferences by third-parties.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.5 3Folio has no influence whatsoever on the accuracy of the financial data provided by you or other users.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.6 Before making any type of decision-making, you should always take reasonable steps to verify the accuracy and completeness of the financial data provided on or through the Platform.</p>

            <h3 className={'text-base font-semibold mb-3'}>4. No Professional Advice
            </h3>
            <p className={'text-sm text-justify pb-5'}>4.1 The services provided via the Platform do not include or constitute investment, legal or financial advice of whatsoever nature or any other (regulated or non-regulated) services that are explicitly the responsibility of a financial advisor, tax advisor, legal advisor, investment banker or accountant, and such services shall also not be construed accordingly.
            </p>
            <p className={'text-sm text-justify pb-5'}>4.2 Nothing on the Platform should be construed as a recommendation by 3Folio to buy, sell, or hold any particular asset or asset class.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>5. Intellectual Property
            </h3>
            <p className={'text-sm text-justify pb-5'}>Our services, and in particular the Platform, any features and functionalities are and will remain the exclusive property of 3Folio and its licensors. Our services are protected by copyright, trademark, and other laws of Austria, the European Union and/or foreign countries. You may not use our trademarks, trade names and corporate get-up in connection with any product or service without our prior consent.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>6. Term and Termination
            </h3>
            <p className={'text-sm text-justify pb-5'}>6.1 The contract for the use of the Platform commences upon registration and is concluded for an indefinite period of time.
            </p>
            <p className={'text-sm text-justify pb-5'}>6.2 You may terminate the contract for the use of the Platform at any time (eg by deleting your user account via the Platform interface or by notifying us via email) without giving any reason.
            </p>
                <p className={'text-sm text-justify pb-5'}>6.3 We may terminate the contract for use of the Platform with you at any time without giving any reason and subject to a notice period of 14 days. The right to terminate the contract for the use of the Platform for good cause (<i>aus wichtigem Grund</i>) shall not be affected by the foregoing.
            </p>

                <p className={'text-sm text-justify pb-5 mt-6'}>January 2024</p>



        </div>
    )
}