import React from "react";

export const PrivacyContent = () => {

    return (
        <div className={'justify-start w-full min-w-full p-4 md:p-0'}>
            <h1 className={'text-xl font-bold mb-3'}>Datenschutzrichtlinie</h1>
            <h2 className={'text-lg mb-[30px]'}>Ihre Daten sind bei uns sicher.</h2>


            <h3 className={'text-base font-semibold mb-3'}>Version 1.0</h3>
            <p className={'text-sm text-justify pb-5'}>Mit dem Aufstieg digitaler Technologien und dem vermehrten Einsatz von Online-Diensten im Bereich Business-to-Consumer (B2C) eröffnen sich sowohl für Verbraucher als auch Unternehmen zahlreiche Chancen. Doch gleichzeitig wächst die Bedeutung des Datenschutzes, insbesondere vor dem Hintergrund der Datenschutzgrundverordnung (DSGVO) in der Europäischen Union. Diese Verordnung legt die Grundlagen für den Schutz personenbezogener Daten und stellt sicher, dass Unternehmen verantwortungsbewusst und transparent mit den Informationen ihrer Nutzer umgehen.
            </p>
            <p className={'text-sm text-justify pb-5'}>Datenschutz hat für uns oberste Priorität. In dieser Datenschutzerklärung gemäß DSGVO informiert die 3Folio GmbH (FN 572991i) mit Sitz, Ernst-Melchior-Gasse 24/DG, 1020 Wien (im Folgenden '3Folio'), als Verantwortlicher für die Datenverarbeitung über WER, WAS, WANN, WIE, WARUM und WO personenbezogene Kundendaten verarbeitet werden. Unsere Datenerhebung, -verwaltung und -nutzung erfolgen in Übereinstimmung mit allen geltenden Datenschutzbestimmungen (DSGVO, Datenschutzgesetz usw.), dem Telekommunikationsgesetz (TKG) und dem E-Commerce-Gesetz (ECG). Bei Fragen stehen Ihnen unsere Kontaktdaten im Impressum zur Verfügung.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Was?</h3>
            <p className={'text-sm text-justify pb-5'}>Auf unserer Webseite/Plattform 3folio.finance können Sie ein Profil erstellen, mit uns in Kontakt treten und anschließend Workspaces erstellen oder diesen beitreten. Bei der Anmeldung und Nutzung unserer Anwendungen erfassen wir Informationen, die wir verwenden, um die Anwendungen zu verbessern und die Zusammenarbeit zu erleichtern. Zur Anmeldung benötigen wir einige Pflichtangaben wie E-Mail und Name. Andere Angaben, wie Geburtsdatum, Adresse und Profilbild, sind optional und können jederzeit hinzugefügt oder entfernt werden. Diese dienen ausschließlich einer erleichterten Zusammenarbeit mit Ihren Teamkollegen innerhalb eines gemeinsamen Workspaces. Wir respektieren Ihre Privatsphäre und handhaben Ihre Daten verantwortungsbewusst, um Ihnen ein optimales Nutzungserlebnis zu bieten.
            </p>
            <p className={'text-sm text-justify pb-3'}>Im Rahmen des Whitelisting-Prozesses/ Erstellung eines Workspaces erheben wir KYC- und Vertragsdaten. Hierbei verarbeiten wir personenbezogene Daten, die wir im Verlauf unserer Geschäftsbeziehung von Ihnen erhalten, sei es auf obligatorischer oder freiwilliger Basis. Zusätzlich verarbeiten wir personenbezogene Daten, die wir rechtmäßig aus öffentlich zugänglichen Quellen beziehen, wie zum Beispiel Grundbücher, Handelsregister, Vereinsregister, Presse, Medien oder dem Internet. Zu den erfassten personenbezogenen Daten gehören:
            </p>

            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify pb-1'}>Authentifizierungsdaten (z.B., Personalausweis)</li>
                <li className={'text-sm text-justify pb-1'}>Persönliche Daten (z.B., Name, Adresse, Kontaktdaten, Geburtsdatum, Geschlecht, Geburtsort, Nationalität)
                </li>
                <li className={'text-sm text-justify pb-1'}>Bestelldaten (z.B., Zahlungsaufträge, Wallet- oder Kontonummer)</li>
                <li className={'text-sm text-justify pb-1'}>Informationen gemäß dem KYC-Prinzip aufgrund gesetzlicher Verpflichtungen nach dem Finanzmarkt-Geldwäschegesetz (z.B., Kundenprofil, Dokumentation des Zwecks und der Art der Geschäftsbeziehung, Nachweis der Geldherkunft, PEP-Check)
                </li>
                <li className={'text-sm text-justify pb-1'}>Daten aus der Erfüllung unserer vertraglichen Verpflichtungen (z.B., Kaufbetrag, Umtauschbetrag, virtuelle Zahlungsdaten)
                </li>
                <li className={'text-sm text-justify pb-1'}>Werbe- und Verkaufsdaten
                </li>
                <li className={'text-sm text-justify pb-1'}>Dokumentationsdaten (z.B., Aktivitätsprotokoll, Avisprotokoll, Notizen)
                </li>
                <li className={'text-sm text-justify pb-1'}>Verarbeitung von selbst generierten Ergebnissen und Daten unter Einhaltung gesetzlicher und behördlicher Vorgaben
                </li>
            </ul>

            <h3 className={'text-base font-semibold mb-3'}>Warum?
            </h3>
            <p className={'text-sm text-justify pb-5'}>Zweck der Datenerhebung ist die automatisierungsgestützte Datenverarbeitung und -dienstleistung im Rahmen von Portfolioanalysen, Transaktionsanalysen und Anwendungen im Bereich der Blockchain-Technologie und vorbereitenden Buchhaltung. 3Folio verfügt über eine Gewerbeberechtigung für Dienstleistungen in der automatischen Datenverarbeitung und Informationstechnik.
            </p>
            <p className={'text-sm text-justify pb-3'}>Wir nutzen Ihre personenbezogenen Daten, um vertragliche Pflichten zu erfüllen (Art. 6 Abs. 1 lit. b DSGVO) und unsere Leistungen zu erbringen. Dazu gehören die Durchführung von Verträgen mit Ihnen sowie vorvertragliche Maßnahmen aufgrund Ihrer Anfrage. Diese Datenverarbeitung umfasst auch Tätigkeiten im Zusammenhang mit dem Betrieb und der Verwaltung unserer Aktivitäten sowie die Verfolgung rechtlicher Ansprüche. Die Zwecke der Datenverarbeitung können Bedarfsanalyse, Qualitätssicherung, Beratung und Transaktionsabwicklung beinhalten.
            </p>
            <p className={'text-sm text-justify pb-3'}>Zur Erfüllung gesetzlicher Verpflichtungen (Art. 6 Abs. 1 lit. c DSGVO) verarbeiten wir Daten gemäß Finanzmarkt-Geldwäschegesetz, Steuergesetzen und möglichen künftigen regulatorischen Anforderungen. Dazu gehören Identitäts- und Altersprüfungen, Betrugs- und Geldwäscheprävention sowie die Erfüllung steuerlicher und aufsichtsrechtlicher Verpflichtungen.
            </p>
            <p className={'text-sm text-justify pb-3'}>it Ihrer Einwilligung (sofern erteilt) verarbeiten wir Daten gemäß Art. 6 Abs. 1 lit. a DSGVO für bestimmte Zwecke. Des Weiteren verarbeiten wir Daten zur Wahrung berechtigter Interessen (Art. 6 Abs. 1 lit. f DSGVO), beispielsweise bei Beratung und Datenaustausch mit Wirtschaftsauskunfteien, Werbung oder Marktforschung (sofern Sie dem nicht widersprochen haben). Dies beinhaltet auch Maßnahmen zum Schutz von Kunden und Mitarbeitern, Betrugsprävention (Transaktionsüberwachung) sowie Geschäftssteuerung und Produktentwicklung.
            </p>
            <p className={'text-sm text-justify pb-3'}>Alle bei der Registrierung angegebenen personenbezogenen Daten werden deaktiviert. Daten, die Sie auf der Plattform 3folio.finance verarbeitet haben, werden innerhalb von 30 Tagen nach Kündigung oder Ausschluss (i) anonymisiert oder (ii) gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten oder berechtigten Interessen entgegenstehen. Weitere Details finden Sie in unserer Datenschutzerklärung.
            </p>
            <p className={'text-sm text-justify pb-3'}>Auf Wunsch werden sämtliche personenbezogenen Daten des Nutzers gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten oder berechtigten Interessen entgegenstehen. Bitte senden Sie eine E-Mail mit der bei der Registrierung verwendeten E-Mail-Adresse und einem Antrag auf endgültige Löschung an support@3folio.finance oder auf der Plattform 3folio.finance. 3Folio wird auf solche Anfragen umgehend reagieren.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>Wer und wo?
            </h3>
            <p className={'text-sm text-justify pb-5'}>3Folio behandelt alle Nutzerdaten vertraulich und gibt sie nur bei gesetzlicher Verpflichtung oder ausdrücklicher Einwilligung des Nutzers weiter. Nur diejenigen Stellen bzw. Mitarbeiter von 3Folio erhalten Zugriff auf Ihre Daten, die diesen Zugang zur Erfüllung von vertraglichen, gesetzlichen und möglicherweise aufsichtsrechtlichen Pflichten sowie zur Wahrung berechtigter Interessen benötigen. Auch von uns beauftragte Dienstleister (Auftragsverarbeiter) erhalten Daten, soweit diese für die Erbringung der jeweiligen Leistung erforderlich sind oder ein entsprechender Erlaubnistatbestand vorliegt. Alle Auftragnehmer und Erfüllungsgehilfen sind vertraglich dazu verpflichtet (Auftragsverarbeitungsvereinbarung), das Datengeheimnis zu wahren, Ihre Daten vertraulich zu behandeln und sie nur im Rahmen der Leistungserbringung zu verarbeiten.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Wann?
            </h3>
            <p className={'text-sm text-justify pb-5'}>Wir verarbeiten und speichern Ihre personenbezogenen Daten so lange, wie es für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten erforderlich ist. Dies umfasst mindestens die gesamte Dauer der Geschäftsbeziehung und darüber hinaus gemäß den gesetzlichen Aufbewahrungsfristen, wie zum Beispiel 7 Jahre gemäß Bundesabgabenordnung (BAO) bzw. Unternehmensgesetzbuch (UGB), und 30 Jahre nach dem Allgemeinen Zivilrecht (ABGB) für Gewährleistung und Schadensersatz. Es besteht die Möglichkeit, dass die Daten statt einer Löschung anonymisiert werden. In diesem Fall wird jeglicher Personenbezug unwiederbringlich entfernt, und die datenschutzrechtlichen Löschpflichten entfallen.
            </p>
            <p className={'text-sm text-justify pb-5'}>Bei der Mitarbeit innerhalb von 3-Folio Workspaces werden User Aktivitäten, wie das Ändern von Datensätzen (z.B. das hinzufügen eines Wallets oder das ändern eines Asset Prices, etc.) dokumentiert um innerhalb des Teams eine entsprechende Nachvollziehbarkeit der Änderungen zu sichern. Ein Löschen dieser historischen Informationen ist nur Bedingt möglich sofern keine rechtliche Aufbewahrungspflicht besteht.
            </p>
            <p className={'text-sm text-justify pb-5'}>Im Rahmen der Zusammenarbeit innerhalb von 3-Folio Workspaces werden Benutzeraktivitäten, wie das Bearbeiten von Datensätzen (z.B. das Hinzufügen eines Wallets oder die Änderung eines Asset-Preises, etc.), auf User-Ebene dokumentiert, um eine angemessene Nachverfolgbarkeit der vorgenommenen Änderungen im Team sicherzustellen. Das Löschen dieser erfassten Personenbenbezogenen Daten (Name) ist nur in Form einer Anonymisierung möglich, sofern nichts anderes mit dem Workspace Inhaber vereinbart wurde.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Datensicherheit
            </h3>
            <p className={'text-sm text-justify pb-5'}>Datensicherheit hat für uns höchste Priorität, und wir setzen umfassende administrative, technische und physische Maßnahmen ein, um Ihre personenbezogenen Daten vor versehentlicher, rechtswidriger oder unbefugter Zerstörung, Verlust, Veränderung, Zugriff, Offenlegung oder Nutzung zu schützen. Diese Maßnahmen erfüllen hohe internationale Sicherheitsstandards und werden regelmäßig auf ihre Effektivität und Eignung zur Erreichung der angestrebten Sicherheitsziele überprüft.
            </p>
            <p className={'text-sm text-justify pb-1'}>Zu den implementierten technischen und organisatorischen Maßnahmen gehören unter anderem:
            </p>

            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify pb-1'}>SSL-Verschlüsselung auf unseren Websites für den sicheren Austausch von personenbezogenen Daten.
                </li>
                <li className={'text-sm text-justify pb-1'}>Sicherstellung der Vertraulichkeit, Integrität, Verfügbarkeit und Belastbarkeit unserer Systeme und Dienste.
                </li>
                <li className={'text-sm text-justify pb-1'}>Einsatz verschlüsselter Systeme.</li>
                <li className={'text-sm text-justify pb-1'}>Pseudonymisierung und Anonymisierung personenbezogener Daten wenn gewünscht.
                </li>
                <li className={'text-sm text-justify pb-1'}>Kontrolle von Zugang, Zutritt und Übertragung für unsere Büros und Systeme.
                </li>
                <li className={'text-sm text-justify pb-1'}>Maßnahmen zur schnellen Wiederherstellung der Datenverfügbarkeit bei physischen oder technischen Vorfällen.
                </li>
                <li className={'text-sm text-justify pb-1'}>Einführung von Verfahren zur regelmäßigen Überprüfung, Bewertung und Beurteilung der Effektivität technischer und organisatorischer Sicherheitsmaßnahmen.
                </li>
                <li className={'text-sm text-justify pb-1'}>Interne IT-Sicherheitsrichtlinien und Schulungen für IT-Sicherheit.
                </li>
                <li className={'text-sm text-justify pb-1'}>Incident-Response-Management.
                </li>
            </ul>

            <p className={'text-sm text-justify pb-5'}>Diese Schutzmaßnahmen gewährleisten eine umfassende Sicherheit Ihrer Daten während der Verarbeitung und Aufbewahrung.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Zahlungs- und Abonnementinformationen
            </h3>
            <p className={'text-sm text-justify pb-5'}>Bei Nutzung unserer Dienstleistungen können Sie Buchungstransaktionen auslösen. Zur Erfüllung des Vertrags erfolgt bei Bedarf eine Weitergabe von Daten an unsere Zahlungsdienstleister (wie Stripe, PayPal) oder das für die Zahlungsabwicklung beauftragte Kreditinstitut. Die übermittelten Daten beschränken sich dabei auf das notwendige Minimum für die Vertragsabwicklung.
            </p>
            <p className={'text-sm text-justify pb-5'}>Bei Zahlungen per Kreditkarte, Banküberweisung oder Lastschrift erfolgt die Zahlungsabwicklung über den Zahlungsdienstleister Stripe Payment Europe Ltd., Block 4, Harcourt Centre, Harcourt Road, Dublin 2, Irland. Weitere Informationen zum Datenschutz bei Stripe finden Sie unter <a className={'text-accent'} href={'https://stripe.com/privacy#translation'} target={'_blank'}> https://stripe.com/privacy#translation.</a>
            </p>
            <p className={'text-sm text-justify pb-5'}>Obwohl wir keine Kreditkarteninformationen speichern, erfassen wir eine von unserem Anbieter vergebene, einer Person zuordenbare Zahlungs-ID. Zusätzlich speichern wir die Dauer Ihres Abonnements, den Preis, die Währung, die Mehrwertsteuer (basierend auf Länderinformationen) sowie den Zahlungsanbieter.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Dienstleister und Dienste Dritter
            </h3>
            <p className={'text-sm text-justify pb-5'}>Wir teilen Ihre Daten mit Dritten, die uns bei der Bereitstellung und Verbesserung unserer Produkte unterstützen, darunter Bereitstellung von Blockchain & Exchange Account und Transaktionsinformationen, Wartung, Analyse, Prüfung, Zahlungen, Betrugserkennung, Marketing und Entwicklung. Diese Dienstleister haben Zugriff auf Ihre Daten, soweit dies für die Erfüllung ihrer Aufgaben in unserem Auftrag erforderlich ist. Sie sind verpflichtet, die Daten nicht weiterzugeben oder für andere Zwecke zu verwenden. Zu den von uns genutzten Auftragsverarbeitern gehören unter anderem Blockpit AG, Microsoft, Microsoft Azure, Google, Facebook, Miro, Notion Labs, Zoom, Stripe, Rewardful Inc., Zapier, TrustPilot & HubSpot.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Datenschutzrechte
            </h3>
            <p className={'text-sm text-justify pb-5'}>Jede betroffene Person hat das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch und Datenübertragbarkeit. Zudem besteht das Recht, eine Beschwerde bei der Datenschutzaufsichtsbehörde einzureichen. In Österreich ist die zuständige Aufsichtsbehörde die Österreichische Datenschutzbehörde mit folgenden Kontaktdaten: Barichgasse 40-42, 1030 Wien, Telefon: +43 1 531 15-202525, E-Mail: <a className={'text-accent'} href={'mailto:dsb@dsb.gv.at'}> dsb@dsb.gv.at</a>, Website: <a className={'text-accent'} href={'http://www.dsb.gv.at'} target={'_blank'}>http://www.dsb.gv.at</a>.
            </p>
            <p className={'text-sm text-justify pb-5'}>Bevor Sie sich an die Datenschutzbehörde wenden, möchten wir Sie freundlich dazu ermutigen, Ihre Rechte zunächst direkt bei 3Folio geltend zu machen. Sie können uns hierzu unter legal@3folio.finance kontaktieren. Bitte berücksichtigen Sie, dass ohne Ihre Daten die übliche Vertragserfüllung oder Auftragsabwicklung in der Regel nicht möglich ist und daher gegebenenfalls beendet werden muss. Wir sind bestrebt, Ihre Anliegen zügig und transparent zu klären.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Automatische Entscheidungsfindung inklusive Profiling?
            </h3>
            <p className={'text-sm text-justify pb-5'}>Für die Begründung und Durchführung unserer Geschäftsbeziehung setzen wir keine automatisierte Entscheidungsfindung ein. Zudem erfolgt keine Auswertung persönlicher Aspekte (Profiling).
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Plugins und Widgets für soziale Medien
            </h3>
            <p className={'text-sm text-justify pb-1'}>3Folio integriert Inhalte von Drittanbietern auf seiner Plattform über verschiedene Social-Media-Plattformen. Diese sind:
            </p>

            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify pb-1'}>LinkedIn: <a className={'text-accent'} href={'https://www.linkedin.com/company/79986826'} target={'_blank'}>https://www.linkedin.com/company/79986826</a>
                </li>
            </ul>

            <p className={'text-sm text-justify pb-5'}>Die Social-Media-Plugins können die IP-Adresse des Nutzers und die aufgerufene Seite auf 3folio.finance erfassen und möglicherweise ein Cookie setzen, um die Funktion ordnungsgemäß zu gewährleisten. Diese Plugins und Widgets werden entweder von Dritten oder direkt auf unseren Websites gehostet und können die IP-Adresse in ein Drittland oder innerhalb der EU übertragen. Die Interaktion der Benutzer mit diesen Funktionen unterliegt den Datenschutzrichtlinien des Unternehmens, das die Funktion bereitstellt. 3Folio übernimmt keine Verantwortung für die Datenschutzrichtlinien und Praktiken Dritter, einschließlich der Datensicherheitspraktiken. Für Informationen zur Rechtsgrundlage und Speicherdauer sollten Sie sich direkt an diese Unternehmen wenden.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>Cookies
            </h3>
            <p className={'text-sm text-justify pb-5'}>**Zur Datenverarbeitung im Zusammenhang mit Cookies und ähnlichen Technologien verweisen wir Sie ausführlich auf unsere <a className={'text-accent'} href={'https://3folio.finance/legal/cookie-policy'}>Cookie-Erklärung</a>
            </p>

            <p className={'text-sm text-justify pb-5'}>Google Analytics und App Analytics**
            </p>
            <p className={'text-sm text-justify pb-5'}>Die 3Folio-Anwendungen verwenden Google Analytics, einen Webanalysedienst von Google Inc. („Google“). Durch die Verwendung von „Cookies“ (siehe oben) sammelt Google Analytics Informationen über den Besuch einer Website, einschließlich der IP-Adresse, die in der Regel an einen Server von Google in den USA übertragen und dort gespeichert wird. Sofern die IP-Anonymisierung auf der Website aktiviert ist, kürzt Google die IP-Adressen aus EU- und EWR-Mitgliedstaaten. In Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA oder Asien übertragen und dort gekürzt. Google verwendet diese Informationen im Auftrag des Website-Anbieters, um die Nutzung der 3Folio-Anwendungen zu analysieren, Berichte über Website-Aktivitäten zu erstellen und weitere mit den Anwendungen verbundene Dienstleistungen zu erbringen. Google führt die IP-Adresse, die vom Browser im Rahmen von Google Analytics übermittelt wird, nicht mit anderen Daten von Google zusammen.
            </p>
            <p className={'text-sm text-justify pb-5'}>Durch Ihre Einwilligung können bestimmte Daten auch an Partner außerhalb der EU übermittelt werden, wie zum Beispiel in die USA (z.B. Google Analytics). Da für die USA kein Angemessenheitsbeschluss der EU-Kommission vorliegt und trotz umfangreicher Maßnahmen das hohe EU-Datenschutzniveau nicht gewährleistet werden kann, besteht das Risiko, dass übermittelte Daten nicht gelöscht oder zu anderen Zwecken weiterverarbeitet werden. Außerdem könnte ein unverhältnismäßiger Zugriff durch US-Behörden erfolgen oder die Durchsetzung Ihrer Rechte in den USA beeinträchtigt sein. Sie können jedoch Ihre Einwilligung jederzeit widerrufen. Beachten Sie jedoch, dass die Ablehnung einiger dieser Cookies Ihr Benutzererlebnis beeinträchtigen kann.
            </p>
            <p className={'text-sm text-justify pb-5'}>Nutzer können die Erfassung von Informationen durch Google Analytics, einschließlich der IP-Adresse, verhindern, indem sie das Browser-Plugin unter <a className={'text-accent'} href={'https://tools.google.com/dlpage/gaoptout?hl=de'} target={'_blank'}> http://tools.google.com/dlpage/gaoptout?hl=de</a> herunterladen und installieren.</p>
            <p className={'text-sm text-justify pb-5'}>Die 3Folio-Anwendungen verwenden auch Google DoubleClick for Publishers („DFP“), welches Cookies zur Anzeige relevanter Werbung verwendet. Weitere Informationen zu DFP finden Sie unter <a className={'text-accent'} target={'_blank'} href={'https://support.google.com/dfp_premium/answer/2839090?hl=de'}> https://support.google.com/dfp_premium/answer/2839090?hl=de</a>. Nutzer können das Setzen von Cookies durch DFP deaktivieren und damit die Anzeige von für sie relevanter Werbung auf der 3Folio-Plattform auf <a className={'text-accent'} target={'_blank'} href={'https://www.google.com/ads/preferences'}> https://www.google.com/ads/preferences</a> verhindern.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Verwendung von Google Recaptcha
            </h3>
            <p className={'text-sm text-justify pb-5'}>Zum Schutz vor Internetformular-Betrug verwendet 3Folio den Dienst reCAPTCHA von Google Inc. (Google). Die IP-Adresse und erforderlichen Daten, die Ihr Browser im Rahmen von reCAPTCHA übermittelt, werden an Google in den USA gesendet. Für diese Daten gelten die Datenschutzbestimmungen von Google, die Sie unter <a href={'http://www.google.com/policies/privacy/'} className={'text-accent'} target={'_blank'}>http://www.google.com/policies/privacy/</a> einsehen können.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Zugriffsdaten/Server-Logfiles
            </h3>
            <p className={'text-sm text-justify pb-5'}>3Folio erfasst über seinen Webspace-Provider Daten zu jedem Zugriff auf das Angebot (sogenannte Serverlogfiles). Zu diesen Zugriffsdaten gehören: Name der aufgerufenen Webseite, Datei, Datum und Uhrzeit des Zugriffs, übertragene Datenmenge, Meldung über erfolgreichen Zugriff, Browsertyp mit Version, Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite) und die IP-Adresse des anfragenden Anbieters. Diese Protokolldaten werden vom Anbieter ausschließlich für statistische Auswertungen genutzt, um das Angebot zu betreiben, die Sicherheit zu gewährleisten und es zu optimieren. Der Anbieter behält sich jedoch vor, die Protokolldaten nachträglich zu überprüfen, wenn konkrete Anhaltspunkte auf eine rechtswidrige Nutzung hindeuten.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Löschung von Benutzerdaten
            </h3>
            <p className={'text-sm text-justify pb-5'}>Bei Kündigung eines registrierten Nutzerkontos werden sämtliche personenbezogenen Daten des Nutzers deaktiviert. Die erhobenen Daten werden entweder (i) anonymisiert, um zu kennzeichnen, dass sie von einem gelöschten Konto stammen, oder (ii) innerhalb von 30 Tagen gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten oder berechtigten Interessen von 3Folio dagegen sprechen. Alle Daten des Nutzers, die nicht mehr für die Vertragserfüllung durch 3Folio erforderlich sind oder aufgrund gesetzlicher Vorschriften aufbewahrt werden müssen, können auf Wunsch des registrierten Nutzers unwiderruflich gelöscht werden. Ein solcher Löschungsantrag ist per E-Mail an support@3folio.finance unter Angabe der bei der Registrierung verwendeten E-Mail-Adresse zu stellen. 3Folio wird dieser Anfrage umgehend nachkommen.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Newsletter
            </h3>
            <p className={'text-sm text-justify pb-5'}>3Folio verschickt Newsletter sowohl an registrierte Nutzer als auch an Personen, die ihre E-Mail-Adresse in Verbindung mit anderen von 3Folio angebotenen Diensten, wie z. B. <a className={'text-accent'} href={'https://3folio.finance'} target={'_blank'}> https://3folio.finance</a>, angegeben haben. Die Verarbeitung der E-Mail-Adresse erfolgt zum Zweck des Newsletterversands, wobei der Nutzer bei jeder erhaltenen Newsletter-E-Mail die Möglichkeit hat, sich abzumelden. Die rechtliche Grundlage für diesen Vorgang beruht auf der Einwilligung des Nutzers gemäß § 107 TKG.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Änderungen der Datenschutzrichtlinie
            </h3>
            <p className={'text-sm text-justify pb-5'}>Um sämtlichen gesetzlichen Anforderungen gerecht zu werden, wird diese Datenschutzerklärung kontinuierlich angepasst. Die aktuelle Version ist stets auf unserer Website verfügbar. Falls wesentliche Änderungen an dieser Datenschutzrichtlinie vorgenommen werden, werden Sie nach der Anmeldung in Ihrem 3Folio-Konto benachrichtigt, und die aktualisierte Version der Datenschutzrichtlinie wird bereitgestellt. Sollte geltendes Recht dies erfordern, wird 3Folio Ihre ausdrückliche Zustimmung für wesentliche Änderungen einholen.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Kontakt
            </h3>
            <p className={'text-sm text-justify pb-5'}>Wenn Sie weitere Fragen zu dieser Datenschutzrichtlinie oder der Verarbeitung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte entweder an unseren Support (<a href={'mailto:support@3folio.finance'} target={'_blank'} className={'text-accent'}>support@3folio.finance</a>) oder unser Rechtsteam (<a href={'mailto:legal@3folio.finance'} target={'_blank'} className={'text-accent'}>legal@3folio.finance</a>).
            </p>

        </div>
    )
}