import React from "react";

export const RocketIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_2722_9700)">
                <path d="M14.1667 10C13.2298 10.9369 9.58337 13.75 9.58337 13.75M14.1667 10C16.6667 7.5 16.6667 5.83333 16.6667 3.33333C14.1667 3.33333 12.5 3.33333 10 5.83333M14.1667 10L14.4752 11.5427C14.8031 13.182 14.29 14.8767 13.1079 16.0588L12.6041 16.5626C12.1344 17.0324 11.3455 16.9219 11.0229 16.3411L9.58337 13.75M10 5.83333L6.25004 10.4167M10 5.83333L8.45733 5.52479C6.81803 5.19693 5.12333 5.71004 3.94121 6.89216L3.43747 7.3959C2.96769 7.86569 3.07817 8.65451 3.65894 8.97716L6.25004 10.4167M6.25004 10.4167L9.58337 13.75M12.9167 7.08374L12.0834 7.91707M2.50004 17.5L5.19423 16.9612C6.05041 16.7899 6.66671 16.0382 6.66671 15.165V15.165C6.66671 14.1534 5.84663 13.3333 4.83501 13.3333V13.3333C3.96187 13.3333 3.21012 13.9496 3.03888 14.8058L2.50004 17.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2722_9700">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}