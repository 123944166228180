import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import {WEBSITE_SHOW_PRICE_LIST} from "../utils";
import { T } from "@tolgee/react";

function Footer() {
    const { trackEvent } = useMatomo();

    const year = new Date().getFullYear()

    const onContactLinkClick = (type: 'tel' | 'mail') => { 
        trackEvent({ category: 'footer', action: `${type} link click` });
        ReactGA.event({
            category: 'footer',
            action: `${type} link click`,
            label: 'Footer Link Click',
            nonInteraction: true
        });
    }


    const landingPageLinks: LinkObj[] = [
        { href: '/#features', label: 'features' },
        { href: '/#roadmap', label: 'roadmap' },
        { href: '/#faq', label: 'FAQ' },
        // { href: '/blog', label: 'Blog' },
        { href: '/pricing', label: 'price_list' },
        { href: '/integrations', label: 'integrations_status' },
        { href: 'https://3folio.notion.site/Job-Board-d0e0abd8fc71457987258db788ca6923', label: 'Jobs' },
    ];

    const legalLinks: LinkObj[] = [
        // { href: '/privacy', label: 'Privacy' },
        { href: '/legal/terms-of-use', label: 'Terms of use' },
        { href: '/legal/terms-and-conditions', label: 'Terms and conditions' },
        { href: '/legal/privacy-policy', label: 'Privacy Policy' },
        { href: '/legal/cookie-policy', label: 'Cookie Policy' },
        { href: '/imprint', label: 'imprint' }
    ];

    const address1 = [
        "Adress Vienna",
        "3Folio GmbH",
        "Ernst Melchior Gasse 24/DG",
        "1020 Wien"
    ];

    const address2 = [
        "Adress Linz",
        "Danube Blockchain Hub",
        "Peter-Behrens-Platz 4",
        "4020 Linz"
    ];

    const phone = "+43 676 366 80 26";
    const email = "info@3folio.finance";

    const scrollToTopOnClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return (
        <>
        <footer className="p-[30px] border-t border-[#E2E8F0]">
            <div className="max-w-screen-xl flex flex-row flex-wrap mx-auto">
                <div className="w-1/5 max-md:w-1/2 max-md:mb-12 max-[450px]:w-full">

                    <ul className={'mt-0'}>
                        <li key={`footer-logo`} className="py-1 text-white text-left">
                            <Link to={'/'} onClick={scrollToTopOnClick}>
                                <img src="/logo.png" alt="logo" className="h-14 -mt-1"/>
                            </Link>
                        </li>
                        <li key={"enterprise"} className={'py-1  text-left text-[#6F6C90] text-[14px] font-medium'}>
                            Enterprise Crypto Co-Pilot
                        </li>
                        <li key={"social"} className={'py-4 flex text-left text-[#6F6C90] text-[14px] font-medium'}>
                            <a href={'https://www.linkedin.com/company/79986826'} target={'_blank'} className={'pr-3'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8205 1.5H3.29437C2.33672 1.5 1.5 2.18906 1.5 3.13547V20.7005C1.5 21.652 2.33672 22.5 3.29437 22.5H20.8153C21.7781 22.5 22.5 21.6464 22.5 20.7005V3.13547C22.5056 2.18906 21.7781 1.5 20.8205 1.5ZM8.00953 19.0045H5.00109V9.65063H8.00953V19.0045ZM6.60938 8.22844H6.58781C5.625 8.22844 5.00156 7.51172 5.00156 6.61453C5.00156 5.70094 5.64141 5.00109 6.62578 5.00109C7.61016 5.00109 8.2125 5.69578 8.23406 6.61453C8.23359 7.51172 7.61016 8.22844 6.60938 8.22844ZM19.0045 19.0045H15.9961V13.89C15.9961 12.6647 15.5583 11.8275 14.4698 11.8275C13.6383 11.8275 13.1461 12.39 12.9272 12.938C12.8452 13.1348 12.8231 13.403 12.8231 13.6767V19.0045H9.81469V9.65063H12.8231V10.9523C13.2609 10.3289 13.9448 9.43172 15.5363 9.43172C17.5111 9.43172 19.005 10.7334 19.005 13.5398L19.0045 19.0045Z" fill="#4F4F4F"/>
                                </svg>
                            </a>
                            {/*<Link to={'#'}>*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">*/}
                            {/*        <path d="M23.25 5.13282C22.406 5.49955 21.513 5.74116 20.5992 5.85001C21.5595 5.28769 22.2817 4.39434 22.6303 3.33751C21.7224 3.86841 20.7307 4.24092 19.6978 4.43907C19.2629 3.98322 18.7397 3.62059 18.1603 3.3732C17.5808 3.12581 16.9571 2.99884 16.327 3.00001C13.7761 3.00001 11.7117 5.03438 11.7117 7.5422C11.7099 7.89102 11.7499 8.23881 11.8308 8.57813C10.0016 8.49238 8.2104 8.02575 6.57187 7.2081C4.93333 6.39044 3.48351 5.23977 2.31516 3.8297C1.90527 4.52069 1.6885 5.30909 1.6875 6.11251C1.6875 7.68751 2.50922 9.0797 3.75 9.89532C3.01487 9.87787 2.29481 9.68331 1.65094 9.32813V9.38438C1.65094 11.5875 3.24469 13.4203 5.35406 13.8375C4.9574 13.9432 4.54864 13.9968 4.13812 13.9969C3.84683 13.9974 3.5562 13.9691 3.27047 13.9125C3.85687 15.7172 5.56359 17.0297 7.58531 17.0672C5.94252 18.3333 3.9256 19.0175 1.85156 19.0125C1.48341 19.012 1.11561 18.99 0.75 18.9469C2.85993 20.2942 5.31255 21.0068 7.81594 21C16.3172 21 20.9616 14.0766 20.9616 8.07188C20.9616 7.87501 20.9564 7.67813 20.947 7.48595C21.8485 6.84472 22.6283 6.04787 23.25 5.13282Z" fill="#4F4F4F"/>*/}
                            {/*    </svg>*/}
                            {/*</Link>*/}
                        </li>
                    </ul>
                </div>
                <div className="w-1/5 max-md:w-1/2 max-md:mb-12 max-[450px]:w-full">
                    <p className="font-bold text-[16px] text-left text-[#475569]">
                        Links
                    </p>
                    <ul>
                        {landingPageLinks.map((link, i) => (
                            <>

                                {link.href.includes('http') ? (
                                    <li key={`footer-${link.label}-${i}`} className="py-1 text-[#475569] text-left">
                                        <a href={link.href} target={'_blank'}>
                                            {link.label}
                                        </a>
                                    </li>
                                ) : (
                                    <>
                                        { link.href !== '/pricing'  ?
                                    <li key={`footer-aa-${link.label}-${i}`} className="py-1 text-[#475569] text-regular text-left">
                                        <Link to={link.href}>
                                            <T keyName={link.label} />
                                        </Link>
                                    </li> :
                                    WEBSITE_SHOW_PRICE_LIST &&
                                    <li key={`footer-aa-da-${link.label}-${i}`} className="py-1 text-[#475569] text-regular text-left">
                                        <Link to={link.href}>
                                            <T keyName={link.label} />
                                        </Link>
                                    </li>}
                                        </>
                                )}
                            </>
                        ))}
                    </ul>
                </div>
                <div className="w-1/5 max-md:w-1/2 max-md:mb-12 max-[450px]:w-full">
                    <p className="font-bold text-[16px] text-left text-[#475569]">
                        Legal
                    </p>
                    <ul>
                        {legalLinks.map((link,i) => (
                            <li key={`some-${link.label}-${i}`} className="py-1 text-[#475569] text-regular text-left">
                              <Link to={link.href} onClick={scrollToTopOnClick}>
                                    <T keyName={link.label} />
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="w-1/5 max-md:w-1/2 max-md:mb-12 max-[450px]:w-full">
                    <ul className={'addresses'}>
                        {address1.map((line, i) => (
                            <li key={`adresses1-${line}`} className={"text-white text-[#475569] text-regular text-left " + (i === 0 && "font-bold text-[16px] text-left text-[#475569]")} >
                                {line}
                            </li>
                        ))}
                    </ul>
                    <ul>
                        <li key='phone' className={" font-bold text-[16px] text-left text-[#475569] text-left mb-5 mt-8"}>
                            <T keyName={'phone'} />
                        </li>
                        <li key='phone1' className={"text-white text-left text-[#475569] text-regular underline hover:text-slate-800"}>
                            <a href={"tel:" + phone} target="_blank" rel="noreferrer" onClick={() => onContactLinkClick('tel')}>
                                { phone }
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="w-1/5 max-md:w-1/2 max-md:mb-12 max-[450px]:w-full">
                    <ul className={'addresses'}>
                        {address2.map((line, i) => (
                            <li key={`adresses2-${line}-${i}`} className={" text-[#475569] text-regular text-left " + (i === 0 && "font-bold text-[16px] text-left text-[#475569]")} >
                                {line}
                            </li>
                        ))}
                    </ul>
                    <ul>
                        <li key={'email'} className={" font-bold text-[16px] text-left text-[#475569] mb-5 text-left font-bold mt-8"}>
                            E-Mail
                        </li>
                        <li  key={'email1'} className={"text-[#475569] text-regular text-left underline hover:text-slate-800"}>
                            <a href={"mailto:" + email} target="_blank" rel="noreferrer" onClick={() => onContactLinkClick('mail')}>
                                { email }
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </footer>
    <div className="bg-accent text-center text-white font-medium text-[14px] py-2 max-lg:p-5" >
        @{year} 3Folio. All rights reserved.
    </div>
    </>
    )
}

export default Footer;

interface LinkObj {
    label: string;
    href: string;
}