import { T } from "@tolgee/react";

function PressLogos() {
  const logos = [
    "DEMO/logos/blockpit.png",
    "DEMO/logos/steger.png",
    "DEMO/logos/validvent-tax.svg",
  ];

  return (
    <div className="my-16 py-5">
      <p className="text-[#98A2B3] font-semibold text-[20px] mb-[20px]">
        <T keyName={"known_from"} />
      </p>
      <div className="flex flex-row flex-wrap items-center justify-center">
        {logos.map((logo, i) => (
          <div className="w-1/2 md:w-auto flex flex-row justify-center">
            <img
              src={logo}
              alt="logo"
              height="42px"
              className="max-w-[120px] md:max-w-full grayscale brightness-105 max-h-[42px] m-6 object-contain"
              key={"pressLogo-" + i}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PressLogos;
