import React from "react";

export const QuotesIcon = () => {
  return (
    <svg
      width="51"
      height="50"
      viewBox="0 0 51 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_518_10796)">
        <path
          d="M11.4875 19.7917C13.6507 19.7917 15.7654 20.4332 17.5641 21.635C19.3627 22.8368 20.7646 24.545 21.5924 26.5436C22.4203 28.5422 22.6369 30.7413 22.2148 32.863C21.7928 34.9847 20.7511 36.9335 19.2215 38.4632C17.6918 39.9928 15.743 41.0345 13.6213 41.4565C11.4996 41.8786 9.30047 41.662 7.3019 40.8341C5.30333 40.0063 3.59513 38.6044 2.3933 36.8057C1.19147 35.0071 0.55 32.8924 0.55 30.7292L0.5 29.1667C0.5 23.3651 2.80468 17.8011 6.90704 13.6987C11.0094 9.59637 16.5734 7.29169 22.375 7.29169V13.5417C20.3222 13.5362 18.2887 13.9377 16.392 14.7229C14.4953 15.5082 12.7731 16.6617 11.325 18.1167C10.7622 18.6785 10.243 19.2823 9.77188 19.9229C10.3313 19.8354 10.9031 19.7886 11.4844 19.7886L11.4875 19.7917ZM39.6125 19.7917C41.7757 19.7917 43.8904 20.4332 45.6891 21.635C47.4877 22.8368 48.8896 24.545 49.7174 26.5436C50.5453 28.5422 50.7619 30.7413 50.3398 32.863C49.9178 34.9847 48.8761 36.9335 47.3465 38.4632C45.8168 39.9928 43.868 41.0345 41.7463 41.4565C39.6246 41.8786 37.4255 41.662 35.4269 40.8341C33.4283 40.0063 31.7201 38.6044 30.5183 36.8057C29.3165 35.0071 28.675 32.8924 28.675 30.7292L28.625 29.1667C28.625 23.3651 30.9297 17.8011 35.032 13.6987C39.1344 9.59637 44.6984 7.29169 50.5 7.29169V13.5417C48.4472 13.5362 46.4137 13.9377 44.517 14.7229C42.6203 15.5082 40.8981 16.6617 39.45 18.1167C38.8872 18.6785 38.368 19.2823 37.8969 19.9229C38.4563 19.8354 39.0281 19.7886 39.6125 19.7886V19.7917Z"
          fill="#275EF7"
        />
      </g>
      <defs>
        <clipPath id="clip0_518_10796">
          <rect
            width="50"
            height="50"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
