import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Subheader from "../Subheader";

export const ErrorPage = () => {

        return (
            <div className={'text-center justify-center'}>
                <Header />
                <Subheader title="Error..." subtitle={''}/>
                <div className="container flex items-center justify-center max-w-screen-lg mx-auto mt-10 text-left max-lg:px-5">
                    <div className="space-y-6 flex relative min-h-[300px] justify-center items-center ">
                        <h4 className={'text-center text-8xl pb-12 font-extrabold'}>404 Not found</h4>
                    </div>
                </div>
                <Footer />
            </div>
        )
    
}