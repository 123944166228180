import React from "react";
import { CheckIconCircle } from "../../components/icons/CheckIconCircle";

export const StarterDescList = () => {
  return (
    <div className={"flex justify-start flex-col"}>
      <div className={"flex justify-start"}>
        <span
          className={"justify-start text-[12px] text-[#83899F] text-center"}
        >
           Ideal for young crypto funds that want to start automating their administration.
        </span>
      </div>
    </div>
  );
};
