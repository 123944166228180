import React from "react";
import { T } from "@tolgee/react";
import { FeeCalculationIcon } from "./icons/FeeCalculationIcon";
import { ClientCommunicationIcon } from "./icons/ClientCommunicationIcon";
import { ClientManagementIcon } from "./icons/ClientManagementIcon";
import { MonitorMethodsIcon } from "./icons/MonitorMethodsIcon";
import { ActionLoggingIcon } from "./icons/ActionLoggingIcon";
import { DataRecordingIcon } from "./icons/DataRecordingIcon";

type ListItemProps = {
  title: string;
  content: string;
  icon: string;
  hoverImage?: string;
  link?: string;
  setHoverImage: (image: string) => void;
};

export const ListItemIconType = {
  FEE_CALCULATION: "FEE_CALCULATION",
  CLIENT_MANAGEMENT: "CLIENT_MANAGEMENT",
  CLIENT_COMMUNICATION: "CLIENT_COMMUNICATION",
  ACTION_LOGGING: "ACTION_LOGGING",
  DATA_RECORDING: "DATA_RECORDING",
  MONITOR_METHODS: "MONITOR_METHODS",
};

export const FeaturedTabsListItem = (props: ListItemProps) => {
  const defaultHoverImage = "funds-illustration.svg";

  const getListItemIcon = (icon: string) => {
    switch (icon) {
      case ListItemIconType.FEE_CALCULATION:
        return <FeeCalculationIcon />;

      case ListItemIconType.CLIENT_COMMUNICATION:
        return <ClientCommunicationIcon />;

      case ListItemIconType.CLIENT_MANAGEMENT:
        return <ClientManagementIcon />;

      case ListItemIconType.ACTION_LOGGING:
        return <ActionLoggingIcon />;

      case ListItemIconType.DATA_RECORDING:
        return <DataRecordingIcon />;

      case ListItemIconType.MONITOR_METHODS:
        return <MonitorMethodsIcon />;

      default:
        return <FeeCalculationIcon />;
    }
  };

  return (
    <div
      className="flex flex-col items-center justify-center"
      onMouseEnter={() => {
        props.hoverImage && props.setHoverImage(props.hoverImage);
      }}
      onMouseLeave={() => {
        props.setHoverImage(defaultHoverImage);
      }}
    >
      <div className="rounded-xl w-full lg:max-w-[550px] min-w-full min-h-[40px] border border-[#EFF0F6] text-start  py-4 px-8">
        <div className="w-full flex justify-start items-center gap-[12px] mb-4">
          <div className="flex rounded-md bg-[#F0F5FF] items-center">
            {getListItemIcon(props.icon)}
          </div>
          <h2 className="font-semibold text-[#061C3D] text-[16px]">
            <T keyName={props.title} />
          </h2>
        </div>
        <p className="text-[#6F6C90] text-[14px] mb-3">
          <T keyName={props.content} />
        </p>
        {props.link && (
          <a className="text-[#156CFE] text-md" href={props.link}>
            <T keyName="show_more" />
          </a>
        )}
      </div>
    </div>
  );
};
