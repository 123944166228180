import React from "react";

export const ClientManagementIcon = () => {
  return (
    <div className="p-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M11.4584 15.625H8.33337C6.39195 15.625 5.42124 15.625 4.65553 15.9422C3.63458 16.3651 2.82343 17.1762 2.40054 18.1972C2.08337 18.9629 2.08337 19.9336 2.08337 21.875M16.1459 3.42787C17.6729 4.04599 18.75 5.54303 18.75 7.29167M12.4999 22.3958L14.6094 21.974C14.7933 21.9372 14.8853 21.9188 14.971 21.8852C15.0471 21.8553 15.1195 21.8166 15.1865 21.7698C15.2621 21.7171 15.3284 21.6508 15.461 21.5182L22.3959 14.5834C22.9712 14.0081 22.9712 13.0753 22.3959 12.5C21.8206 11.9247 20.8878 11.9247 20.3125 12.5L13.3777 19.4349C13.245 19.5675 13.1787 19.6338 13.126 19.7093C13.0793 19.7764 13.0406 19.8487 13.0107 19.9248C12.9771 20.0106 12.9587 20.1025 12.9219 20.2864L12.4999 22.3958ZM14.0625 7.29167C14.0625 9.59285 12.1971 11.4583 9.89587 11.4583C7.59469 11.4583 5.72921 9.59285 5.72921 7.29167C5.72921 4.99048 7.59469 3.125 9.89587 3.125C12.1971 3.125 14.0625 4.99048 14.0625 7.29167Z"
          stroke="#275EF7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
