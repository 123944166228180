import React from "react";

export const AccountingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
    >
      <rect
        opacity="0.1"
        x="0.480225"
        width="35"
        height="35"
        rx="8"
        fill="#275EF7"
      />
      <path
        opacity="0.3"
        d="M21.1426 18.5539C23.4693 18.5539 25.4395 20.5516 24.324 22.5935C24.2044 22.8124 24.0749 23.0265 23.9355 23.235C23.0096 24.6207 21.6937 25.7007 20.1541 26.3384C18.6144 26.9761 16.9202 27.143 15.2858 26.8179C13.6513 26.4928 12.1499 25.6903 10.9716 24.5119C9.79317 23.3335 8.99068 21.8321 8.66556 20.1977C8.34045 18.5632 8.50731 16.869 9.14505 15.3294C9.78279 13.7898 10.8628 12.4738 12.2484 11.548C12.457 11.4086 12.671 11.279 12.8899 11.1594C14.9318 10.0439 16.9296 12.0141 16.9296 14.3409C16.9296 16.6676 18.8158 18.5539 21.1426 18.5539Z"
        fill="#275EF7"
      />
      <path
        d="M19.039 11.33C19.039 9.50261 20.5751 7.96326 22.2634 8.66258C23.2857 9.08602 24.2146 9.70667 24.997 10.4891C25.7794 11.2715 26.4001 12.2004 26.8235 13.2227C27.5228 14.911 25.9835 16.4471 24.1561 16.4471L23.2519 16.4471C20.9252 16.4471 19.039 14.5609 19.039 12.2342L19.039 11.33Z"
        fill="#275EF7"
      />
    </svg>
  );
};
