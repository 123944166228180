import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useState } from "react";
import ReactGA from "react-ga";
import { PlusIcon } from "../../components/icons/PlusIcon";
import { PlusMinusIcon } from "../../components/icons/PlusMinusIcon";
import { CloseIcon } from "../../components/icons/CloseIcon";
import { T } from "@tolgee/react";

function FAQ() {
  const { trackEvent } = useMatomo();

  const [selectedIndex, setSelectedIndex] = useState(-1);

  const onQuestionClick = (i: number) => {
    if (selectedIndex === i) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(i);
    }
  };

  const questions = [
    {
      question: "faq_1",
      answer: "faq_1_desc",
    },
    {
      question: "faq_2",
      answer: "faq_2_desc",
    },
    {
      question: "faq_3",
      answer: "faq_3_desc",
    },
    {
      question: "faq_4",
      answer: "faq_4_desc",
    },
    {
      question: "faq_5",
      answer: "faq_5_desc",
    },
    {
      question: "faq_6",
      answer: "faq_6_desc",
    },
    {
      question: "faq_7",
      answer: "faq_7_desc",
    },
    {
      question: "faq_8",
      answer: "faq_8_desc",
    },
    {
      question: "faq_9",
      answer: "faq_9_desc",
    },
    {
      question: "faq_10",
      answer: "faq_10_desc",
    },
  ];

  const trackQuestionClick = (question: string, id: number) => {
    trackEvent({
      category: "FAQ",
      action: `question #${id} clicked: ${question}`,
    });
    ReactGA.event({
      category: "FAQ",
      action: `question #${id} clicked: ${question}`,
      label: "FAQ",
      nonInteraction: true,
    });
  };

  return (
    <div className="max-lg:p-5 ">
      <div
        className="w-full max-w-screen-xl h-fit box mx-auto rounded-3xl "
        id="faq"
      >
        <h1 className="text-2xl font-bold text-[#0A1B39] mb-3 max-md:text-2xl">
          <T keyName={"main_faq_title"} />
        </h1>
        <p className="font-medium text-md text-[#667085] mb-10">
          <span>
            <T keyName={"main_faq_desc"} />
          </span>
          <a
            href="mailto:info@3folio.finance"
            className="text-[#454545] font-bold"
          >
            {" "}
            info@3folio.finance
          </a>
        </p>

        <div
          className={
            "flex justify-center items-center w-full flex-col md:flex-row"
          }
        >
          <div className={"w-full  mx-3"}>
            {questions.map(
              (question, i) =>
                i < 5 && (
                  <div
                    className="relative rounded-[16px] px-4 py-5 my-4 shadow-cards"
                    key={"question-" + i}
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        onQuestionClick(i);
                        trackQuestionClick(question.question, i + 1);
                      }}
                    >
                      <h3
                        className={
                          "text-left text-[#3c3c4380] text-lg font-bold"
                        }
                      >
                        0{i + 1}
                      </h3>
                      <h2 className="text-md font-semibold text-left text-black w-10/12">
                        <T keyName={question.question} />
                      </h2>
                      <span
                        className={`absolute top-10 right-5 ${
                          selectedIndex !== i ? "bg-[#F3F5F6]" : "bg-gray-600"
                        } items-center justify-center flex w-[32px] h-[32px] rounded-full`}
                      >
                        {selectedIndex !== i ? <PlusIcon /> : <CloseIcon />}
                      </span>
                      {/*<i className={(selectedIndex === i ? "iconoir-nav-arrow-up" : "iconoir-nav-arrow-down") + " text-3xl font-semibold text-left absolute right-0 top-0" }></i>*/}
                    </div>
                    {selectedIndex === i && (
                      <p className="pt-3 text-slate-600 text-left text-sm">
                        <T keyName={question.answer} />
                      </p>
                    )}
                  </div>
                )
            )}
          </div>

          <div className={"w-full mx-3"}>
            {questions.map(
              (question, i) =>
                i > 4 && (
                  <div
                    className="relative rounded-[16px] px-4 py-5 my-4 shadow-cards"
                    key={"question-" + i}
                  >
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        onQuestionClick(i);
                        trackQuestionClick(question.question, i + 1);
                      }}
                    >
                      <h3
                        className={
                          "text-left text-[#3c3c4380] text-lg font-bold"
                        }
                      >
                        {i < 9 && "0"}
                        {i + 1}
                      </h3>
                      <h2 className="text-md font-semibold text-left text-black w-10/12">
                        <T keyName={question.question} />
                      </h2>
                      <span
                        className={`absolute top-10 right-5 ${
                          selectedIndex !== i ? "bg-[#F3F5F6]" : "bg-gray-600"
                        } items-center justify-center flex w-[32px] h-[32px] rounded-full`}
                      >
                        {selectedIndex !== i ? <PlusIcon /> : <CloseIcon />}
                      </span>
                      {/*<i className={(selectedIndex === i ? "iconoir-nav-arrow-up" : "iconoir-nav-arrow-down") + " text-3xl font-semibold text-left absolute right-0 top-0" }></i>*/}
                    </div>
                    {selectedIndex === i && (
                      <p className="text-sm text-slate-600 pt-3 text-left">
                        <T keyName={question.answer} />
                      </p>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
