import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {FileIcon} from "../../components/icons/FileIcon";
import {PrivacyIcon} from "../../components/icons/PrivacyIcon";
import {CookiePolicyIcon} from "../../components/icons/CookiePolicyIcon";
import {TermsAndConditions} from "./TermsAndConditions";
import {PrivacyPolicy} from "./PrivacyPolicy";
import {CookiePolicy} from "./CookiePolicy";
import {useNavigate} from "react-router";
import {TermsOfUseContent} from "./TermsOfUseContent";
import {TermsOfUseIcon} from "../../components/icons/TermsOfUseIcon";

export const TermsOfUse = () => {
    const navigate = useNavigate()

    return (
        <>
            <Header />

            <div className="mx-auto max-w-screen-xl container w-full px-2 flex justify-center flex-col xl max-h-max max-lg:px-2 pb-20 ">
                <h1 className="text-5xl flex justify-center text-center text-[#0A1B39] text-[24px] font-bold pt-40 -mt-[90px] font-tt max-md:text-3xl md:w-[70%] md:ml-[15%]">
                    Legal
                </h1>
                <div className="dark:bg-backgroundDM mt-6 flex flex-col bg-background">
                    <div className="flex  md:flex-row flex-col items-center justify-center gap-5 px-4 pb-4 pt-4 md:grid-cols-1 md:justify-center mb-6">
                        <div onClick={() => navigate('/legal/terms-of-use')} className={` md:w-1/4 w-full flex py-[18px] pl-[33px] shadow-inner-active border border-[transparent]  flex cursor-pointer  rounded-[20px]   items-center  text-center justify-start m-w-[100px]`}>
                            <div className={'flex  items-center rounded-lg p-3 bg-accent bg-opacity-[0.1]'}>
                                <TermsOfUseIcon />
                            </div>
                            <span className={`pl-2  text-[#275EF7] font-semibold`}>
                                Terms of Use
                            </span>
                        </div>
                        <div onClick={() => navigate('/legal/terms-and-conditions')} className={`md:w-1/4 w-full flex py-[18px] pl-[33px]  shadow-sm shadow-choice-selectors border border-[#EFF0F6] flex cursor-pointer  rounded-[20px]   items-center  text-center justify-start m-w-[100px]`}>
                            <div className={'flex items-center rounded-lg p-3 bg-accent bg-opacity-[0.1]'}>
                                <FileIcon />
                            </div>
                            <span className={`pl-2 text-[#6F6C90] font-semibold`}>
                                Terms & Conditions
                            </span>
                        </div>
                        <div onClick={() => navigate('/legal/privacy-policy')} className={`md:w-1/4 w-full flex py-[18px] px-[33px]  shadow-sm shadow-choice-selectors border border-[#EFF0F6] flex cursor-pointer  rounded-[20px]   items-center  text-center justify-start m-w-[100px]`}>
                            <div className={'flex items-center rounded-lg p-3 bg-accent bg-opacity-[0.1]'}>
                                <PrivacyIcon />
                            </div>
                            <span className={`pl-2 text-[#6F6C90] font-semibold`}>
                                Privacy Policy
                            </span>
                        </div>
                        <div onClick={() => navigate('/legal/cookie-policy')} className={`md:w-1/4 w-full flex py-[18px] px-[33px]  shadow-sm shadow-choice-selectors border border-[#EFF0F6] flex cursor-pointer  rounded-[20px]   items-center  text-center justify-start m-w-[100px]`}>
                            <div className={'flex items-center rounded-lg p-[5px] bg-accent bg-opacity-[0.1] '}>
                                <CookiePolicyIcon />
                            </div>
                            <span className={`pl-2 text-[#6F6C90] font-semibold`}>
                                Cookie Policy
                            </span>
                        </div>

                    </div>
                </div>
                <div className={'flex text-start m-auto lg:w-[97%] md:w-full justify-start'}>
                  <TermsOfUseContent />
                </div>
            </div>



            <Footer />
        </>
    )
}