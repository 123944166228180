import React from "react";

export const IndividualIcon = ({ color = "#485066" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M8.68187 10.5952C6.56848 10.5952 4.68906 11.6156 3.49253 13.1992C3.235 13.54 3.10624 13.7104 3.11045 13.9407C3.11371 14.1187 3.22544 14.3431 3.36544 14.453C3.54666 14.5952 3.79778 14.5952 4.30002 14.5952H13.0637C13.566 14.5952 13.8171 14.5952 13.9983 14.453C14.1383 14.3431 14.25 14.1187 14.2533 13.9407C14.2575 13.7104 14.1287 13.54 13.8712 13.1992C12.6747 11.6156 10.7953 10.5952 8.68187 10.5952Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.68187 8.59522C10.3387 8.59522 11.6819 7.25207 11.6819 5.59521C11.6819 3.93836 10.3387 2.59521 8.68187 2.59521C7.02501 2.59521 5.68187 3.93836 5.68187 5.59521C5.68187 7.25207 7.02501 8.59522 8.68187 8.59522Z"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
