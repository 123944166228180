import { T } from "@tolgee/react";
import { useEffect, useRef, useState } from "react";
import { QuotesIcon } from "../../components/icons/QuotesIcon";

function FeaturesIllustrated() {
  const featureRefs = useRef([]);

  const itemRef = useRef<HTMLDivElement>(null);

  const [lineWidth, setLineWidth] = useState(200);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-fadeIn");
          }
        });
      },
      { threshold: 0.1 }
    );

    featureRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      if (featureRefs.current) {
        featureRefs.current.forEach((ref) => {
          if (ref) {
            observer.unobserve(ref);
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    if (itemRef.current) {
      const parentWidth = itemRef.current.offsetWidth;
      setLineWidth(parentWidth - 400);
    }
  }, []);

  const features: featureObject[] = [
    {
      title: "",
      subtitle: "tracking_subtitle",
      desc: "tracking_desc",
      image: "./tracking.svg",
      milestonePeriod: "milestone_1_period",
    },
    {
      title: "",
      subtitle: "rm_accounting_subtitle",
      desc: "rm_accounting_desc",
      image: "./accounting.svg",
      milestonePeriod: "milestone_2_period",
    },
    {
      title: "",
      subtitle: "reporting_subtitle",
      desc: "reporting_desc",
      image: "./report.svg",
      milestonePeriod: "milestone_3_period",
    },
    {
      title: "",
      subtitle: "subtitle_4",
      desc: "desc_4",
      image: "./report.svg",
      milestonePeriod: "milestone_4_period",
    },
    {
      title: "",
      subtitle: "subtitle_5",
      desc: "desc_5",
      image: "./report.svg",
      milestonePeriod: "milestone_5_period_1",
    }
  ];

  const style = `

        .inset-right{
            display:flex;
            justify-content:flex-end;
            align-items:center;
        }

        .inset-left::after, .inset-right::after {
            content: '';
            height: 100%;
            width: 7px;
            background: #156CFE19;
            position: absolute;
            top: 0;
        }
        .inset-left::after {
            right: -7px;
        }
        .inset-right::after {
            left: 0;
        }

         .inset-milestone-left,  .inset-milestone-right{
          width:28px;
            height:28px;
            border-radius:50%;
            background: #C7D2FE;
            position:absolute;
            bottom:50%;
            display:flex;
            justify-content:center;
            align-items:center;
         }

        .inset-milestone-left{
            right:-17.5px;
        }
        .inset-milestone-right{
            left:-10.5px;
        }
        .circle{
            width:14px;
            height:14px;
            background: #156CFE;
            border-radius:50%;
            position:relative;
        }
        
        .inset-milestone-mobile{
            width:28px;
            height:28px;
            border-radius:50%;
            background: #C7D2FE;
            position:absolute;
            bottom:50%;
            display:flex;
            justify-content:center;
            align-items:center;
             left:-40px;
         }

          .inset-mobile{
            display:flex;
            justify-content:flex-end;
            align-items:center;
        }

        .inset-mobile::after {
            content: '';
            height: 100%;
            width: 7px;
            background: #156CFE19;
            position: absolute;
            top: 0;
            left:-30px;
        }

        .circle-mobile{
          width:14px;
          height:14px;
          background: #156CFE;
          border-radius:50%;
          position:relative;
        }
    `;

  const DesktopView = () => {
    return (
      <div className="flex justify-items-stretch flex-wrap pt-0 lg:pt-12">
        {features.map((feature, i) => (
          <div
            className={`flex flex-row basis-full flex-wrap ${
              i % 2 !== 0 ? "flex-row-reverse" : ""
            } `} // Initial state with opacity-0
            key={`feature-${i}`}
            // @ts-ignore
            ref={(el) => (featureRefs.current[i] = el)}
          >
            <div
              className={`box relative mt-2 lg:my-auto  max-sm:pb-12 w-1/2 max-sm:w-full max-sm:mx-auto ${
                i % 2 === 0 ? "inset-left" : "inset-right"
              }`}
              ref={itemRef}
            >
              <div
                className={`${
                  i % 2 === 0 ? "inset-milestone-left" : "inset-milestone-right"
                }`}
              >
                <div className="circle">
                  <div
                    style={{
                      height: "1px",
                      top: "50%",
                      background: "#156CFE",
                      position: "absolute",
                      width: lineWidth,
                      left: i % 2 !== 0 ? 0 : -lineWidth + 10,
                    }}
                  />
                </div>
              </div>

              <div className="max-w-[400px] min-w-[400px] shadow-[0_5px_14px_0_#080F340A] rounded-[20px] p-10">
                <p className="text-[#170F49] text-left font-semibold text-md max-lg:mt-4 max-lg:text-sm lg:mb-0 mb-3 ob">
                  <T keyName={feature.subtitle} />
                </p>
                <h1 className="font-bold text-[#191919] text-[28px] text-left mb-4 max-lg:text-[28px]">
                  <T keyName={feature.title} />
                </h1>
                <div className="flex" key={`bulletPoint-${i}`}>
                  <p
                    className="text-[#6F6C90] text-sm text-left"
                    key={`bulletPoint-${i}`}
                  >
                    <T keyName={feature.desc} />
                  </p>
                </div>
              </div>
            </div>
            <div className="flex relative items-center">
              <div
                className={`absolute bottom-[51.5%] w-[100px] ${
                  i % 2 === 0 ? "left-[10px]" : "-left-[100px]"
                }`}
              >
                <p className="text-sm font-semibold uppercase">
                  <T keyName={feature.milestonePeriod || ""} />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const MobileView = () => {
    return (
      <div className="flex justify-items-stretch flex-wrap pt-0 md:pt-12">
        {features.map((feature, i) => (
          <div
            className="flex flex-row basis-full flex-wrap ml-8"
            key={`feature-${i}`}
            // @ts-ignore
            ref={(el) => (featureRefs.current[i] = el)}
          >
            <div
              className={`box relative mt-2 md:my-auto w-full max-lg:grow inset-mobile`}
              ref={itemRef}
            >
              <div className="inset-milestone-mobile">
                <div className="circle-mobile" />
              </div>

              <div className="relative lg:max-w-full w-full lg:min-w-[400px] shadow-[0_5px_14px_0_#080F340A] rounded-[20px] p-5 lg:p-10">
                <p className="text-[#170F49] text-left font-semibold text-md max-md:mt-4 max-md:text-sm md:mb-0 mb-3 ob">
                  <T keyName={feature.subtitle} />
                  <span className="text-sm font-semibold uppercase ml-2">
                    (<T keyName={feature.milestonePeriod || ""} />)
                  </span>
                </p>

                <h1 className="font-bold text-[#191919] text-[28px] text-left mb-4 max-md:text-[28px]">
                  <T keyName={feature.title} />
                </h1>
                <div className="flex" key={`bulletPoint-${i}`}>
                  <p
                    className="text-[#6F6C90] text-sm text-left"
                    key={`bulletPoint-${i}`}
                  >
                    <T keyName={feature.desc} />
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className="max-w-screen-xl box m-auto md:mb-24 mb-14 max-xl:p-5"
      id="guideline"
    >
      <style>{style}</style>
      <h1 className="text-[24px] font-bold text-[#344054] mb-3">
        <T keyName="roadmap_title" />
      </h1>
      <div className="relative flex justify-center items-center bg-[#FAFAFA] py-8 rounded-lg border border-[#EFF0F6] mb-8">
        <div className="absolute -top-7 md:-top-4 left-4">
          <QuotesIcon />
        </div>
        <p className="text-[#667085] text-regular lg:w-[70%]">
          <T keyName="roadmap_subtitle" />
        </p>
      </div>
      {window.innerWidth <= 1048 ? <MobileView /> : <DesktopView />}
    </div>
  );
}

export default FeaturesIllustrated;

interface featureObject {
  title: string;
  subtitle: string;
  desc: string;
  image: string;
  milestonePeriod?: string;
}
