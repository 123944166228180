import React from "react";
import { T } from "@tolgee/react";
import Button from "../../components/Button";
import ReactGA from "react-ga";

export const SupportedIntegrationsSection: React.FC = () => {
  const goToIntegrations = () => {
    ReactGA.event({
      category: "home supported integrations",
      action: `go-to-supported-integrations`,
      label: "Home - Supported Integrations",
      nonInteraction: true,
    });
    window.open("https://www.3folio.finance/integrations", "_blank");
  };

  return (
    <div className="flex justify-start lg:justify-center items-center m-auto p-5 text-left mt-8 mb-[100px]">
      <img
        className="hidden lg:block"
        src="/DEMO/images/page-integrations.png"
      />
      <div className="flex flex-col justify-center max-w-full md:max-w-[600px] w-full ml-0 lg:ml-[120px] px-4">
        <h3 className="font-bold text-[24px] text-[#344054] mb-4">
          <T keyName="supported_integrations_heading" />
          <span className="text-[#275EF7]">
            <T keyName="supported_integrations_heading2" />
          </span>
        </h3>
        <p className="text-[#667085] text-md">
          <T keyName="supported_integrations_text" />
        </p>

        <Button
          text={<T keyName="supported_integrations_button" />}
          color="primary"
          size="large"
          className="mt-[40px] py-3 w-fit"
          onClick={goToIntegrations}
        />
      </div>
    </div>
  );
};
