import React from "react";
import {ToggleWithText} from "../../components/ToggleWithText";
import {useNavigate} from "react-router";

export const TermsAndConditionsContent = () => {

    const navigate = useNavigate()

    return (
        <div className={'justify-start w-full min-w-full p-4 md:p-0'}>
            <div className={'flex md:justify-end justify-center md:mb-0 mb-[30px]'}>
                        <ToggleWithText
                            onChange={() => navigate('/legal/terms-and-conditions/user')}
                            firstOption="Individual"
                            secondOption="Company"
                            checked={false}
                            id="terms-individual"
                            name="terms-individual"
                        />
                </div>


            <h1 className={'text-xl font-bold mb-3'}>General Terms and Conditions – B2B
                ("GTC")
            </h1>

            <h3 className={'text-base font-semibold mb-3'}>1. General</h3>
            <p className={'text-sm text-justify pb-5'}>1.1 3Folio GmbH, FN 572991i, Ernst Melchior Gasse 24/DG, 1020 Vienna, Austria, ("<b>3Folio</b>") offers crypto monitoring, accounting and reporting services via its proprietary platform accessible under the domain 3folio.finance (the "<b>Platform</b>").
            </p>
            <p className={'text-sm text-justify pb-5'}>1.2 The Platform consists of a web interface that facilitates the compilation, visualization and analysis of investment data, and 3Folio offers subscriptions to access and use the Platform.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.3 The use of the Platform by any business customer (<i>Unternehmer</i>) within the meaning of the Austrian Commercial Code (<i>Unternehmensgesetzbuch</i>) (the "<b>Client</b>") shall be governed exclusively by the terms and conditions set out in these GTC. Any terms and conditions that deviate from or supplement these GTC or quotations or framework agreements or supplementary agreements concluded with the Client shall not form part of the agreement between 3Folio and the Client without the express written consent of 3Folio.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.4 3Folio and the Client are hereinafter collectively referred to as "<b>Parties</b>", and each individually as "<b>Party</b>".</p>
            <p className={'text-sm text-justify pb-5'}>1.5 The Client acknowledges that the Platform uses third party data, including data of its clients, and that the 3Folio shall not be liable in any way for such client data, as further outlined in these GTC.
            </p>
            <p className={'text-sm text-justify pb-5'}>1.6 3Folio may amend these GTC from time to time and will give the Client prior written notice of any changes to these GTC. If the Client does not object to such changes within six weeks of receipt of the notice, the changes shall be deemed to have been accepted and shall become part of these GTC. In the event of such a change to these GTC, 3Folio will expressly inform the Client in the notice of the right to object and of the legal consequences of remaining silent.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>2. Services</h3>
            <p className={'text-sm text-justify pb-5'}>2.1 The functionalities accessible via the Platform depend on the type of subscription chosen by the Client and are described in detail at <a href={'https://www.3folio.finance/pricing'} className={'text-accent'} target={'_blank'}> www.3folio.finance/pricing</a> (the "<b>Services</b>").
            </p>
            <p className={'text-sm text-justify pb-5'}>2.2 The Client acknowledges that the Platform is still under development. The Parties therefore agree that 3Folio may change/modify the Services during such development. 3Folio shall be free to make any such changes and modifications, and in particular improvements and enhancements, including changes and modifications to the user interface of the Platform, as it deems fit, provided that the key functionalities of the Services (as set out at <a className={'text-accent'} href={'https://www.3folio.finance/pricing'} target={'_blank'}>www.3folio.finance/pricing</a>) remain materially in place.
            </p>
            <p className={'text-sm text-justify pb-5'}>2.3 3Folio may further add additional services to the Platform. 3Folio may offer such additional services to the Client at the sole discretion and at terms to be agreed between 3Folio and the Client.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>3. Subscriptions and User Accounts</h3>
            <p className={'text-sm text-justify pb-5'}>3.1 3Folio grants to the Client a subscription (the "<b>Subscription</b>") which entitles the Client to access and use the Platform with the number of user accounts (the "<b>User Accounts</b>") and Services corresponding to the type of subscription chosen by the Client.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.2 The Client may upgrade the subscription type via the Platform.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.3 The use of and access to the Client's User Accounts is exclusively carried out by authorized representatives, professional advisors and/or qualified employees of the Client ("<b>Users</b>"). The Client shall ensure that all Users are sufficiently skilled, have received proper training and were explicitly instructed on the content of the terms of use of the Platform (the "<b>Terms of Use</b>"), as published on the website of 3Folio from time to time.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.4 The Client shall provide true and complete information when setting up the User Accounts and shall keep the information up to date at all times.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.5 The Client shall dedicate at least one User Account, which shall be granted full access to all setting options and administration areas (such User Account, the "<b>Administrator Account</b>") of the Platform. Administrator Accounts may restrict access to specific Services, setting options or administration areas of other User Accounts (non-Administrator Accounts). For the avoidance of doubt, it is therefore in principle the sole responsibility of the Client to ensure that the User Accounts assigned to the Client are used accordingly.
            </p>
            <p className={'text-sm text-justify pb-5'}>3.6 3Folio is entitled to temporarily or permanently suspend User Accounts, including for avoidance of doubt Administrator Accounts, and/or Subscriptions if there are specific indications that (i) the Client has breached the provisions of these GTC or (ii) a User Account has breached the Terms of Use. 3Folio is further entitled to temporarily or permanently suspend User Accounts, including for avoidance of doubt Administrator Accounts, and/or Subscriptions for other good cause (<i>aus anderen, wichtigen Gründen</i>), as determined by 3Folio in its reasonable assessment.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>4. License </h3>
            <p className={'text-sm text-justify pb-5'}>For the term of the Subscription and subject to these GTC, 3Folio grants the Client a revocable, limited, non-exclusive, non-transferable, non-sublicensable license to access and use the Platform and the Services as set out at <a target={'_blank'} href={'https://www.3folio.finance/pricing'} className={'text-accent'}>www.3folio.finance/pricing</a> OR in in the price plans section at the Platform. Other than such license right, no right, title or interest to any material contained on the Platform will be transferred or assigned to the Client.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>5. Workspaces </h3>
            <p className={'text-sm text-justify pb-5'}>5.1 Financial data provided on or through the Platform will be organized and displayed in workspaces (the "<b>Workspace(s)</b>").
            </p>
            <p className={'text-sm text-justify pb-5'}>5.2 Each Workspace is managed by at least one administrator (the "<b>Workspace Admin(s)</b>"). By default, the User Account which sets up a Workspace will be designated as its Workspace Admin. Workspace Admins manage access of User Accounts to the respective Workspace and may selectively restrict or grant permissions within the Workspace's settings and administrative areas.
            </p>
            <p className={'text-sm text-justify pb-5'}>5.3 Each User Account can be a member of multiple Workspaces. To join a Workspace, other User Accounts require an invitation from the responsible Workspace Admin.
            </p>
            <p className={'text-sm text-justify pb-5'}>5.4 Each Workspace functions as a self-contained unit. Financial data will not be automatically transferred or shared between Workspaces.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>6. Financial Data Provided on the Platform </h3>
            <p className={'text-sm text-justify pb-5'}>6.1 Financial data provided on or through the Platform is either provided by 3Folio or by the Client as follows:
            </p>

            <p className={'text-sm text-justify pb-5 pl-4'}>6.1.1 Financial data provided by 3Folio will be visually highlighted or otherwise recognizable on the Platform's interface to ensure differentiation from financial data provided by the Client.
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>6.1.2 All other data, such as manual inputs by Client's User Accounts – incl manual overrides of financial data originally provided by 3Folio – and all data provided by third parties on behalf of the Client (eg wallet information or transaction data provided by Client's exchange or broker) shall be deemed financial data provided by the Client.
            </p>

            <p className={'text-sm text-justify pb-5'}>6.2 The Client acknowledges that:
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>6.2.1 although 3Folio takes all reasonable efforts to ensure the accuracy of financial data it provides on the Platform, 3Folio cannot warrant correctness, validity or completeness of any data, and the marking "Confirmed" shall not be construed as confirmation of the correctness, validity or completeness of any data by 3Folio;
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>6.2.2 markets and prices change continuously, so the data provided by 3Folio may not be up-to-date and should not be relied upon;
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>6.2.3 any data could be inaccurate and/or corrupted due to uncontrollable circumstances, such as technical instabilities, in particular unstable Internet connections, third-party services unavailability or interferences by third-parties;
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>6.2.4 3Folio has no influence whatsoever on the accuracy of the financial data provided by the Client; and
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>6.2.5 before making any type of decision-making, the Client and its employees and agents must always take reasonable steps to verify the accuracy and completeness of the financial data provided on or through the Platform.
            </p>

            <h3 className={'text-base font-semibold mb-3 pl-4'}>3Folio hence disclaims any liability in such respect.</h3>

            <h3 className={'text-base font-semibold mb-3'}>7. No Professional Advice
            </h3>
            <p className={'text-sm text-justify pb-5'}>7.1 The Client acknowledges that:
            </p>

            <p className={'text-sm text-justify pb-5 pl-4'}>7.1.1 the services of 3Folio to be rendered to the Client in the course of this business relationship (including the Services) do explicitly not include or constitute investment, legal or financial advice of whatsoever nature or any other (regulated or non-regulated) services that are explicitly the responsibility of a financial advisor, tax advisor, legal advisor, investment banker or accountant, and such services shall also not be construed accordingly; and
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>7.1.2 furthermore, nothing on the Platform should be construed as a recommendation by 3Folio to buy, sell, or hold any particular asset or asset class.
            </p>
            <h3 className={'text-base font-semibold mb-3 pl-4'}>3Folio hence disclaims any liability in such respect.
            </h3>

            <h3 className={'text-base font-semibold mb-3'}>8. Pricing and Payment
            </h3>
            <p className={'text-sm text-justify pb-5'}>8.1 3Folio offers different subscription types to provide the most suitable solutions. The detailed scope and price of each subscription type are specified at <a href={'https://www.3folio.finance/pricing'} target={'_blank'} className={'text-accent'} >www.3folio.finance/pricing</a>. Such prices are adjusted automatically every 1 January in line with the change of the Austrian Consumer Price Index.
            </p>
            <p className={'text-sm text-justify pb-5'}>8.2 The Client shall make monthly payments within 14 days after receipt of the invoice from 3Folio. The Client is not entitled to make any deductions from the invoiced amount or to set-off any claims with amounts owed to 3Folio. Payments are only deemed to have been made once 3Folio's bank account has been credited with the full invoiced amount.
            </p>
            <p className={'text-sm text-justify pb-5'}>8.3 In the event of arrears in payment, 3Folio is entitled to charge interest on arrears at the rate of 9.2 % per annum above the current base interest rate announced by the Austrian National Bank. The Client shall reimburse 3Folio for all expenses, dunning and collection charges, incl the legal fees and costs, incurred as a result of the Client's arrears in payment. If the Client is in arrears with payment for more than two months, 3Folio may suspend the Client's Subscription after setting a grace period of another 14 days.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>9. Term and Termination for Convenience
            </h3>
            <p className={'text-sm text-justify pb-5'}>9.1 Unless specified otherwise at <a href={'https://www.3folio.finance/pricing'} target={'_blank'} className={'text-accent'}>www.3folio.finance/pricing</a> or unless the Parties agree otherwise in writing, the term of the agreement between the Client and 3Folio concluded on the basis of these GTC (the "Agreement" and such term, the "Term") shall be indefinite until terminated for convenience by either Party by giving written notice at least one month prior to the end of any calendar month.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>10. Termination for Good Cause
            </h3>
            <p className={'text-sm text-justify pb-5'}>10.1 3Folio may terminate the Agreement at any time without notice and without being liable for any loss or damages resulting from such termination for any of the following reasons:
            </p>

            <p className={'text-sm text-justify pb-5 pl-4'}>10.1.1 if the discontinuation of the Platform or the termination of the Agreement is directly or indirectly required by law (eg by statute, regulation or a decision of a competent court or regulatory authority); or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.1.2 there is a direct or indirect change in the control of the Client (change of control), whereas "control" means the possession of the power to direct or cause the direction of the management and policies of a person (or substantially all the management and policies of such), whether through '
                the majority of the voting rights, through the right to appoint the majority of the members of the statutory of the legal person, by contract or otherwise; "control" is also assumed if the shareholdings or voting rights in another person exceeds 50% (fifty percent); or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.1.3 if the Platform is used by the Client or any of its Users in material breach of the Platform's Terms of Use or in any unlawful manner whatsoever; or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.1.4 if the Client is in arrears with more than 2 monthly instalments; or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.1.5 if it becomes (in the reasonable opinion of 3Folio) illegal to further provide services to the Client; or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.1.6 if (in the reasonable opinion of 3Folio) it becomes commercially intolerable to 3Folio to continue to provide services to the Client for reasons that are attributable to the Client, including loss of reputation for 3Folio.
            </p>

            <p className={'text-sm text-justify pb-5'}>10.2 Either Party may terminate the Agreement with immediate effect after written notification to the other Party and without being liable for any loss or damages resulting from such termination, if the other Party:
            </p>

            <p className={'text-sm text-justify pb-5 pl-4'}>10.2.1 is in default with the fulfillment of its essential obligations under these GTC and has not remedied such non-fulfillment within fourteen (14) days of written notification; or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.2.2 ceases to do business; or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.2.3 becomes or is declared insolvent or bankrupt; or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.2.4 is the subject of any proceeding related to its liquidation or insolvency (whether voluntary or involuntary); or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.2.5 makes an assignment for the benefit of creditors; or
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>10.2.6 due to other circumstances, is no longer entitled to freely manage its assets.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>11. Maintenance
            </h3>

            <p className={'text-sm text-justify pb-5'}>11.1 3Folio has the right to carry out maintenance work on weekdays in the period from 20:00 to 06:00 UTC and at weekends from 00:00 to 24:00 UTC (hereinafter: "Maintenance Window") for a total of 10 hours per calendar month. Activities in the Maintenance Window shall be announced to the Client in advance with a reasonable period of notice.
            </p>
            <p className={'text-sm text-justify pb-5'}>11.2 3Folio is entitled to postpone or extend the Maintenance Window in exceptional cases to eliminate or prevent serious faults.
            </p>
            <p className={'text-sm text-justify pb-5'}>11.3 During the Maintenance Window, the Platform may not be available or may have limited availability, which does not constitute a defect of the Platform.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>12. Limited Warranty and Liability
            </h3>
            <p className={'text-sm text-justify pb-5'}>12.1 Unless specifically otherwise provided for in these GTC, 3Folio shall to the greatest extent legally admissible not be liable for any representations or warranties, express or implied, or for the absence or the existence of any other circumstances, events or matters of fact relating to the Services and the Platform. Without limitation to the generality of the foregoing, the following provisions are additionally and expressly agreed between the Parties.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.2 3Folio strives for a high level of reliability and availability of its Services. However, 3Folio neither owes continuous availability or error-free operation of the Platform to the Client.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.3 In case of any defect of the Platform, the Client shall have no implied nor statutory warranty claims if 3Folio remedies the defect within the following timeframes:
            </p>

            <p className={'text-sm text-justify pb-5 pl-4'}>12.3.1 1 business day in case of a total operational shut-down of the Platform;
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>12.3.2 3 business days in case of substantiated defects that are a serious inconvenience to the Client (eg down-time of the Platform more than 6 hours); and
            </p>
            <p className={'text-sm text-justify pb-5 pl-4'}>12.3.3 7 business days in case of all other defects.
            </p>

            <p className={'text-sm text-justify pb-5'}>12.4 The Client shall inform 3Folio in writing about any defects of the Platform immediately upon detection thereof.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.5 3Folio does not warrant that the data transfer via external systems, in particular the Internet or telecommunications networks, will not be tracked, recorded or falsified by third parties.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.6 The use by the Client and its Users of the Platform and the Services offered by 3Folio is exclusively at the Client's or its Users' own risk. This applies without restriction to the downloading and exploitation in any way of all financial data by the Client and its Users.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.7 3Folio is liable within the scope of the existing mandatory provisions only for damages that 3Folio has caused through severe gross negligence (<i>krass grobe Fahrlässigkeit</i>) or willful misconduct (<i>Vorsatz</i>). 3Folio shall in no event be liable for indirect damage, loss of profit, loss of interest, failure to make savings, consequential and pecuniary damage and damage arising from third party claims.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.8 Any claims of the Client against 3Folio shall generally and without prejudice to any other limitations, disclaimers and exclusions of liabilities be limited to the lower of (i) the value of the fees paid by the Client for one contract year and (ii) the amount of 3Folio's liability insurance maintained by it from time to time.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.9 Client's claims against 3Folio shall be time-barred after 12 months from the moment in time at which the Client could have become aware of the claim, had the Client acted diligently, whatever occurs earlier. The full burden of proof is on the Client.
            </p>
            <p className={'text-sm text-justify pb-5'}>12.10 3Folio shall not be liable, except as required by mandatory law, for any damages that may arise from the use of content on the Platform or other types of use of the Platform or Services or for any loss of data and damages caused by the Client's system.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>13. Indemnification
            </h3>

            <p className={'text-sm text-justify pb-5'}>The Client shall fully indemnify, defend and hold harmless 3Folio and its representatives from and against any costs, damages, and fees (including attorney and other professional fees) attributable to any claims or allegations that third parties may raise against 3Folio from or in connection with the Client's and/or its Users' use of the Platform or the Services.
            </p>

                <h3 className={'text-base font-semibold mb-3'}>14. Obligations of the Client
                </h3>

                <p className={'text-sm text-justify pb-5'}>14.1 The Client is obligated to ensure that:
                </p>

                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.1 only truthful, up-to-date and accurate data is provided when subscribing, registering Users and using the Platform;
                </p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.2 no third parties, but only trained and suitable Users authorized by the Client are granted access to the Platform;
                </p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.3 all credentials to the User Accounts are kept secret and securely stored;</p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.4 if data/information generated via the Platform or the Services includes data provided by the Client or on its behalf, the Client reviews such data in advance for accuracy and authenticity before the Client uses or passes on the generated data/information (eg to tax advisors);
                </p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.5 neither the Client nor its Users rent, lease, lend, exchange or resell the Platform or otherwise grant access to it to third parties;
                </p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.6 no disruptive interventions with technical or electronic aids on the Platform are made by the Client and/or any of its Users, in particular hacking attempts, brute force attacks, introduction of viruses/worms/Trojans and other disruption attempts affecting the Platform;
                </p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.7 it and its Users do not copy, distribute and transmit accessible data without the express consent of the respective rights holder or to read these with technical aids, such as crawlers or bots;
                </p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.8 it supports 3Folio in maintenance and troubleshooting, in each case to a reasonable extent;
                </p>
                <p className={'text-sm text-justify pb-5 pl-4'}>14.1.9 it reports discovered violations of the above obligations immediately the 3Folio in writing.
                </p>

                <h3 className={'text-base font-semibold mb-3'}>15. Data Protection
                </h3>

                <p className={'text-sm text-justify pb-5'}>15.1 3Folio processes personal data in accordance with 3Folio's privacy policy, which is available at <a href={'https://www.3folio.finance/privacy'} target={'_blank'} className={'text-accent'}>www.3folio.finance/privacy</a>.
                </p>
                <p className={'text-sm text-justify pb-5'}>15.2 Insofar as 3Folio processes personal data on behalf of the Client within the framework of the Agreement, the data processing agreement set out [at <a href={'https://www.3folio.finance/privacy/data'} target={'_blank'} className={'text-accent'}>www.3folio.finance/privacy/data</a> (the "<b>Data Processing Agreement</b>") applies to such processing of personal data. The Data Processing Agreement is an integral part of the Agreement.
                </p>

                <h3 className={'text-base font-semibold mb-3'}>16. Final Provisions
                </h3>

                <p className={'text-sm text-justify pb-5'}>16.1 These GTC, its annexes and any other attachment expressly referred to in these GTC or the Agreement (as updated from time to time), constitute the entire understanding between the Parties with respect to the subject matter and supersedes all prior written or oral agreements, including correspondence in this respect. Any annex to these GTC is an integral part of the Agreement.
                </p>
                <p className={'text-sm text-justify pb-5'}>16.2 Neither Party will be liable for any failure or delay in performing any obligation under the Agreement that is due to causes beyond its reasonable control, such as natural catastrophes, acts of war, (digitized) terrorism, governmental acts or omissions, labor strikes or disputes (other than with its own workforce or the workforce of an affiliate), or transportation unavailability, stoppages or slowdowns, provided that the affected Party (i) gives prompt written notice to the other of the nature of the event and its estimated duration and resumes performance as soon as possible after the event ends; (ii) uses all reasonable efforts to continue to perform its obligations hereunder for the duration of such force majeure event; and (iii) gives written notice to the other Party of the force majeure event as soon as it becomes reasonably evident that such force majeure event will continue for a period of more than five (5) business days. However, if any such event prevents a Party from performing its obligations hereunder for more than thirty (30) days, the other Party may immediately terminate the Agreement on written notice. This provision shall not relieve the Client of any of its obligations in the event of a security incident.
                </p>
                <p className={'text-sm text-justify pb-5'}>16.3 Any reference to writing or written includes any electronic messaging, including emails. The Client shall send all communications based on the Agreement to 3Folio per email to: <a href={'mailto:legal@3folio.finance'} className={'text-accent'} target={'_blank'}>legal@3folio.finance</a>. 3Folio shall be entitled to send all communications based on the Agreement to the email address provided by the Client during registration or, if applicable, as updated via the Platform.
                </p>
                <p className={'text-sm text-justify pb-5'}>16.4 Should any provision of these GTC be or become invalid or should these GTC be incomplete, this shall not affect the validity of the remaining provisions of these GTC. The invalid provision shall be replaced by an enforceable provision which comes as close as possible to the economic purpose of the invalid provision.
                </p>
                <p className={'text-sm text-justify pb-5'}>16.5 Section 9 of the Austrian E-Commerce Act (<i>E-Commerce-Gesetz</i>) shall not apply to the Agreement.
                </p>
                <p className={'text-sm text-justify pb-5'}>16.6 The Agreement and all rights and obligations of the Parties arising out of or in connection with the Agreement shall be governed by and construed in accordance with Austrian law excluding the conflict of law rules and excluding the United Nations Convention on Contracts for the International Sale of Goods (CISG).
                </p>
                <p className={'text-sm text-justify pb-5'}>16.7 For Clients that are resident or that have their corporate seat in the European Union, the following applies: The competent court in 1010 Vienna, Austria, having jurisdiction over the subject-matter shall have exclusive jurisdiction to settle any disputes arising out of or in connection with the Agreement or related to its creation, validity, nullity, interpretation, performance and termination as well as its pre- and post-contractual effects. For all other Clients, the following applies: All disputes or claims arising out of or in connection with the Agreement, including disputes relating to its validity, breach, termination or nullity, shall be finally settled under the Rules of Arbitration (Vienna Rules) of the Vienna International Arbitral Centre (VIAC) of the Austrian Federal Economic Chamber by one arbitrator appointed in accordance with the said rules. The seat of the arbitration shall be Vienna, Austria. The language of the arbitration shall be English. The governing law of this arbitration agreement shall be Austrian law.
                </p>
            <p className={'text-sm text-justify pb-5'}>16.8 These GTC can be accessed, saved, and printed out at any time under [<a className={'text-accent'} href={`${process.env.PUBLIC_URL}/pdf/GTC_B2B_3FOLIO_V1.pdf`} download>Download</a>].</p>
            <p className={'text-sm text-justify pb-5'}>16.9 The contractual language shall be English.
            </p>


                <p className={'text-sm text-justify pb-5 pt-6'}>January, 2024</p>
        </div>
    )
}