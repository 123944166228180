import React from "react";

export const MonitorMethodsIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.8"
        x="14.1743"
        y="12.3506"
        width="6.26879"
        height="12.6389"
        rx="3.13439"
        fill="#275EF7"
      />
      <rect
        opacity="0.5"
        x="6"
        y="15"
        width="6.26879"
        height="9.76641"
        rx="3.13439"
        fill="#275EF7"
      />
      <rect width="35" height="35" rx="8" fill="#F0F5FF" />
      <path
        d="M22.8778 25.0902C21.1693 26.3315 19.1117 27 16.9999 27C14.8881 27 12.8305 26.3315 11.1221 25.0902M21.3836 8.01206C23.2817 8.93781 24.838 10.4407 25.8294 12.3053C26.8208 14.1699 27.1965 16.3005 26.9026 18.3917M7.09733 18.3916C6.80343 16.3004 7.17911 14.1698 8.17053 12.3052C9.16196 10.4406 10.7182 8.93771 12.6163 8.01196M22.4999 17C22.4999 20.0376 20.0375 22.5 16.9999 22.5C13.9623 22.5 11.4999 20.0376 11.4999 17C11.4999 13.9624 13.9623 11.5 16.9999 11.5C20.0375 11.5 22.4999 13.9624 22.4999 17Z"
        stroke="#317BFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
