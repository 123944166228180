import React from "react";

export const CookiePolicyContent = () => {

    return (
        <div className={'justify-start w-full min-w-full p-4 md:p-0'}>
            <h1 className={'text-xl font-bold mb-3'}>Cookie Policy</h1>

            <h3 className={'text-base font-semibold mb-3'}>Datenschutz & Sicherheit bei 3Folio</h3>
            <p className={'text-sm text-justify pb-5'}>In dieser Cookie-Richtlinie möchten wir Sie über die Verwendung von Cookies oder ähnlichen Speichertechnologien (nachfolgend gemeinsam als "Cookies" bezeichnet) auf unserer Website 3folio.finance informieren. Insbesondere wird Ihnen auch gezeigt, wie Sie einstellen können, welche Cookies Sie zulassen möchten, und wie Sie Ihre Privatsphäre selbst schützen können.
            </p>

            <p className={'text-sm text-justify pb-5'}>1. <b>Was sind Cookies?</b> Cookies sind kleine Textdateien, die auf Ihrem Computer, Tablet, Smartphone oder einem anderen Endgerät sowie in Ihrem Browser gespeichert werden. Beim nächsten Besuch unserer Website mit dem gleichen Endgerät wird die in Cookies gespeicherte Information entweder an unsere Website ("First Party Cookie") oder an eine andere Website, zu der das Cookie gehört ("Third Party Cookie"), zurückgesendet. 3Folio verwendet Cookies, um einen benutzerfreundlicheren, effektiveren und sichereren Service bereitzustellen. Cookies ermöglichen es auch 3Folio, den verwendeten Browser zu identifizieren und bestimmte Angebote für den Benutzer zu machen. Es wird auch zwischen Session-Cookies unterschieden, temporäre Cookies, die während des Browsens aktiv sind und nach dem Schließen des Browsers gelöscht werden, und Persistent Cookies, die im Browser gespeichert sind, bis Sie sie selbst löschen oder bis Ihr Browser sie aufgrund vordefinierter Einstellungen löscht. Die meisten Cookies haben auch eine eindeutige Kennung, eine sogenannte "Cookie-ID". Dadurch können Websites dem spezifischen Internetbrowser zugeordnet werden, in dem das Cookie gespeichert wurde. Die Identifizierung einer bestimmten Person ist normalerweise nur mit bestimmten Tools von Dritten möglich.Sie haben jederzeit die Möglichkeit, die Aktivierung bestimmter Cookies abzulehnen, indem Sie die entsprechende Option in den Browsereinstellungen oder in den Cookie-Einstellungen auswählen.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Zustimmung zur Verwendung von Cookies und zur Übertragung in Drittländer (z.B. USA)
            </h3>
            <p className={'text-sm text-justify pb-5'}>Cookies, die nicht unbedingt erforderlich sind, um Ihnen die Dienste auf unserer Website bereitzustellen (sogenannte "Notwendige Cookies"), werden nur mit Ihrer Zustimmung verwendet. In einigen Fällen werden solche "Optional Cookies" auch von Unternehmen betrieben, die Daten in Drittländern, insbesondere in den USA, verarbeiten (z.B. Google Ireland Ltd. über ihren Datenverarbeiter Google LLC). Durch die Zustimmung zur Verwendung von Cookies von solchen Anbietern stimmen Sie auch ausdrücklich der Übertragung Ihrer Daten in Drittländer, wie den USA (gemäß Art. 49 Abs. 1 lit. a DSGVO), zu. Ihre Zustimmung ermöglicht es uns auch, bestimmte Daten an unsere Partner zur Verarbeitung in Ländern außerhalb der EU, wie den USA (z.B. Google Analytics), zu übertragen. Für die USA liegt keine Angemessenheitsentscheidung der EU-Kommission vor, und trotz umfangreicher Maßnahmen kann das hohe Niveau des EU-Datenschutzes in den USA nicht garantiert werden. Es bestehen Risiken, dass übermittelte Daten möglicherweise nicht gelöscht oder für jeden Zweck weiterverarbeitet werden, dass US-Behörden übermäßigen Zugriff auf Ihre Daten haben oder dass Sie Ihre Rechte in den USA möglicherweise nicht wirksam durchsetzen können. Sie können jedoch jederzeit Ihre Zustimmung für die Zukunft widerrufen. Die Ablehnung einiger dieser Cookies kann sich jedoch auf Ihre Benutzererfahrung auswirken.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Welche Arten von Cookies gibt es?
            </h3>
            <p className={'text-sm text-justify'}>Um besser zu verstehen, warum Cookies so wichtig sind, geben wir Ihnen einen kurzen Überblick darüber, welche Arten von Cookies es gibt:
            </p>
            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify font-semibold'}>Notwendige Cookies (Necessary Cookies)
                </li>
            </ul>
            <p className={'text-sm text-justify pb-1'}>helfen bei der grundlegenden Navigation auf unserer Website und der Anzeige bestimmter Funktionen. Ohne diese Art von Cookies ist die Kernfunktionalität und Sicherheit unserer Website nicht gegeben. Daher ist für solche Notwendigen Cookies keine Zustimmung erforderlich (Art. 6 Abs. 1 lit. f DSGVO). Aus diesem Grund können Notwendige Cookies nicht individuell deaktiviert oder aktiviert werden. Sie haben jedoch die Möglichkeit, Cookies generell in Ihrem Browser jederzeit zu deaktivieren (siehe Abschnitt 5.). Notwendige Cookies werden von uns oder von Dritten bereitgestellt, deren Dienste von uns auf unserer Website implementiert sind. Notwendige Cookies werden beispielsweise verwendet, um sicherzustellen, dass Sie als registrierter Benutzer angemeldet bleiben, wenn Sie verschiedene Unterseiten unserer Website aufrufen, und daher Ihre Anmeldeinformationen nicht jedes Mal erneut eingeben müssen.
            </p>
            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify font-semibold'}>Werbungs-Cookies (Advertisement Cookies)
                </li>
            </ul>
            <p className={'text-sm text-justify pb-1'}>werden verwendet, um Website-Besuchern relevante Werbung und Marketingkampagnen bereitzustellen. Diese Cookies zeichnen die Seiten auf, die ein Benutzer auf verschiedenen Websites besucht, und sammeln Informationen, um maßgeschneiderte Werbung zu liefern. Sie helfen uns, die auf der Website gesammelten Informationen für die bestmögliche Benutzererfahrung und auch für zusätzliche Marketingzwecke zu nutzen. Die meisten Werbe-Cookies stammen von externen Werbeunternehmen (Third Party Cookies) und sind Persistent Cookies. Daher wird diese Art von Cookie auch als "Targeting Cookie" bezeichnet. Der zweite Typ von Cookies, die in diese Kategorie fallen, wird für Leistungszwecke verwendet, da sie die Website im Allgemeinen angenehmer zu nutzen machen und Marketingnachrichten verbessern. Es gibt auch Möglichkeiten, Inhalte mit einer Community zu teilen, wie z.B. Blog-Beiträge oder die Interaktion zwischen einer Website und einem Social-Media-Kanal. Diese Cookies werden als "Interaktions-Cookies" bezeichnet. Wenn wir personenbezogene Daten im Zusammenhang mit Werbe-Cookies verarbeiten, dürfen wir dies nur auf Grundlage Ihrer Zustimmung (Art. 6 Abs. 1 lit. a DSGVO) und nur solange tun, wie Sie das jeweilige Leistungs-Cookie nicht deaktiviert haben; Sie können dies jederzeit mit Wirkung für die Zukunft tun (siehe Punkt 5.).
            </p>

            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify font-semibold'}>Funktionale Cookies (Functional Cookies)
                </li>
            </ul>
            <p className={'text-sm text-justify pb-1'}>ermöglichen es unserer Website, Informationen, die Sie bereits bereitgestellt haben (z.B. Sprachauswahl), zu speichern und Ihnen auf Grundlage dieser Informationen verbesserte und personalisierte Funktionen anzubieten. Diese Cookies sammeln und speichern größtenteils anonyme Informationen, sodass sie Ihr Verhalten auf anderen Websites nicht nachverfolgen können. Wenn wir personenbezogene Daten verarbeiten sollten, können wir dies entweder aufgrund unseres berechtigten Interesses am Betrieb einer funktionalen Website (Art. 6 Abs. 1 lit. f DSGVO) oder aufgrund Ihrer Zustimmung (Art. 6 Abs. 1 lit. a DSGVO) tun, jedoch nur so lange, wie Sie das jeweilige Funktionale Cookie nicht deaktiviert haben; Sie können dies jederzeit mit Wirkung für die Zukunft tun (siehe Punkt 5.).
            </p>

            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify font-semibold'}>Leistungs-Cookies (Performance Cookies)</li>
            </ul>
            <p className={'text-sm text-justify pb-6'}>werden verwendet, um wichtige Website-Metriken zu verstehen und zu analysieren, um Besuchern eine bessere Benutzererfahrung zu bieten. Unter anderem erfassen wir die Anzahl der Zugriffe, die auf unserer Website verbrachte Zeit, die Anzahl der aufgerufenen Unterseiten, die Reihenfolge der aufgerufenen Seiten, welche Suchbegriffe Sie zu uns geführt haben, den Ort, von dem aus der Zugriff erfolgt, und den Anteil von Mobilgeräten, die auf unsere Websites zugreifen. Wir erfassen auch Bewegungen, Mausklicks und Scrollverhalten, um zu verstehen, welche Bereiche unserer Website für Sie besonders interessant sind. Wenn wir personenbezogene Daten im Rahmen dieser Cookies verarbeiten, dürfen wir dies nur auf Grundlage Ihrer Zustimmung (Art. 6 Abs. 1 lit. a DSGVO) und nur solange tun, wie Sie das jeweilige Leistungs-Cookie nicht deaktiviert haben; Sie können dies jederzeit mit Wirkung für die Zukunft tun (siehe Punkt 5.).
            </p>

            <h3 className={'text-base font-semibold mb-3'}>1. Welche anderen Dienste werden von 3Folio verwendet</h3>
            <p className={'text-sm text-justify pb-1'}>Wir kategorisieren zudem in die folgenden Bereiche:
            </p>

            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify pb-1'}><b>Einstellung</b>: Einige 3Folio-Funktionen erfordern Cookies für benutzerspezifische Einstellungen, wie z.B. die Anmeldung. Beispiel: __ar_v4
                </li>
                <li className={'text-sm text-justify pb-1'}><b>Sicherheit</b>: Wir verwenden Cookies, um die Sicherheit auf unserer Website auf einem hohen Niveau zu halten. Diese Cookies verhindern beispielsweise unbefugten Zugriff. Beispiel: __cfduid
                </li>
                <li className={'text-sm text-justify pb-1'}><b>Marketing</b>: Wir verwenden die folgenden Cookies, um unsere Anzeigen zu zielen und Ihnen optimale Anzeigen zu senden, die Sie wirklich im Zusammenhang mit 3Folio interessieren. Beispiel: __adroll_fpc, personalization_id, fr oder auch Google Analytics. Diese Cookies werden nur nach Ihrer ausdrücklichen Zustimmung aktiviert (Art. 6 Abs. 1 lit. a DSGVO).
                </li>
            </ul>

            <p className={'text-sm text-justify pb-6'}>Sie haben die Cookie-Richtlinie ohne Unterstützung von JavaScript geladen. Auf AMP können Sie die Schaltfläche zur Zustimmungsverwaltung am unteren Rand der Seite verwenden.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>Zugriffsdaten / Server-Logfiles
            </h3>
            <p className={'text-sm text-justify pb-5'}>3Folio (oder sein Webspace-Provider) sammelt Daten zu jedem Zugriff auf das Angebot (sogenannte "Server-Logfiles"). Die Zugriffsdaten umfassen: Name der aufgerufenen Website, Datei, Datum und Uhrzeit des Zugriffs, übertragene Datenmenge, Meldung über erfolgreichen Zugriff, Browsertyp und -version, das Betriebssystem des Benutzers, Referrer-URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider. 3Folio verwendet solche Protokolldaten nur für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung der Website. Der Anbieter behält sich jedoch das Recht vor, die Protokolldaten nachträglich zu überprüfen, wenn es einen begründeten Verdacht auf rechtswidrige Nutzung aufgrund konkreter Anhaltspunkte gibt.
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Platzierte Cookies (Placed Cookies)
            </h3>
            <p className={'text-sm text-justify pb-5'}>Diese Website verwendet Cookies, um Ihre Benutzererfahrung beim Durchsuchen unserer Website zu verbessern. Einige Cookies sind streng erforderlich für den Betrieb der Website, andere sind optional und werden beispielsweise an unsere Partner zu Marketingzwecken gesendet. Diese Partner können Ihre Informationen mit Daten kombinieren, die Sie ihnen zur Verfügung gestellt haben oder die sie im Rahmen anderer Dienste gesammelt haben.
            </p>
            <p className={'text-sm text-justify pb-5'}>Ihre Zustimmung ermöglicht es uns auch, bestimmte Daten an unsere Partner zur Verarbeitung in Ländern außerhalb der EU zu übertragen, insbesondere in die USA (z.B. Google Analytics). Das Tracking-Tool Google Analytics wird von Google LLC ("Google"), 1600 Amphitheatre Pkwy, Mountain View, CA 94043-1351, USA, betrieben und angeboten. Es liegt keine Angemessenheitsentscheidung der EU-Kommission für die USA vor, und trotz umfangreicher Maßnahmen kann das hohe EU-Datenschutzniveau in den USA nicht garantiert werden. Es bestehen Risiken, dass übermittelte Daten möglicherweise nicht gelöscht oder für jeden Zweck weiterverarbeitet werden, es möglicherweise übermäßigen Zugriff auf Ihre Daten durch US-Behörden gibt und Sie Ihre Rechte in den USA möglicherweise nicht wirksam durchsetzen können. Sie können jedoch jederzeit Ihre Zustimmung für die Zukunft widerrufen (siehe Punkt 5.). Die Ablehnung einiger dieser Cookies kann sich jedoch auf Ihre Benutzererfahrung auswirken.
            </p>


            <h3 className={'text-base font-semibold mb-3'}>Rechtsgrundlage für Cookies
            </h3>
            <p className={'text-sm text-justify pb-5'}>Bei Ihrem ersten Besuch auf unserer Website zeigen wir Ihnen ein Pop-up-Fenster mit einer kurzen Erklärung und Informationen zu den von uns verwendeten Cookies. Sie können entscheiden, welche Cookies wir verwenden dürfen, indem Sie die entsprechende Option im Banner auswählen. Um eine größere Transparenz zu gewährleisten, unterteilen wir die Cookies in die zuvor genannten Gruppen für die Auswahlmöglichkeit. Die Verwendung der von Ihnen ausgewählten Cookies basiert auf Art. 6 Abs. 1 lit a DSGVO, insbesondere auf Ihrer Zustimmung, wenn sie Ihr Verhalten verfolgen. Sie können diese Zustimmung jederzeit und ohne Angabe von Gründen widerrufen (siehe Punkt 5.).
            </p>
            <p className={'text-sm text-justify pb-5'}>Je nach den ausgewählten Cookies besteht die Möglichkeit, dass sie auch personenbezogene Daten an Drittländer übermitteln. Dies sind Länder mit einem niedrigeren Datenschutzniveau als die EU/EEA, wie beispielsweise die USA. Falls dies der Fall ist, werden wir Sie über dieses Risiko informieren, bevor wir Ihre ausdrückliche Zustimmung einholen. Die Übertragung personenbezogener Daten in ein Drittland erfolgt dann auf der Grundlage Ihrer ausdrücklichen Zustimmung gemäß Art. 49 Abs. 1 lit a DSGVO. Sie können diese Zustimmung ebenfalls jederzeit ohne Angabe von Gründen für die Zukunft widerrufen (siehe Punkt 5.).
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Wie Sie Cookies deaktivieren?
            </h3>
            <p className={'text-sm text-justify pb-1'}>Sie können in Ihrem Browser bestimmte Einstellungen vornehmen, um zu bestimmen, welche Cookies auf Ihrem Computer oder Mobilgerät gelöscht oder verhindert werden sollen. Dies könnte jedoch die Nutzung der Website einschränken. Unter "Hilfe" in Ihrem verwendeten Browser finden Sie Informationen dazu, wie Sie Ihre Cookie-Einstellungen verwalten können. Informationen zu den Einstellungen im jeweiligen Browser:
            </p>

            <ul className={'list-disc my-3 ml-6'}>
                <li className={'text-sm text-justify pb-1'}><b>Firefox</b>: <a className={'text-accent'} href='http://support.mozilla.com/de/kb/Cookies' target='_blank'>http://support.mozilla.com/de/kb/Cookies</a> </li>
                <li className={'text-sm text-justify pb-1'}><b>Chrome</b>: <a className={'text-accent'} href='http://www.google.com/support/chrome/bin/answer.py?hl=de&answer=95647' target='_blank'>http://www.google.com/support/chrome/bin/answer.py?hl=de&answer=95647</a> </li>
                <li className={'text-sm text-justify pb-1'}><b>Safari</b>: <a className={'text-accent'} href='http://support.apple.com/kb/PH5042' target='_blank'>http://support.apple.com/kb/PH5042</a> </li>
                <li className={'text-sm text-justify pb-1'}><b>Internet Explorer</b>: <a className={'text-accent'} href='https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies' target='_blank'>https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies</a> </li>
                <li className={'text-sm text-justify pb-1'}><b>Opera</b>: <a className={'text-accent'} href='http://www.opera.com/browser/tutorials/security/privacy/' target='_blank'>http://www.opera.com/browser/tutorials/security/privacy/</a> </li>
                <li className={'text-sm text-justify pb-1'}><b>Microsoft Edge</b>: <a className={'text-accent'} href='https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221101038' target='_blank'>https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies?tid=221101038</a> </li>
            </ul>

            <p className={'text-sm text-justify pb-6'}>Die Verwendung von Drittanbieter-Cookies kann unter der folgenden Adresse deaktiviert werden: <a className={'text-accent'} href='http://optout.aboutads.info/?lang=DE&c=2#!%2F' target='_blank'> http://optout.aboutads.info/?lang=DE&c=2#!%2F</a>
            </p>
            <p className={'text-sm  pb-6'}>Sie können auch ein Browser-Add-On herunterladen und installieren, wenn Sie die Nutzung von Google Analytics beschränken möchten. Hier der Link: <a className={'text-accent'} href='https://tools.google.com/dlpage/gaoptout?hl=de' target='_blank'> https://tools.google.com/dlpage/gaoptout?hl=de</a>
            </p>

            <h3 className={'text-base font-semibold mb-3'}>Änderungen und Kontakt
            </h3>
            <p className={'text-sm text-justify pb-6'}>Um allen rechtlichen Anforderungen zu entsprechen, wird diese Cookie-Richtlinie kontinuierlich angepasst. Die aktuellste Version finden Sie immer auf unserer Website. Daher empfehlen wir Ihnen, diese Cookie-Richtlinie jedes Mal zu lesen, wenn Sie sich entscheiden, ob Sie Cookies von uns akzeptieren möchten oder nicht. Wenn dies jedoch nach geltendem Recht erforderlich ist, werden wir selbstverständlich Ihre ausdrückliche Zustimmung zu wesentlichen Änderungen einholen.
            </p>
            <p className={'text-sm text-justify pb-6'}>Wenn Sie Fragen zum Inhalt dieser Cookie-Richtlinie oder zur Verarbeitung Ihrer personenbezogenen Daten haben, wenden Sie sich bitte an unseren Datenschutzbeauftragten (per E-Mail an: <a className={'text-accent'} href='mailto:legal@3folio.finance' target='_blank'>legal@3folio.finance</a>)
            </p>
            <p className={'text-sm text-justify pb-6'}>oder lesen Sie unsere Datenschutzrichtlinie. Um sicherzustellen, dass personenbezogene Daten nur mit der betroffenen Person geteilt werden, können wir zusätzliche Identifikationsdaten von Ihnen verlangen (z.B. Pass, Personalausweis usw.).
            </p>


        </div>
    )
}