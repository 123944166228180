import React from "react";
import { CheckIconCircle } from "../../components/icons/CheckIconCircle";

export const IndividualsStarterDescList = () => {
  return (
    <div className={"flex justify-start flex-col"}>
        <div className={"flex justify-start"}>
        <span
          className={"justify-start text-[12px] text-[#83899F] text-center"}
        >
          Ideal for investors who need continuous portfolio tracking and evaluation to ensure regulatory compliance.
        </span>
      </div>
    </div>
  );
};
