import React from "react";
import {Tooltip} from "react-tooltip";
import {HelpIcon} from "./icons/HelpIcon";

type Props = {
    color?: string,
    text?: string
    small?: boolean
    tooltip?: string
    keyA?: number | string
    border?: boolean
}
export const ListItem = ({ color, text, small = false, tooltip = '', keyA, border = false}: Props) => {

    return (
        <div key={`abc-${keyA}`} className={`flex items-center ${small && 'min-h-[44px]'}  text-[${small ? '14px' : '16px'}] font-medium text-[#454545] ${border ? 'border-[#EDEEF3] pt-3.5 pb-2 border-b-[0.5px]' : 'py-[5px]'}`}>
            <i className={`w-[7px] h-[7px] mr-1 rounded-full `} style={{ backgroundColor: color}}></i>
            <p className={`font-medium`} style={{ fontSize: small ? '14px' : '16px'}}>{text}</p>
            {tooltip &&
                <>
                    <Tooltip
                        place="right"
                        id={`tooltip-span-${keyA}`}
                        className="px-3 py-3 text-xs font-medium z-[999] text-white rounded-lg"
                    />
                    <span data-tooltip-content={tooltip} data-tooltip-id={`tooltip-span-${keyA}`} className={'cursor-pointer'}>
                        <HelpIcon />
                    </span>
                    {/*<svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"*/}
                    {/*     stroke="currentColor" className="w-4 h-4 ml-2">*/}
                    {/*    <path stroke-linecap="round" stroke-linejoin="round"*/}
                    {/*          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"/>*/}
                    {/*</svg>*/}
                </>
            }

         </div>
    )
}