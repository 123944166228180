import { ReactElement } from "react";
import { T } from "@tolgee/react";
import { AnalyticsIcon } from "../../components/icons/AnalyticsIcon";
import { AccountingIcon } from "../../components/icons/AccountingIcon";
import { ReportingIcon } from "../../components/icons/ReportingIcon";

function ProblemCards() {
  const cards: ProblemCard[] = [
    {
      title: "portfolio_insights",
      description: "portfolio_insights_desc",
      icon: <AnalyticsIcon />,
    },
    {
      title: "book_keeping",
      description: "book_keeping_desc",
      icon: <AccountingIcon />,
    },
    {
      title: "integrations",
      description: "integrations_desc",
      icon: <ReportingIcon />,
    },
    {
         title: 'action_logging',
         description: 'action_logging_desc',
         icon: <ReportingIcon />
     },
     {
         title: 'data_export_import',
         description: 'data_export_import_desc',
         icon: <ReportingIcon />
   },
   {
       title: 'traceable_asset_prices',
         description: 'traceable_asset_prices_desc',
         icon: <ReportingIcon />
     }
  ];
  return (
    <div
      className="max-w-screen-xl box m-auto mb-22 md:mb-10 lg:mb-36 max-xl:p-5"
      id="features"
    >
      <br />
      <h1 className="text-2xl lg:w-[75%] mx-auto w-full font-bold text-[#0A1B39] max-md:text-2xl">
        <T keyName={"problem_cards_title"} />
      </h1>

      <p className="text-normal lg:text-md text-slate-400 mt-4 mb-[30px]">
        <T keyName={"our_enterprise_copilot"} />
      </p>

      <div className="flex flex-wrap text-left">
        {cards.map((card, i) => (
          <div
            className="flex flex-col items-center w-full lg:w-4/12 mb-8"
            key={"problemCard-" + i}
          >
            <div
              className={
                "flex flex-col items-center h-full justify-start p-5 mx-4 rounded-[16px] shadow-cards border-[#EFF0F6] border-[1.5px]"
              }
            >
              <div className="w-full flex justify-start items-center gap-[12px]">
                <div className={"flex rounded-md bg-[#F0F5FF] items-center"}>
                  {card.icon}
                </div>
                <h2 className="font-semibold text-[#061C3D] text-[16px]">
                  <T keyName={card.title} />
                </h2>
              </div>

              <p className="text-[#6F6C90] text-[14px] pt-3 w-full">
                <T keyName={card.description} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProblemCards;

interface ProblemCard {
  title: string;
  description: string;
  icon: ReactElement;
}
