import React from "react";
import { CheckIconCircle } from "../../components/icons/CheckIconCircle";

export const EnterpriceSuiteList = () => {
  return (
    <div className={"flex justify-start flex-row justify-between"}>
      <div className={"flex flex-col"}>
      <div className={"flex justify-start"}>
        <span
          className={"justify-start text-[12px] text-[#83899F] text-center"}
        >
           Ideal for crypto funds with extended requirements in the areas of compliance, security, infrastructure and reporting.
        </span>
      </div>
      </div>
    </div>
  );
};
