import React from "react";

export const FeeCalculationIcon = () => {
  return (
    <div className="p-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <path
          d="M18.2292 6.77083L6.77083 18.2292M8.85417 10.9375V6.77083M6.77083 8.85417H10.9375M14.0625 16.1458H18.2292M8.125 21.875H16.875C18.6252 21.875 19.5002 21.875 20.1687 21.5344C20.7567 21.2348 21.2348 20.7567 21.5344 20.1687C21.875 19.5002 21.875 18.6252 21.875 16.875V8.125C21.875 6.37484 21.875 5.49975 21.5344 4.83128C21.2348 4.24327 20.7567 3.76521 20.1687 3.4656C19.5002 3.125 18.6252 3.125 16.875 3.125H8.125C6.37484 3.125 5.49975 3.125 4.83128 3.4656C4.24327 3.76521 3.76521 4.24327 3.4656 4.83128C3.125 5.49975 3.125 6.37484 3.125 8.125V16.875C3.125 18.6252 3.125 19.5002 3.4656 20.1687C3.76521 20.7567 4.24327 21.2348 4.83128 21.5344C5.49975 21.875 6.37484 21.875 8.125 21.875Z"
          stroke="#317BFF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
