import React, { MouseEventHandler, useEffect, useState } from "react";
import { motion } from "framer-motion";

import { EuroIcon } from "../../components/icons/EuroIcon";
import { LinkIcon } from "../../components/icons/LinkIcon";
import {InfoFillCircleIcon} from "../../components/icons/InfoFillCircleIcon";
import {CloseModalIcon} from "../../components/icons/CloseModalIcon";
import {InfoCircleIcon} from "../../components/icons/InfoCircleIcon";

type ModalProps = {
    isOpen: boolean;
    closeModal: MouseEventHandler<HTMLElement>;
    handleToSignUp?: (s: string ) => void
    pricePlan?: string
}

export function asNumber(num: number) {
    const formatter = new Intl.NumberFormat('de-DE', {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return formatter.format(num);
}



export const CalculateContentModal = ({ isOpen, closeModal, handleToSignUp, pricePlan }: ModalProps) => {
    const [navValue, setNavValue] = useState<string>('');
    const [valuePerMonth, setValuePerMonth] = useState(0)
    const [expectedAnnualFee, setExpectedAnnualFee] = useState(0)
    const [realValue, setRealValue] = useState(0)

    if (!isOpen) return null;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
        if (value) {
            const formattedValue = asNumber(parseInt(value));
            setRealValue(parseInt(value))
            let perMonth = parseInt(value) * (0.05 / 100) / 12
            setExpectedAnnualFee(parseInt(value) * (0.05 / 100))
            setNavValue(formattedValue);
            // @ts-ignore
            setValuePerMonth(parseFloat(perMonth).toFixed(2))
        } else {
            setNavValue('');
        }



    };

    return (
        <motion.div className="fixed inset-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-[99998]" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <motion.div className="bg-white p-8 rounded-md relative z-[99999]" initial={{ scale: 0.5 }} animate={{ scale: 1 }} exit={{ scale: 0.5 }}>
                <div className="bg-white py-8 justify-start text-start rounded-md relative max-w-md mx-auto">
                    <h2 className="text-[16px] font-bold mt-6">
                        Forecast your subscription fee
                    </h2>
                    <p className="font-medium mt-1 text-[12px] text-[#83899F] leading-[22px]">The subscription fee is 0.05% of the average annual net asset value
                        and is recalculated monthly.</p>
                    <div className="flex justify-start relative mt-3 w-1/2">
                        <span className="absolute left-2 top-2.5"><EuroIcon /></span>
                        <input autoComplete="off" type="text" placeholder={"Enter annual NAV value"} onChange={handleInputChange} className="w-full border border-[#D1D5DB] pl-[30px] rounded-[5px] p-[9px] text-[12px] text-[#485066] font-medium" name="calculation" id="calculation" value={navValue} />
                    </div>
                    {expectedAnnualFee < 2500 && navValue && <div className={'flex justify-start pt-1'}>
                        <InfoCircleIcon />
                        <span className={'pl-1 text-[#83899F] text-[11px] font-[500]'}>Subscription starts with a minimum NAV of EUR</span>
                        <span className={`pl-1 text-[#485066] text-[11px] font-semibold`}>5.000.000</span>
                    </div>}
                    <div className="flex justify-start relative mt-5 ">
                        <h2 className="text-[12px] font-bold text-[#485066]">
                            Subscription formula
                        </h2>
                    </div>
                    {!navValue ?
                    <div className="flex justify-center relative mt-3 rounded-[11px] border border-[#EDEEF3] h-[150px] items-center">
                        <p className="text-[12px] text-[#83899F]">Calculation result will be displayed here after entering NAV</p>
                    </div> :
                    <div className="flex justify-center flex-col relative mt-3 rounded-[11px] border border-[#EDEEF3] h-[150px] ">
                        <div className={'w-full flex items-center px-3 text-[11px] font-medium bg-[#EFF6FF] rounded-t-[11px] py-2 h-[35px]'}>
                            <InfoFillCircleIcon /> <span className={'pl-2'}></span>12 months contract commitment
                        </div>
                        <div className={'flex p-4'}>
                            <div className={'w-1/2'}>
                                <div className={'flex text-[12px] font-semibold'}>
                                    {realValue >= 5000000 ? navValue : '5.000.000'} EUR x 0.05% / 12
                                </div>
                                <div className={'flex mt-2.5 text-[10px] text-[#83899F] font-medium'}>
                                    Re-Calculated on a monthly base
                                </div>
                            </div>
                            <div className={'w-1/2 flex justify-end items-center'}>
                                <span className={'text-accent text-[14px] font-bold'}>{valuePerMonth >= 208.33 ? valuePerMonth : '208.33'}€</span>
                                <span className={'text-[12px] text-[#485066] font-semibold relative top-0.5 pl-1'}>/month</span>
                            </div>
                        </div>
                        <div className={'flex px-4 '}>
                            <div className={'w-1/2 border-t border-[#F5F5F5] py-4'}>
                                <p className={'text-[12px] text-[#83899F] font-medium'}>Expected annual fee</p>
                            </div>
                            <div className={'w-1/2 flex justify-end items-center border-t py-4 border-[#F5F5F5]'}>
                                <span className={'text-[#83899F] text-[12px] font-semibold'}>{expectedAnnualFee >= 2500 ? asNumber(expectedAnnualFee) : '2.500'}€</span>

                            </div>
                        </div>
                    </div> }
                    <div className="flex justify-center relative mt-[30px]">
                        <button onClick={() => handleToSignUp && pricePlan && handleToSignUp(pricePlan)} disabled={!navValue} className={`bg-accent text-white text-[13px] w-full font-semibold py-4 rounded-md w-full items-center text-center ${!navValue && 'opacity-50 cursor-not-allowed'}`}>
                            Start 7 day trial now!
                        </button>
                    </div>
                    <div className="flex justify-center relative mt-[20px]">
                        <span className="text-accent text-[11px] items-center font-semibold flex" onClick={() => alert('where Goes this?')}><LinkIcon /> &nbsp;&nbsp;Contact Us</span>
                    </div>
                    <button onClick={closeModal} className="absolute -top-2 -right-2 text-gray-700 hover:text-gray-900">
                       <span className=" items-center justify-center flex w-[32px] h-[32px] rounded-full">
                            <CloseModalIcon />
                        </span>
                    </button>
                </div>
            </motion.div>
        </motion.div>
    );
}

export default CalculateContentModal;
