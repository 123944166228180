import React from "react";

type Props = {
  firstOption: string;
  secondOption: string;
  checked: boolean;
  id: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  small?: boolean;
  disabled?: boolean;
};

export const ToggleWithText = ({
  firstOption,
  secondOption,
  checked,
  id,
  name,
  onChange,
  small = false,
  disabled = false,
}: Props) => {
  return (
    <label
      htmlFor={id}
      className={`inline-flex items-center self-end  p-1 rounded-[6px] ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } text-gray-700 border text-sm`}
    >
      <input
        id={id}
        name={name}
        type="checkbox"
        className="hidden peer"
        onChange={onChange}
        disabled={disabled}
      />
      <span
        className={`px-2.5 text-[14px] py-2 rounded-[6px] font-semibold ${
          checked ? "bg-[#275EF726] text-accent" : "bg-white text-[#485066]"
        } text-xs `}
      >
        {firstOption}
      </span>
      <span
        className={`px-2.5 text-[14px] py-2 rounded-[6px] font-semibold ${
          !checked ? "bg-[#275EF726] text-accent" : "bg-white text-[#485066]"
        } text-xs `}
      >
        {secondOption}
      </span>
    </label>
  );
};
