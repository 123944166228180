import React from "react";
import { CheckIconCircle } from "../../components/icons/CheckIconCircle";

export const AdvancedPlanList = () => {
  return (
    <div className={"flex justify-start flex-col"}>
       <div className={"flex justify-start"}>
        <span
          className={"justify-start text-[12px] text-[#83899F] text-center"}
        >
           Ideal for established funds that want to take their customer relationships to the next level.
        </span>
      </div>
    </div>
  );
};
