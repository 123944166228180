import React from "react";

import {ListItem} from "./ListItem";
import {PriceListCard} from "./PriceListCard";
import {
  individualAssetOptions,
  individualCoreFunctions,
  individualFunctionsAccounting,
  individualFunctionsReporting,
  individualFunctionsTracking,
  individualImportOptions,
} from "../utils";
import {CommunityDescList} from "../sections/price-list/CommunityDescList";
import {IndividualsStarterDescList} from "../sections/price-list/IndividualsStarterDescList";
import {IndividualsAdvancedDescList} from "../sections/price-list/IndividualsAdvancedDescList";
import {IndividualsProfessionalDescList} from "../sections/price-list/IndividualsProfessionalDescList";

type PriceListFundsProps = {
  isMonthly: boolean;
  referralId?: string;
};

export const IndividualsPriceList = ({
  isMonthly,
  referralId,
}: PriceListFundsProps) => {
  return (
    <div className="mx-auto flex max-w-screen-xl container w-full justify-center flex-row xl  max-h-max max-lg:px-2 pb-20">
      <div
        className="w-1/4 hidden md:flex flex-col mr-3 min-w-[307px] relative before:absolute before:top-[396px] before:h-[395px] before:bg-[#F5F7FD] before:-left-4 before:w-[110%] before:-z-10 before:border-y-2 before:border-y-[#9CB5FB]"
      >
        <div className="flex flex-col mt-12">
          <h3 className="text-[#373A45] font-bold pb-2">Featured Status</h3>
          <ListItem key={1} color={"#1AB200"} text={"Online"}></ListItem>
          <ListItem key={2} color={"#F76527"} text={"Beta Phase"}></ListItem>
          <ListItem key={3} color={"#686F87"} text={"Coming 2025"}></ListItem>
        </div>

        <div
          className="mt-[76px] pb-[30px] text-[16px] font-medium text-[#83899F]"
        >
          <span className="text-accent"> Please note! </span>
          All our fees and prices are before VAT.
          <br />
        </div>

        <div className="flex flex-col mt-[75px]">
          <>
            <h3 className={"text-[#373A45] font-bold"}>Compare Limits & Modules</h3>
            {individualCoreFunctions.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`core-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <span className="font-bold pt-3 text-xs">
              *for supported countries AT, DE
            </span>
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Import Options
            </h3>
            {individualImportOptions.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`import-options-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <span className="font-bold pt-3 text-xs">
              *please check our support level of integrations
            </span>
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Asset Support
            </h3>
            {individualAssetOptions.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`import-options-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Tracking Module
            </h3>
            {individualFunctionsTracking.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`funcTracking-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Accounting Module
            </h3>
            {individualFunctionsAccounting.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`funcAccounting-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
            <span className="font-bold pt-3 text-xs">
              *for supported countries AT, DE
            </span>
            <h3 className="text-[#373A45] font-bold mt-[55px]">
              Reporting Module
            </h3>
            {individualFunctionsReporting.map((item, index) => (
              <ListItem
                border
                tooltip={item.tooltip}
                key={`funcReporting-${index}`}
                small
                color={item.color}
                text={item.title}
              ></ListItem>
            ))}
          </>
        </div>
      </div>
      <div
        className="md:w-3/4 w-full flex md:flex-row flex-col overflow-x-auto"
      >
        <div className="w-full md:w-1/4 min-w-[230px]">
          <PriceListCard
            desc={<CommunityDescList />}
            type={"Tracker"}
            btnText={"Start now!"}
            pricePlanId={isMonthly ? "copilot_mon" : "copilot_anu"}
            isMonthly={isMonthly}
            referralId={referralId}
            free
            isIndividual
          />
        </div>
        <div className="w-full md:w-1/4 min-w-[230px]">
          <PriceListCard
            desc={<IndividualsStarterDescList />}
            type="Tracker Pro"
            price={`${isMonthly ? "€24" : "264"}`}
            btnText={"Start 14 day trial!"}
            pricePlanId={isMonthly ? "copilot-tracking_mon" : "copilot-tracking_anu"}
            isMonthly={isMonthly}
            referralId={referralId}
            isIndividual
          />
        </div>
        <div className="w-full md:w-1/4 min-w-[230px]">
          <PriceListCard
            desc={<IndividualsAdvancedDescList />}
            type="Individuals Advanced"
            packageName="Accounting"
            price={`${isMonthly ? "€65" : "€590"}`}
            btnText="Start 14 day trial!"
            pricePlanId={isMonthly ? "copilot-company-starter_mon" : "copilot-company-starter_anu"}
            isMonthly={isMonthly}
            referralId={referralId}
            popular
            isIndividual
          />
        </div>
        <div className="w-full md:w-1/4 min-w-[230px]">
          <PriceListCard
            desc={<IndividualsProfessionalDescList />}
            type="Accounting Pro"
            price={`${isMonthly ? "€250" : "€2600"}`}
            // trial={
            //   <div className={"flex-row flex"}>
            //     <InfoCircleIcon />
            //     <p
            //       className={`flex justify-start ${
            //         isMonthly ? "flex-row" : "flex-col"
            //       }`}
            //     >
            //       <span
            //         className={`flex pl-1 text-[#485066] text-[11px] font-[500]`}
            //       >
            //         {isMonthly ? "1 month" : "12 months"}
            //       </span>
            //       <span
            //         className={
            //           "pl-1 flex text-[#83899F] text-[11px] font-[500]"
            //         }
            //       >
            //         contract commitment
            //       </span>
            //     </p>
            //   </div>
            // }
            btnText="Start 14 day trial!"
            pricePlanId={isMonthly ? "copilot-pro_mon" : "copilot-pro_anu"}
            isMonthly={isMonthly}
            referralId={referralId}
            isIndividual
          />
        </div>
      </div>
    </div>
  );
};
