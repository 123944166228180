import Button from "../../components/Button";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { T } from "@tolgee/react";

function AnnouncementBanner({
  message,
  link,
  onClick,
}: {
  message: string;
  link?: string;
  onClick?: any;
}) {
  return (
    <div
      className="text-white p-3"
      style={{
        background:
          "linear-gradient(99deg, #312E81 0%, #1E40AF 0.01%, #1E3A8A 46%, #384AEA 96.97%",
      }}
    >
      <div className="flex sm:flex-row font-medium max-sm:px-3 flex justify-center items-center">
        <span className={"flex"}>
          <T keyName={message} />
        </span>
        <Button
          onClick={onClick}
          text={<T keyName="book_now" />}
          color="primary"
          size="small"
          className="max-sm:block max-sm:mt-3 max-sm:mx-auto md:text-[12px] lg:text-[16px] "
        />
      </div>
    </div>
  );
}

export default AnnouncementBanner;
