import React, { useRef, useState } from "react";
import ItemsCarousel from "react-items-carousel";
import { TestimonialsArray } from "../../utils";
import Stars from "../../img/stars.svg";
import { CustomDots } from "./CustomDots";
import { useSwipeable } from "react-swipeable";
import { T } from "@tolgee/react";

export const Testimonials = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 20;

  const width = window.innerWidth;

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setActiveItemIndex((prev) =>
        prev === TestimonialsArray.length - 1 ? 0 : prev + 1
      ),
    onSwipedRight: () =>
      setActiveItemIndex((prev) =>
        prev === 0 ? TestimonialsArray.length - 1 : prev - 1
      ),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className="w-full md:max-w-screen-xl box m-auto mb-18 md:mb-18 lg:mb-32 mt-5 "
    >
      <h2 className={"text-[24px] font-bold pb-8"}>
        <T keyName={"our_work_appreciated"} />
      </h2>
      <div className={"px-5 md:px-10"}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={window.innerWidth > 1024 ? 3 : 1}
          gutter={10}
          infiniteLoop
          disableSwipe={true}
          outsideChevron
          chevronWidth={chevronWidth}
          classes={{itemsInnerWrapper: 'flex items-center justify-center'}}
          leftChevron={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
                stroke={"#98a2b3"}
              />
            </svg>
          }
          rightChevron={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                stroke={"#98a2b3"}
              />
            </svg>
          }
        >
          {TestimonialsArray.map((testimonial, i) => (
            <div key={i} className={"flex justify-center m-2 mb-7"}>
              <div
                className={
                  "w-full cursor-grab p-7 items-center h-full justify-start shadow-testimonial rounded-[16px] "
                }
              >
                <div className={"flex flex-row items-center"}>
                  <img
                    className="rounded-full flex w-10 h-[60px] w-[60px] mr-4"
                    src={testimonial.avatarUrl}
                    alt={testimonial.name}
                  />
                  <div className={"flex flex-col justify-start text-start"}>
                    <h2 className={"flex text-[18px] font-bold text-[#0F172A]"}>
                      {testimonial.name}
                    </h2>
                    <h3
                      className={"flex text-[16px] font-medium text-[#475569]"}
                    >
                      {testimonial.position}
                    </h3>
                  </div>
                </div>
                <div className={"flex pt-3"}>
                  <img src={Stars} alt="stars" className="w-20 mt-2" />
                </div>
                <div className={"flex pt-3"}>
                  <p
                    className={
                      "text-start text-[#303A42] text-[16px] font-medium"
                    }
                  >
                    "{testimonial.quote}"
                  </p>
                </div>
              </div>
            </div>
          ))}
        </ItemsCarousel>
      </div>
      <div className={"flex flex-row justify-center"}>
        {TestimonialsArray.map((tst, i) => (
          <CustomDots
            active={activeItemIndex}
            index={i}
            onClick={() => setActiveItemIndex(i)}
          />
        ))}
      </div>
    </div>
  );
};
