import { useMatomo } from "@jonkoops/matomo-tracker-react";
import React from "react";
import ReactGA from "react-ga";
import { TwitterIcon } from "../../components/icons/TwitterIcon";
import { LinkedinIcon } from "../../components/icons/LinkedinIcon";
import { T } from "@tolgee/react";
// @ts-ignore
import ItemsCarousel from "react-items-carousel";
import { useState } from "react";
import { BoardMembersType } from "../../utils";

export const AdvisoryBoard = ({
  sectionTitle,
  members,
}: {
  sectionTitle?: string;
  members: BoardMembersType[];
}) => {
  const { trackEvent } = useMatomo();
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 20;

  const onSocialMediaClick = (
    socialMedia: "linkedin" | "twitter" | "xing",
    name: string
  ) => {
    trackEvent({
      category: "advisory board",
      action: `${socialMedia} of ${name} clicked`,
    });
    ReactGA.event({
      category: "advisory board",
      action: `${socialMedia} of ${name} clicked`,
      label: "Advisory Board",
      nonInteraction: true,
    });
  };

  return (
    <div className={"max-w-screen-xl box m-auto md:mb-32 mb:16  max-xl:p-5"}>
      <p className="font-semibold text-black text-[24px] pb-10">
        <T keyName={sectionTitle || "advisory_board"} />
      </p>

      <div className={" md:hidden block"}>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={1}
          gutter={10}
          infiniteLoop
          outsideChevron
          chevronWidth={chevronWidth}
          classes={{itemsInnerWrapper: 'flex items-center justify-center'}}
          leftChevron={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5 8.25 12l7.5-7.5"
                stroke={"#98a2b3"}
              />
            </svg>
          }
          rightChevron={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                stroke={"#98a2b3"}
              />
            </svg>
          }
        >
          {members.map((member, i) => (
            <div
              className="flex flex-col  self-stretch items-center w-full  mb-4 "
              key={"AdvisorBoard-" + i}
            >
              <div
                className={
                  "flex flex-col items-center h-full justify-start p-6 mx-2 rounded-[16px] self-stretch shadow-cards "
                }
              >
                <img
                  src={member.image}
                  alt="logo"
                  className="my-3 object-contain h-[80px] border-r-6 rounded-full"
                  key={"memberImage-" + i}
                />
                <h2 className="text-[#101828] text-[18px] leading-7 font-bold">
                  {member.name}
                </h2>
                <p className="font-medium text-[14px] leading-6 text-[#312E81]">
                  {member.job}
                </p>
                <p className="text-[#667085] text-[14px] leading-6 w-full">
                  {member.position}
                </p>
                <div className="flex flex-row">
                  {member.twitter && (
                    <a
                      href={member.twitter}
                      className={"mt-3 px-1"}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() => onSocialMediaClick("twitter", member.name)}
                    >
                      <TwitterIcon />
                    </a>
                  )}
                  {member.linkedin && (
                    <a
                      href={member.linkedin}
                      className={"mt-3  px-1"}
                      target="_blank"
                      rel="noreferrer"
                      onClick={() =>
                        onSocialMediaClick("linkedin", member.name)
                      }
                    >
                      <LinkedinIcon />
                    </a>
                  )}
                </div>
              </div>
            </div>
          ))}
        </ItemsCarousel>
      </div>

      <div className="flex hidden md:flex flex-wrap justify-center">
        {members.map((member, i) => (
          <div
            className="flex flex-col items-center w-full sm:w-6/12 md:w-3/12 mb-4 "
            key={"AdvisorBoard-" + i}
          >
            <div
              className={
                "flex flex-col items-center h-full justify-start p-6 mx-2 rounded-[16px] self-stretch shadow-cards "
              }
            >
              <img
                src={member.image}
                alt="logo"
                className="my-3 object-contain h-[80px] border-r-6 rounded-full"
                key={"memberImage-" + i}
              />
              <h2 className="text-[#101828] text-[18px] leading-7 font-bold">
                {member.name}
              </h2>
              <p className="font-medium text-[14px] leading-6 text-[#312E81]">
                {member.job}
              </p>
              <p className="text-[#667085] text-[14px] leading-6 w-full">
                {member.position}
              </p>
              <div className="flex flex-row">
                {member.twitter && (
                  <a
                    href={member.twitter}
                    className={"mt-3 px-1"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onSocialMediaClick("twitter", member.name)}
                  >
                    <TwitterIcon />
                  </a>
                )}
                {member.linkedin && (
                  <a
                    href={member.linkedin}
                    className={"mt-3  px-1"}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => onSocialMediaClick("linkedin", member.name)}
                  >
                    <LinkedinIcon />
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
