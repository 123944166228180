import React from "react";
import { Tooltip } from 'react-tooltip'
import {consensusBackground, consensusColor} from "../../utils";
import {DefaultIntegration} from "../../components/icons/DefaultIntegration";

type Props = {
    row?: any
}

export function statusSyncIcon(status: string) {
    switch (status) {
        case "Automated":
            return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="22" height="22" x="0" y="0" viewBox="0 0 32 32" className=""><g><path d="M24.972 12.288C24.608 7.657 20.723 4 16 4a8.94 8.94 0 0 0-8.628 6.451A8.004 8.004 0 0 0 2 18c0 4.411 3.589 8 8 8h13c3.859 0 7-3.141 7-7a6.97 6.97 0 0 0-5.028-6.712zM23 24H10c-3.309 0-6-2.691-6-6a6.003 6.003 0 0 1 4.457-5.792 1 1 0 0 0 .719-.747C9.887 8.297 12.756 6 16 6a6.992 6.992 0 0 1 6.996 7.057 1 1 0 0 0 .834 1.019A4.978 4.978 0 0 1 28 19c0 2.757-2.243 5-5 5z" fill="#3da515" opacity="1" data-original="#000000" className=""/><path d="M10.293 14.293a.999.999 0 0 1 1.414 0l2 2A1 1 0 0 1 13 18h-.858c.447 1.721 1.999 3 3.858 3a3.98 3.98 0 0 0 1.599-.332.998.998 0 0 1 1.316.516.998.998 0 0 1-.516 1.316c-.758.332-1.566.5-2.399.5-2.967 0-5.431-2.167-5.91-5H9a.999.999 0 0 1-.707-1.707zM18.076 16.617A1 1 0 0 1 19 16h.858c-.447-1.72-1.999-3-3.858-3-.558 0-1.097.112-1.602.333a1.001 1.001 0 0 1-.802-1.832A5.967 5.967 0 0 1 16 11c2.967 0 5.431 2.167 5.91 5H23a1 1 0 0 1 .707 1.707l-2 2a.997.997 0 0 1-1.414 0l-2-2a1 1 0 0 1-.217-1.09z" fill="#3da515" opacity="1" data-original="#000000" className=""/></g></svg>
        case "Manually":
            return <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 24 24" className=""><g><path fill="#4189ff" fill-rule="evenodd" d="M6 4a3 3 0 0 1 3-3h7.172a3 3 0 0 1 2.12.879l3.83 3.828A3 3 0 0 1 23 7.828V20a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V9h-3a3 3 0 0 1-3-3V3H9a1 1 0 0 0-1 1v7a1 1 0 1 1-2 0zm11-.586L20.586 7H18a1 1 0 0 1-1-1zm-2.293 12.293-3 3a1 1 0 0 1-1.414-1.414L11.586 16H2a1 1 0 1 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z" clip-rule="evenodd" opacity="1" data-original="#000000" className=""/></g></svg>
        default:
            return <span className='text-sm'>-</span>
    }
}

export function statusIcon(status: string) {
    switch (status) {
        case "Online":
            return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_984_16801)">
                    <path d="M4.99998 7.99998L6.99998 9.99998L11 5.99998M14.6666 7.99998C14.6666 11.6819 11.6819 14.6666 7.99998 14.6666C4.31808 14.6666 1.33331 11.6819 1.33331 7.99998C1.33331 4.31808 4.31808 1.33331 7.99998 1.33331C11.6819 1.33331 14.6666 4.31808 14.6666 7.99998Z" stroke="#148800" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_984_16801">
                        <rect width="16" height="16" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        case "Maintenance":
            return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.99998 3.99998L6.99998 6.99998M3.99998 3.99998H1.99998L1.33331 1.99998L1.99998 1.33331L3.99998 1.99998V3.99998ZM12.8393 1.82732L11.0876 3.57907C10.8235 3.84308 10.6915 3.97508 10.6421 4.1273C10.5986 4.2612 10.5986 4.40543 10.6421 4.53932C10.6915 4.69154 10.8235 4.82355 11.0876 5.08756L11.2457 5.24573C11.5097 5.50974 11.6417 5.64175 11.794 5.69121C11.9279 5.73471 12.0721 5.73471 12.206 5.69121C12.3582 5.64175 12.4902 5.50974 12.7542 5.24573L14.3928 3.60712C14.5693 4.03657 14.6666 4.50691 14.6666 4.99998C14.6666 7.02502 13.025 8.66665 11 8.66665C10.7558 8.66665 10.5173 8.64279 10.2865 8.59727C9.96239 8.53335 9.80034 8.50139 9.7021 8.51118C9.59766 8.52159 9.54619 8.53725 9.45365 8.58677C9.36661 8.63335 9.27929 8.72066 9.10466 8.89529L4.33331 13.6666C3.78103 14.2189 2.8856 14.2189 2.33331 13.6666C1.78103 13.1144 1.78103 12.2189 2.33331 11.6666L7.10467 6.89529C7.27929 6.72066 7.36661 6.63335 7.41319 6.5463C7.46271 6.45377 7.47837 6.40229 7.48878 6.29785C7.49857 6.19962 7.46661 6.03757 7.40269 5.71347C7.35717 5.48268 7.33331 5.24412 7.33331 4.99998C7.33331 2.97494 8.97494 1.33331 11 1.33331C11.6703 1.33331 12.2986 1.51319 12.8393 1.82732ZM8.00001 9.99994L11.6666 13.6666C12.2189 14.2189 13.1144 14.2189 13.6666 13.6666C14.2189 13.1143 14.2189 12.2189 13.6666 11.6666L10.6502 8.65018C10.4367 8.62997 10.2285 8.59145 10.0272 8.5362C9.76779 8.46501 9.48328 8.51668 9.29309 8.70687L8.00001 9.99994Z" stroke="#83899F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        default:
            return <span className='text-sm'>-</span>
    }
}

export const SupportedIntegrationsTableRow = ({ row }: Props) => {

    return (
        <tr>
            <td className="border-b border-slate-200 p-3 text-center">
                <div data-tooltip-id={`tooltip-platform-${row?.iconUrl}`}
                     data-tooltip-content={row?.platformStatus}>
                <span className={`px-2.5 py-0.5 font-medium rounded-[4px] flex justify-center`}>{statusIcon(row?.platformStatus)}</span>
                <Tooltip
                    place="bottom"
                    id={`tooltip-platform-${row?.iconUrl}`}
                    className="px-3 py-3 text-xs font-normal z-[999] text-white rounded-lg"
                /></div>
            </td>
            <td className="border-b border-slate-200 font-medium p-4">
                <div className='flex items-center'>
                    <div className="relative">
                        <div className="text-gray-300 cursor-pointer flex">
                            <div className="flex h-[35px] border-2 border-gray-300 rounded-lg overflow-hidden">
                                {row?.iconUrl ? <img
                                    src={row?.iconUrl}
                                    alt={row?.displayName}
                                    className='max-w-[31px]'
                                /> :
                                <DefaultIntegration />}
                            </div>
                        </div>
                    </div>
                    <div
                        className="pl-2.5"
                    >
                        <p className="flex text-gray-800 text-sm font-medium mb-0.5 truncate">
                            {row?.displayName}
                        </p>

                    </div>
                </div>
            </td>
            <td className="border-b border-slate-200 p-3 text-center">
                <div data-tooltip-id={`tooltip-balance-${row?.iconUrl}`}
                data-tooltip-content={row?.balanceSync}>
            <span className={`px-2.5 py-0.5 font-medium  rounded-[4px] flex justify-center`}>{statusSyncIcon(row?.balanceSync)}</span>
                <Tooltip
                    place="bottom"
                    id={`tooltip-balance-${row?.iconUrl}`}
                    className="px-3 py-3 text-xs font-normal z-[999] text-white rounded-lg"
                /></div>
            </td>
            <td className="border-b border-slate-200 p-3 text-center">
                <div data-tooltip-id={`tooltip-staking-${row?.iconUrl}`}
                     data-tooltip-content={row?.stakingSync}>
                <span className={`px-2.5 py-0.5 font-medium rounded-[4px] flex justify-center`}>{statusSyncIcon(row?.stakingSync)}</span>
                <Tooltip
                    place="bottom"
                    id={`tooltip-staking-${row?.iconUrl}`}
                    className="px-3 py-3 text-xs font-normal z-[999] text-white rounded-lg"
                /></div>
            </td>
            <td className="border-b border-slate-200 p-3 text-center">
                <div data-tooltip-id={`tooltip-transaction-${row?.iconUrl}`}
                     data-tooltip-content={row?.transactionSync}>
                <span className={`px-2.5 py-0.5 font-medium rounded-[4px] flex justify-center`}>{statusSyncIcon(row?.transactionSync)}</span>
                    <Tooltip
                        place="bottom"
                        id={`tooltip-transaction-${row?.iconUrl}`}
                        className="px-3 py-3 text-xs font-normal z-[999] text-white rounded-lg"
                    /></div>
            </td>
        </tr>
    )
}