import React, {useEffect, useRef, useState} from "react";
import Header from "../../components/Header";

import Footer from "../../components/Footer";
import { SupportedIntegrationsTableRow} from "./SupportedIntegrationsTableRow";
import { T } from "@tolgee/react";
import {InfoBox} from "../../components/InfoBox";

export const SupportedIntegrations = () => {

    const [platforms, setPlatforms] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [banner, setBanner] = useState({});

    const handleSearchChange = (event:any) => {
        setSearchTerm(event.target.value);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null);


    const [selectedOption, setSelectedOption] = useState<string>('all');
    const handleOptionChange = (value: string) => {
        setSelectedOption(value);
    };

    const filteredItems = platforms.filter((item:any) => {
        // Filter by search text
        const searchTextMatch = item?.displayName.toLowerCase().includes(searchTerm.toLowerCase());

        // Filter by selected category (if any)
        const categoryMatch = selectedOption !== 'all' ? item?.integrationCategory === selectedOption : true;

        return searchTextMatch && categoryMatch;
    });


    useEffect(() => {
        fetch(`https://coredach01.azurewebsites.net/api/IntegrationInfo/status`)
            .then((response) => response.json())
            .then((actualData) => {
                setPlatforms(actualData.platforms)
                setBanner(actualData.statusBanner)
            });


    }, [])

    return (
        <>
            <Header />
            {/*<div className={'max-w-screen-xl flex w-full mx-auto pt-6 pb-4'}>*/}
            {/*    {Object.keys(banner).length !== 0 &&*/}
            {/*    <InfoBox banner={banner} />*/}
            {/*    }*/}
            {/*</div>*/}
            <div className={'flex max-w-screen-xl mx-auto pb-0 pt-8'}>
                <h1 className={' flex  text-[#0A1B39] text-[20px] font-bold font-tt'}>
                    <T keyName={'list_integrations'} />
                </h1>
            </div>
            <div className='flex justify-between items-center max-w-screen-xl mx-auto'>
                <div className='flex justify-start  pt-4 pb-5'>
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search..."
                        className={'block w-64 p-2 pl-3 text-xs text-gray-900 h-9 border shadow-none  outline-none rounded-lg focus-visible:border-blue-500 focus:border-blue-500  '}
                 />
                <div className={''} ref={dropdownRef}>
                    <button id="status" name="status"
                            onClick={() => setDropdownOpen(!dropdownOpen)}
                            className={`border ${selectedOption === 'Chain' || selectedOption === 'Cex' ? 'border-accent text-accent' : 'border-gray-300 text-gray-600'} text-gray-600 focus:border-blue-500  w-28 ml-2 bg-white font-medium rounded-lg text-xs px-5 py-2.5 h-9 text-center hover:border-gray-500 transition duration-150 ease-in-out flex justify-between items-center`}
                            type="button">{selectedOption === 'Chain' ? 'Chain' : selectedOption === 'Cex' ? 'CEX' : 'All'}
                        <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true"
                                                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="m1 1 4 4 4-4"></path>
                        </svg>
                    </button>
                    {dropdownOpen && <div id="dropdown"
                         className="z-10 absolute ml-2 w-32 bg-white divide-y divide-gray-100 rounded-lg shadow w-auto p-1.5 dark:bg-gray-700">
                        <ul className="text-sm text-gray-700 m-0 dark:text-gray-200"
                            aria-labelledby="dropdownDefaultButton">
                            <li className={'p-2'}>
                                <div className="flex items-center cursor-pointer">
                                    <input id="categories-all" type="radio" value="all" name="categories"
                                           checked={selectedOption === 'all'}
                                           onChange={(e) => handleOptionChange(e.target.value)}
                                           className="w-4 h-4 outline-none focus:ring-[0px] mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                    <label htmlFor="categories-all"
                                           className="text-sm w-full font-medium text-gray-900 dark:text-gray-300 cursor-pointer">
                                        All
                                    </label>
                                </div>
                            </li>
                            <li className={'p-2'}>
                                <div className="flex items-center cursor-pointer">
                                    <input id="categories-1" type="radio" value="Chain" name="categories"
                                           onChange={(e) => handleOptionChange(e.target.value)}
                                           checked={selectedOption === 'Chain'}
                                           className="w-4 h-4 outline-none focus:ring-[0px] mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <label htmlFor="categories-1"
                                               className="text-sm w-full font-medium text-gray-900 dark:text-gray-300 cursor-pointer">
                                            Chain
                                        </label>
                                </div>
                            </li>
                            <li className={'p-2'}>
                                <div className="flex items-center cursor-pointer">
                                    <input id="categories-2" type="radio" value="Cex" name="categories"
                                           onChange={(e) => handleOptionChange(e.target.value)}
                                           checked={selectedOption === 'Cex'}
                                           className="w-4 h-4 outline-none focus:ring-[0px]  mr-1 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <label htmlFor="categories-2"
                                               className="ms-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer">
                                            CEX
                                        </label>
                                </div>
                            </li>
                        </ul>
                    </div>}
                </div>
            </div>

<div className='flex gap-3 items-center'><div className='flex gap-2 items-center'><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="22" height="22" x="0" y="0" viewBox="0 0 32 32" className=""><g><path d="M24.972 12.288C24.608 7.657 20.723 4 16 4a8.94 8.94 0 0 0-8.628 6.451A8.004 8.004 0 0 0 2 18c0 4.411 3.589 8 8 8h13c3.859 0 7-3.141 7-7a6.97 6.97 0 0 0-5.028-6.712zM23 24H10c-3.309 0-6-2.691-6-6a6.003 6.003 0 0 1 4.457-5.792 1 1 0 0 0 .719-.747C9.887 8.297 12.756 6 16 6a6.992 6.992 0 0 1 6.996 7.057 1 1 0 0 0 .834 1.019A4.978 4.978 0 0 1 28 19c0 2.757-2.243 5-5 5z" fill="#3da515" opacity="1" data-original="#000000" className=""/><path d="M10.293 14.293a.999.999 0 0 1 1.414 0l2 2A1 1 0 0 1 13 18h-.858c.447 1.721 1.999 3 3.858 3a3.98 3.98 0 0 0 1.599-.332.998.998 0 0 1 1.316.516.998.998 0 0 1-.516 1.316c-.758.332-1.566.5-2.399.5-2.967 0-5.431-2.167-5.91-5H9a.999.999 0 0 1-.707-1.707zM18.076 16.617A1 1 0 0 1 19 16h.858c-.447-1.72-1.999-3-3.858-3-.558 0-1.097.112-1.602.333a1.001 1.001 0 0 1-.802-1.832A5.967 5.967 0 0 1 16 11c2.967 0 5.431 2.167 5.91 5H23a1 1 0 0 1 .707 1.707l-2 2a.997.997 0 0 1-1.414 0l-2-2a1 1 0 0 1-.217-1.09z" fill="#3da515" opacity="1" data-original="#000000" className=""/></g></svg>

    <span className='text-sm'>Automated</span></div>
    <div className='flex gap-2 items-center'><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="20" height="20" x="0" y="0" viewBox="0 0 24 24" className=""><g><path fill="#4189ff" fill-rule="evenodd" d="M6 4a3 3 0 0 1 3-3h7.172a3 3 0 0 1 2.12.879l3.83 3.828A3 3 0 0 1 23 7.828V20a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3v-1a1 1 0 1 1 2 0v1a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V9h-3a3 3 0 0 1-3-3V3H9a1 1 0 0 0-1 1v7a1 1 0 1 1-2 0zm11-.586L20.586 7H18a1 1 0 0 1-1-1zm-2.293 12.293-3 3a1 1 0 0 1-1.414-1.414L11.586 16H2a1 1 0 1 1 0-2h9.586l-1.293-1.293a1 1 0 0 1 1.414-1.414l3 3a1 1 0 0 1 0 1.414z" clip-rule="evenodd" opacity="1" data-original="#000000" className=""/></g></svg>

        <span className='text-sm'>Manually</span></div></div>
            </div>

            <div className="max-w-screen-xl  mx-auto bg-gray-50 rounded-xl  mb-12">
                <div className="relative rounded-xl overflow-auto">
                    <div className="mt-3">
                        <table className="table-auto w-full text-sm overflow-x-auto">
                            <thead>
                            <tr>
                                <th className="border-b border-slate-200 font-semibold w-[20px] p-4 pt-0 pb-3 text-center"></th>
                                <th className="border-b border-slate-200 font-semibold w-[40%] p-4 pt-0 pb-3 text-left ">Integration</th>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-center ">Balance Sync</th>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-center ">Staking Sync</th>
                                <th className="border-b border-slate-200 font-semibold w-[20%] p-4 pt-0 pb-3 text-center ">Transaction Sync</th>
                            </tr>
                            </thead>
                            <tbody className="bg-white ">
                            {filteredItems.length === 0 &&
                                <tr>
                                    <td colSpan={5}><p className={'p-4 items-start text-center'}><T keyName={'no_integrations'} /></p></td> </tr>}
                            {filteredItems && filteredItems.map((row:any) =>
                                <SupportedIntegrationsTableRow key={row?.displayName} row={row} />
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>




            <Footer />
        </>
    )
}