import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { T, useTolgee } from "@tolgee/react";
import Button from "../../components/Button";
import React from "react";

function Contact() {
  const { trackEvent } = useMatomo();
  const tolgee = useTolgee(["language"]);

  const onNewsletterButtonClick = () => {
    trackEvent({ category: "contact", action: `newsletter button click` });
  };

  const onCallButtonClick = (type: "features" | "book-call") => {
    trackEvent({ category: "contact", action: `call button click` });

    if (type === "book-call") {
      openCalendlyModal();
    }
  };

  const openCalendlyModal = () => {
    const event = new CustomEvent("openCalendlyModal");
    window.dispatchEvent(event);
  };

  const openForm = () => {
    let formUrl = "";

    if (tolgee.getLanguage() === "en") {
      formUrl = "https://7tfcckb894t.typeform.com/to/UQpy2Ohb";
    } else {
      formUrl = "https://form.typeform.com/to/NYt8sXIO";
    }

    window.open(formUrl, "_blank");
  };

  return (
    <div className="max-w-screen-xl box mx-auto my-20 lg:my-32 max-lg:px-5">
      {/* <h1 className="text-[24px] font-bold text-[#0F172A] mb-12 max-md:text-3xl">
                <T keyName={'contact_title'} />
            </h1> */}
      <div className="h-fit flex flex-row justify-between items-start max-md:flex-col shadow-map rounded-[24px] py-[20px] px-[20px] lg:py-[120px] lg:px-[90px]">
        <div
          className={
            "md:flex hidden h-full flex-1 p-3 px-0 justify-center items-stretch self-center"
          }
          style={{ alignItems: "center" }}
        >
          <img
            src="/DEMO/images/contact1.svg"
            alt="Contact image"
            className="flex w-3/4"
          />
        </div>
        <div
          className={"flex-1 flex-col self-center flex max-md:min-w-full p-6"}
        >
          <p
            className={
              "font-semibold text-xl pb-2 justify-start text-start items-center flex"
            }
          >
            <T keyName={"form_title"} />
          </p>
          <p
            className={
              "font-medium text-[#42526B] text-lg pb-2 pr-12 justify-start text-start items-center flex"
            }
          >
            <T keyName={"form_desc"} />
          </p>
          {/* <p
            className={
              " text-[16px] text-[#98A2B3] justify-start text-start items-center flex"
            }
          >
            <T keyName={"form_desc_second"} />
          </p> */}
          <Button
            text={<T keyName="form_btn" />}
            color="primary"
            size="large"
            className="mt-[34px] py-3 block mx-0 w-auto inline-block min-w-[0px] max-w-[250px]"
            onClick={() => openForm()}
          />
          {/*<HubspotForm*/}
          {/*    region="eu1"*/}
          {/*    portalId="27026247"*/}
          {/*    formId="c8dfc87d-8010-4679-b508-7f427bc9d01d"*/}
          {/*/>*/}
          {/*    <TypeformEmbed />*/}
          {/*<Button text="Book a call" color="secondary" size="medium" className="w-full mx-0 my-4 h-[42px]" onClick={() => onCallButtonClick('book-call')}/>*/}
        </div>
      </div>
    </div>
  );
}

export default Contact;
