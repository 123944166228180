import React from "react";

export const AnalyticsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="35"
      viewBox="0 0 36 35"
      fill="none"
    >
      <rect
        opacity="0.1"
        x="0.255615"
        width="35"
        height="35"
        rx="8"
        fill="#275EF7"
      />
      <rect
        opacity="0.8"
        x="15.1735"
        y="12.3506"
        width="5.17045"
        height="12.6389"
        rx="2.58523"
        fill="#275EF7"
      />
      <rect
        opacity="0.5"
        x="8.27893"
        y="15.2236"
        width="5.17045"
        height="9.76641"
        rx="2.58523"
        fill="#275EF7"
      />
      <rect
        x="22.0681"
        y="9.47852"
        width="5.17045"
        height="15.5114"
        rx="2.58523"
        fill="#275EF7"
      />
    </svg>
  );
};
