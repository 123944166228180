import React from "react";

export const CryptoFundsIcon = ({ color = "white" }: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M8.08724 2.66097C8.08724 3.39735 6.44562 3.9943 4.42057 3.9943C2.39553 3.9943 0.753906 3.39735 0.753906 2.66097M8.08724 2.66097C8.08724 1.92459 6.44562 1.32764 4.42057 1.32764C2.39553 1.32764 0.753906 1.92459 0.753906 2.66097M8.08724 2.66097V3.66097M0.753906 2.66097V10.661C0.753906 11.3973 2.39553 11.9943 4.42057 11.9943M4.42057 6.66097C4.30821 6.66097 4.19703 6.65913 4.08724 6.65553C2.21841 6.59429 0.753906 6.02316 0.753906 5.32764M4.42057 9.32764C2.39553 9.32764 0.753906 8.73068 0.753906 7.9943M14.0872 6.9943C14.0872 7.73068 12.4456 8.32764 10.4206 8.32764C8.39553 8.32764 6.75391 7.73068 6.75391 6.9943M14.0872 6.9943C14.0872 6.25792 12.4456 5.66097 10.4206 5.66097C8.39553 5.66097 6.75391 6.25792 6.75391 6.9943M14.0872 6.9943V11.9943C14.0872 12.7307 12.4456 13.3276 10.4206 13.3276C8.39553 13.3276 6.75391 12.7307 6.75391 11.9943V6.9943M14.0872 9.4943C14.0872 10.2307 12.4456 10.8276 10.4206 10.8276C8.39553 10.8276 6.75391 10.2307 6.75391 9.4943"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
