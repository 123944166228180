import { useMatomo } from "@jonkoops/matomo-tracker-react";
import ReactGA from "react-ga";
import { T } from "@tolgee/react";

function InvestorsBox() {
  const { trackEvent } = useMatomo();

  const openCalendlyModal = () => {
    const event = new CustomEvent("openCalendlyModal");
    window.dispatchEvent(event);
  };

  const onButtonClick = (type: "features" | "book-call") => {
    trackEvent({ category: "investors box", action: `${type} button clicked` });
    ReactGA.event({
      category: "investors box",
      action: `${type} button clicked`,
      label: "Investors box",
      nonInteraction: true,
    });
    if (type === "book-call") {
      openCalendlyModal();
    }
  };

  return (
    <div
      className="my-8 md:my-20 py-9 md:p-5"
      style={{
        background:
          "linear-gradient(99deg, #312E81 0%, #1E40AF 0.01%, #1E3A8A 46%, #384AEA 96.97%)",
      }}
    >
      <div className="flex flex-col md:flex-row justify-between items-center container box max-w-screen-xl m-auto">
        <div className={"w-full pl-6"}>
          <p className="text-white text-left text-xl font-bold">
            <T keyName={"more_info_title"} />
          </p>
          <p className="mt-3 text-white text-left text-md font-medium">
            <T keyName={"more_info_desc"} />
          </p>
        </div>
        <div className={"w-full justify-end flex pr-6"}>
          <button
            onClick={() => onButtonClick("book-call")}
            className={
              "text-accent min-h-[48px] flex items-center text-md font-medium md:pl-6 md:pr-6 justify-center  py-3 max-sm:w-full max-sm:mt-6 max-sm:ml-6 max-sm:h-10 bg-white rounded-md"
            }
          >
            <T keyName={"book_a_call"} />
            <svg
              className={"relative "}
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M16.3673 10.6569L12.4173 6.70692C12.2351 6.51832 12.1343 6.26571 12.1366 6.00352C12.1389 5.74132 12.244 5.49051 12.4294 5.3051C12.6148 5.11969 12.8657 5.01452 13.1278 5.01224C13.39 5.00997 13.6426 5.11076 13.8313 5.29292L19.4882 10.9499C19.5814 11.0426 19.6554 11.1527 19.7058 11.2741C19.7563 11.3954 19.7823 11.5255 19.7823 11.6569C19.7823 11.7883 19.7563 11.9184 19.7058 12.0398C19.6554 12.1611 19.5814 12.2713 19.4882 12.3639L13.8313 18.0209C13.739 18.1164 13.6287 18.1926 13.5067 18.245C13.3847 18.2974 13.2534 18.325 13.1207 18.3262C12.9879 18.3273 12.8562 18.302 12.7333 18.2517C12.6104 18.2015 12.4987 18.1272 12.4049 18.0333C12.311 17.9394 12.2367 17.8278 12.1864 17.7049C12.1361 17.582 12.1108 17.4503 12.112 17.3175C12.1132 17.1847 12.1407 17.0535 12.1931 16.9315C12.2456 16.8095 12.3217 16.6992 12.4173 16.6069L16.3673 12.6569H6.78125C6.51603 12.6569 6.26168 12.5516 6.07414 12.364C5.88661 12.1765 5.78125 11.9221 5.78125 11.6569C5.78125 11.3917 5.88661 11.1373 6.07414 10.9498C6.26168 10.7623 6.51603 10.6569 6.78125 10.6569H16.3673Z"
                fill="#2563EB"
              />
            </svg>
          </button>
        </div>

        {/*<Button text={`Book a call ${<CloseIcon />}`} color="secondary" size="large" className="text-accent px-5 py-6 max-sm:w-full max-sm:h-10" onClick={() => onButtonClick('book-call')}/>*/}
      </div>
    </div>
  );
}

export default InvestorsBox;
