import React from "react";

export const ClientCommunicationIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="35" height="35" rx="8" fill="#F0F5FF" />
      <path
        d="M16 9H12.8C11.1198 9 10.2798 9 9.63803 9.32698C9.07354 9.6146 8.6146 10.0735 8.32698 10.638C8 11.2798 8 12.1198 8 13.8V19C8 19.93 8 20.395 8.10222 20.7765C8.37962 21.8117 9.18827 22.6204 10.2235 22.8978C10.605 23 11.07 23 12 23V25.3355C12 25.8684 12 26.1348 12.1092 26.2716C12.2042 26.3906 12.3483 26.4599 12.5005 26.4597C12.6756 26.4595 12.8837 26.2931 13.2998 25.9602L15.6852 24.0518C16.1725 23.662 16.4162 23.4671 16.6875 23.3285C16.9282 23.2055 17.1844 23.1156 17.4492 23.0613C17.7477 23 18.0597 23 18.6837 23H20.2C21.8802 23 22.7202 23 23.362 22.673C23.9265 22.3854 24.3854 21.9265 24.673 21.362C25 20.7202 25 19.8802 25 18.2V18M25.1213 8.87868C26.2929 10.0503 26.2929 11.9497 25.1213 13.1213C23.9497 14.2929 22.0503 14.2929 20.8787 13.1213C19.7071 11.9497 19.7071 10.0503 20.8787 8.87868C22.0503 7.70711 23.9497 7.70711 25.1213 8.87868Z"
        stroke="#275EF7"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
