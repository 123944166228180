import React from "react";

export const EnterpriseIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_515_10236)">
        <path
          d="M4.3335 8.66659L4.85647 9.71253C5.03346 10.0665 5.12196 10.2435 5.24018 10.3969C5.34509 10.533 5.4671 10.655 5.6032 10.7599C5.75657 10.8781 5.93357 10.9666 6.28755 11.1436L7.3335 11.6666L6.28755 12.1896C5.93357 12.3666 5.75657 12.455 5.6032 12.5733C5.4671 12.6782 5.34509 12.8002 5.24018 12.9363C5.12196 13.0897 5.03346 13.2667 4.85647 13.6206L4.3335 14.6666L3.81052 13.6206C3.63353 13.2667 3.54503 13.0897 3.42681 12.9363C3.3219 12.8002 3.19989 12.6782 3.06379 12.5733C2.91042 12.455 2.73343 12.3666 2.37944 12.1896L1.3335 11.6666L2.37944 11.1436C2.73343 10.9666 2.91042 10.8781 3.06379 10.7599C3.19989 10.655 3.3219 10.533 3.42681 10.3969C3.54503 10.2435 3.63353 10.0665 3.81052 9.71253L4.3335 8.66659Z"
          stroke="#275EF7"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.0002 1.33325L10.7859 3.3762C10.9739 3.86502 11.0679 4.10943 11.2141 4.31501C11.3437 4.49722 11.5029 4.65642 11.6851 4.78598C11.8907 4.93216 12.1351 5.02616 12.6239 5.21417L14.6668 5.99992L12.6239 6.78567C12.1351 6.97367 11.8907 7.06768 11.6851 7.21386C11.5029 7.34342 11.3437 7.50262 11.2141 7.68482C11.0679 7.89041 10.9739 8.13482 10.7859 8.62364L10.0002 10.6666L9.21441 8.62364C9.02641 8.13482 8.9324 7.89041 8.78622 7.68482C8.65666 7.50262 8.49747 7.34342 8.31526 7.21386C8.10967 7.06768 7.86526 6.97367 7.37644 6.78567L5.3335 5.99992L7.37644 5.21417C7.86526 5.02616 8.10967 4.93216 8.31526 4.78598C8.49746 4.65642 8.65666 4.49722 8.78622 4.31501C8.9324 4.10943 9.02641 3.86502 9.21441 3.3762L10.0002 1.33325Z"
          stroke="#275EF7"
          stroke-width="1.2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_515_10236">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
