
import React, {MouseEventHandler, useState} from 'react';
import {CalculateContentModal} from "./CalculateContentModal";


type ModalProps = {
    isOpen: boolean;
    setIsOpen: (b: boolean) => void;
    handleToSignUp?: (s: string) => void
    pricePlan?: string
}
export const PricePlanCalculationModal = ({ isOpen, setIsOpen, handleToSignUp, pricePlan }: ModalProps) => {

    return (
        <CalculateContentModal pricePlan={pricePlan} handleToSignUp={handleToSignUp} key={Math.random()} isOpen={isOpen} closeModal={() => setIsOpen(false)} />
    );
};
