import React, {useState} from "react";
import {cardsData} from "../utils";
import {CheckIcon} from "./icons/CheckIcon";
import {GiftIcon} from "./icons/GiftIcon";
import {RocketIcon} from "./icons/RocketIcon";
import {EnterpriseIcon} from "./EnterpriseIcon";
import {UserIcon} from "./icons/UserIcon";
import {PricePlanCalculationModal} from "../sections/price-list/PricePlanCalculationModal";

type Props = {
  type?: string;
  packageName?: string;
  price?: string;
  prevPrice?: string;
  trial?: string | JSX.Element;
  icon?: JSX.Element | string;
  desc?: string | JSX.Element;
  gift?: boolean;
  individual?: boolean;
  commingSoon?: boolean;
  enterprise?: boolean;
  btnText?: string;
  pricePlanId?: string;
  isMonthly?: boolean;
  referralId?: string;
  free?: boolean;
  startup?: boolean;
  textDesc?: boolean;
  popular?: boolean;
  isIndividual?: boolean;
};

export const PriceListCard = ({
  type,
  packageName,
  price,
  prevPrice,
  trial = "",
  isMonthly,
  referralId,
  textDesc,
  free,
  startup,
  desc,
  gift,
  individual,
  enterprise,
  commingSoon,
  popular,
  btnText = "Choose Plan",
  pricePlanId,
    isIndividual,
}: Props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  // const url = window.location.href
  const affiliateLink = queryParameters.get("via");

  // console.log(affiliateLink)

  const handleToSignUp = (pricePlanId: string) => {
    let url = "";
    if (affiliateLink) {
      url = `https://app.eu.3folio.finance/register?ReferralId=${referralId}&pricePlan=${pricePlanId}`;
    } else {
      url = `https://app.eu.3folio.finance/register?pricePlan=${pricePlanId}`;
    }
    window.open(url, "_blank");
    // window.open(`https://3folio.finance/register?via=${affiliateLink}&pricePlan=${pricePlanId}`, '_blank')
  };

  const handleContact = () => {
    window.open(`https://calendly.com/michael-3folio/meeting-15-min`, "_blank");
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`border md:shadow-none shadow-cards text-center border-[#EDEEF3] rounded-[24px] md:py-10 ${
        !textDesc ? "pt-12" : ""
      } px-3 mx-2 md:mx-0 md:ml-4 md:mb-0 mb-12 relative overflow-hidden before:absolute before:top-[395px] ${isIndividual ? 'before:h-[395px]' : 'before:h-[440px]'} before:bg-[#F5F7FD] before:left-0 before:w-full before:-z-10 before:border-y-2 before:border-y-[#9CB5FB]`}
    >
      {gift && (
        <div
          className="bg-accent flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-white"
        >
          <GiftIcon /> <span className="pl-1 text-[12px]">Beta Phase</span>
        </div>
      )}

      {individual && (
        <div
          className="bg-[#002aa314] flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-[#08267B]"
        >
          <UserIcon />{" "}
          <span className="pl-1 text-[12px] font-semibold">Individual</span>
        </div>
      )}

      {startup && (
        <div
          className="bg-[#002aa314] flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-[#08267B]"
        >
          <span className="pl-1 text-[12px] font-semibold uppercase">
            startups only
          </span>
        </div>
      )}

      {commingSoon && (
        <div
          className="bg-[#768194] flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-white"
        >
          <RocketIcon />{" "}
          <span className="pl-1 text-[12px]">Coming Soon</span>
        </div>
      )}

      {popular && (
        <div
          className="bg-[#002AA30A] flex items-center justify-center p-1.5 absolute top-0 w-full left-0 text-[#275EF7]"
        >
          <EnterpriseIcon />{" "}
          <span className="text-[11px] px-2 py-0.5 font-bold text-[#275EF7]">
            MOST POPULAR
          </span>
        </div>
      )}

      <h1
        className={`font-bold ${enterprise ? "pt-10 md:pt-4" : "pt-4"} ${
          !textDesc ? "mb-4" : ""
        } text-[20px]`}
      >
        {packageName || type}
      </h1>
      {price && (
        <h2 className="font-bold mb-5 text-[20px]">
          <span className="text-[#8D0000] pr-1 font-medium line-through">
            {prevPrice}
          </span>
          {price}{" "}
          <span className="text-[14px] text-[#485066]">
            {enterprise ? "individual" : isMonthly ? "/month" : "/year"}
          </span>
        </h2>
      )}
      {free && (
        <h2 className="font-bold text-accent mb-5 text-[20px]">Free</h2>
      )}
      {textDesc && (
        <div className="flex flex-col mb-4 mt-1 text-[20px]">
          <h3 className="font-semibold text-[#485066] text-[16px]">
            Flexible pricing for every complexity
          </h3>
          <p className="text-[12px] pt-1 text-[#485066]">
            Let's talk to our consulting team.
          </p>
        </div>
      )}
      <p className="text-[#83899F] text-[12px] font-medium h-[114px] mb-5">
        {desc}
      </p>

      <div className="hidden md:block">
        {!enterprise && (
          <button
            onClick={() => {
              !enterprise
                ? handleToSignUp(pricePlanId as string)
                : handleContact();
            }}
            disabled={commingSoon}
            className={`${
              commingSoon
                ? "bg-[#B3B8C9] cursor-not-allowed"
                : "bg-accent w-full"
            } text-white py-4 rounded-md w-full items-center text-center text-[15px] font-semibold`}
          >
            {btnText}
          </button>
        )}
        {enterprise && (
          <button
            onClick={() => handleToSignUp(pricePlanId as string)}
            disabled={commingSoon}
            className='py-4 bg-accent text-white w-full rounded-md items-center text-center text-[15px] font-semibold'
          >
            Contact us
          </button>
        )}

        {/* {enterprise && (
          <button
            onClick={() => {
              setIsOpen(true);
            }}
            disabled={commingSoon}
            className={`bg-white mt-2  border border-accent text-accent h-[54px] w-full py-4 rounded-md items-center text-center text-[15px] font-semibold`}
          >
            Calculate subscription fee
          </button>
        )} */}

        {trial ? (
          <p
            className={`text-accent font-bold text-[14px] pt-3 ${
              enterprise ? "h-[33px]" : "h-[94px]"
            }`}
          >
            {trial}
          </p>
        ) : !trial && !enterprise ? (
          <p className="h-[94px]"></p>
        ) : (
          <p className="h-[33px]"></p>
        )}
        {enterprise && <div className="mt-2 h-[54px] w-full py-4"></div>}

        {/* CRYPTO FUNDS */}

        {type === "Crypto Funds Starter" &&
          cardsData.cryptoFundsStarter.coreFunctions.map(
            (core: any, i: number) => (
              <p
                key={`free-core-${i}`}
                className={`pt-${
                  i === 0 ? "[65px]" : "3.5"
                } flex justify-center items-center  text-[14px] font-medium text-[#454545] border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Crypto Funds Starter" && <div className="h-[108px]"></div>}
        {type === "Crypto Funds Starter" &&
          cardsData.cryptoFundsStarter.importOptions.map(
            (core: any, i: any) => (
              <p
                key={`free-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Crypto Funds Starter" && <div className={"pt-[105px]"}></div>}
        {type === "Crypto Funds Starter" &&
          cardsData.cryptoFundsStarter.assetOptions.map((core: any, i: any) => (
            <p
              key={`free-import-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}
        {type === "Crypto Funds Starter" && <div className={"pt-[79px]"}></div>}
        {type === "Crypto Funds Starter" &&
          cardsData.cryptoFundsStarter.functionsTracking.map(
            (core: any, i: any) => (
              <p
                key={`free-functTracking-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Crypto Funds Starter" && <div className={"pt-[79px]"}></div>}
        {type === "Crypto Funds Starter" &&
          cardsData.cryptoFundsStarter.functionsAccounting.map(
            (core: any, i: any) => (
              <p
                key={`free-funcAcc-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Crypto Funds Starter" && (
          <div className={"pt-[78.5px]"}></div>
        )}
        {type === "Crypto Funds Starter" &&
          cardsData.cryptoFundsStarter.functionsReporting.map(
            (core: any, i: any) => (
              <p
                key={`free-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Crypto Funds Starter" && (
          <div className={"pt-[107px]"}></div>
        )}
        {type === "Crypto Funds Starter" &&
          cardsData.cryptoFundsStarter.payPerUse.map((core: any, i: any) => (
            <p
              key={`free-funcReport-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}

        {type === "Fund Orbit" &&
          cardsData.cryptoFundsAdvanced.coreFunctions.map(
            (core: any, i: number) => (
              <p
                key={`adopter-fore-${i}`}
                className={`pt-${
                  i === 0 ? "[65px]" : "3.5"
                } flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Fund Orbit" && <div className={"h-[108px]"}></div>}
        {type === "Fund Orbit" &&
          cardsData.cryptoFundsAdvanced.importOptions.map(
            (core: any, i: any) => (
              <p
                key={`adopter-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Fund Orbit" && <div className={"pt-[105px]"}></div>}
        {type === "Fund Orbit" &&
          cardsData.cryptoFundsStarter.assetOptions.map((core: any, i: any) => (
            <p
              key={`free-import-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}
        {type === "Fund Orbit" && <div className={"pt-[79px]"}></div>}
        {type === "Fund Orbit" &&
          cardsData.cryptoFundsAdvanced.functionsTracking.map(
            (core: any, i: any) => (
              <p
                key={`adopter-funcTrack-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Fund Orbit" && <div className={"pt-[79px]"}></div>}
        {type === "Fund Orbit" &&
          cardsData.cryptoFundsAdvanced.functionsAccounting.map(
            (core: any, i: any) => (
              <p
                key={`adopter-funcAcc-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Fund Orbit" && <div className={"pt-[78.5px]"}></div>}
        {type === "Fund Orbit" &&
          cardsData.cryptoFundsAdvanced.functionsReporting.map(
            (core: any, i: any) => (
              <p
                key={`adopter-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Fund Orbit" && <div className={"pt-[107px]"}></div>}
        {type === "Fund Orbit" &&
          cardsData.cryptoFundsAdvanced.payPerUse.map((core: any, i: any) => (
            <p
              key={`adopter-funcReport-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}

        {type === "Enterprise Suite" &&
          cardsData.cryptoFundsEnterprise.coreFunctions.map(
            (core: any, i: number) => (
              <p
                key={`advanced-core-${i}`}
                className={`pt-${
                  i === 0 ? "[65px]" : "3.5"
                } flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Enterprise Suite" && <div className={"h-[108px]"}></div>}
        {type === "Enterprise Suite" &&
          cardsData.cryptoFundsEnterprise.importOptions.map(
            (core: any, i: any) => (
              <p
                key={`advanced-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Enterprise Suite" && <div className={"pt-[105px]"}></div>}
        {type === "Enterprise Suite" &&
          cardsData.cryptoFundsEnterprise.assetOptions.map(
            (core: any, i: any) => (
              <p
                key={`free-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Enterprise Suite" && <div className={"pt-[79px]"}></div>}
        {type === "Enterprise Suite" &&
          cardsData.cryptoFundsEnterprise.functionsTracking.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcTrack-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Enterprise Suite" && <div className={"pt-[79px]"}></div>}
        {type === "Enterprise Suite" &&
          cardsData.cryptoFundsEnterprise.functionsAccounting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcAcc-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Enterprise Suite" && <div className={"pt-[78.5px]"}></div>}
        {type === "Enterprise Suite" &&
          cardsData.cryptoFundsEnterprise.functionsReporting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Enterprise Suite" && <div className={"pt-[108px]"}></div>}
        {type === "Enterprise Suite" &&
          cardsData.cryptoFundsEnterprise.payPerUse.map((core: any, i: any) => (
            <p
              key={`advanced-funcReport-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}

        {/* INDIVIDUALS */}

        {type === "Tracker" &&
          cardsData.individualsCommunity.coreFunctions.map(
            (core: any, i: number) => (
              <p
                key={`advanced-core-${i}`}
                className={`pt-${
                  i === 0 ? "[65px]" : "3.5"
                } flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker" && <div className={"h-[108px]"}></div>}
        {type === "Tracker" &&
          cardsData.individualsCommunity.importOptions.map(
            (core: any, i: any) => (
              <p
                key={`advanced-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker" && <div className={"pt-[105px]"}></div>}
        {type === "Tracker" &&
          cardsData.individualsCommunity.assetOptions.map(
            (core: any, i: any) => (
              <p
                key={`free-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker" && <div className={"pt-[79px]"}></div>}
        {type === "Tracker" &&
          cardsData.individualsCommunity.functionsTracking.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcTrack-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker" && <div className={"pt-[79px]"}></div>}
        {type === "Tracker" &&
          cardsData.individualsCommunity.functionsAccounting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcAcc-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker" && <div className={"pt-[105px]"}></div>}
        {type === "Tracker" &&
          cardsData.individualsCommunity.functionsReporting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker" && <div className={"pt-[108px]"}></div>}
        {type === "Tracker" &&
          cardsData.individualsCommunity.payPerUse.map((core: any, i: any) => (
            <p
              key={`advanced-funcReport-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}

        {type === "Tracker Pro" &&
          cardsData.individualsStarter.coreFunctions.map(
            (core: any, i: number) => (
              <p
                key={`advanced-core-${i}`}
                className={`pt-${
                  i === 0 ? "[65px]" : "3.5"
                } flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker Pro" && <div className={"h-[108px]"}></div>}
        {type === "Tracker Pro" &&
          cardsData.individualsStarter.importOptions.map(
            (core: any, i: any) => (
              <p
                key={`advanced-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker Pro" && <div className={"pt-[105px]"}></div>}
        {type === "Tracker Pro" &&
          cardsData.individualsStarter.assetOptions.map((core: any, i: any) => (
            <p
              key={`free-import-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}
        {type === "Tracker Pro" && <div className={"pt-[79px]"}></div>}
        {type === "Tracker Pro" &&
          cardsData.individualsStarter.functionsTracking.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcTrack-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker Pro" && <div className={"pt-[79px]"}></div>}
        {type === "Tracker Pro" &&
          cardsData.individualsStarter.functionsAccounting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcAcc-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker Pro" && <div className={"pt-[105px]"}></div>}
        {type === "Tracker Pro" &&
          cardsData.individualsStarter.functionsReporting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Tracker Pro" && <div className={"pt-[108px]"}></div>}
        {type === "Tracker Pro" &&
          cardsData.individualsStarter.payPerUse.map((core: any, i: any) => (
            <p
              key={`advanced-funcReport-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}

        {type === "Individuals Advanced" &&
          cardsData.individualsAdvanced.coreFunctions.map(
            (core: any, i: number) => (
              <p
                key={`starter-core-${i}`}
                className={`pt-${
                  i === 0 ? "[65px]" : "3.5"
                } flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Individuals Advanced" && <div className={"h-[108px]"}></div>}
        {type === "Individuals Advanced" &&
          cardsData.individualsAdvanced.importOptions.map(
            (core: any, i: any) => (
              <p
                key={`starter-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Individuals Advanced" && <div className={"pt-[105px]"}></div>}
        {type === "Individuals Advanced" &&
          cardsData.cryptoFundsStarter.assetOptions.map((core: any, i: any) => (
            <p
              key={`free-import-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}
        {type === "Individuals Advanced" && <div className={"pt-[79px]"}></div>}
        {type === "Individuals Advanced" &&
          cardsData.individualsAdvanced.functionsTracking.map(
            (core: any, i: any) => (
              <p
                key={`starter-funcTrack-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Individuals Advanced" && <div className={"pt-[79px]"}></div>}
        {type === "Individuals Advanced" &&
          cardsData.individualsAdvanced.functionsAccounting.map(
            (core: any, i: any) => (
              <p
                key={`starter-funcAcc-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Individuals Advanced" && (
          <div className={"pt-[105px]"}></div>
        )}
        {type === "Individuals Advanced" &&
          cardsData.individualsAdvanced.functionsReporting.map(
            (core: any, i: any) => (
              <p
                key={`starter-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Individuals Advanced" && (
          <div className={"pt-[107px]"}></div>
        )}
        {type === "Individuals Advanced" &&
          cardsData.individualsAdvanced.payPerUse.map((core: any, i: any) => (
            <p
              key={`starter-funcReport-${i}`}
              className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                core.index === "coming soon" ? "text-accent" : "text-[#454545]"
              }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                core.index === "check" && "min-h-[44px]"
              }`}
            >
              <>{core.index === "check" ? <CheckIcon /> : core.index}</>
            </p>
          ))}

        {type === "Accounting Pro" &&
          cardsData.individualsProfessional.coreFunctions.map(
            (core: any, i: number) => (
              <p
                key={`advanced-core-${i}`}
                className={`pt-${
                  i === 0 ? "[65px]" : "3.5"
                } flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Accounting Pro" && <div className={"h-[108px]"}></div>}
        {type === "Accounting Pro" &&
          cardsData.individualsProfessional.importOptions.map(
            (core: any, i: any) => (
              <p
                key={`advanced-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Accounting Pro" && <div className={"pt-[105px]"}></div>}
        {type === "Accounting Pro" &&
          cardsData.individualsProfessional.assetOptions.map(
            (core: any, i: any) => (
              <p
                key={`free-import-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                } border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Accounting Pro" && <div className={"pt-[79px]"}></div>}
        {type === "Accounting Pro" &&
          cardsData.individualsProfessional.functionsTracking.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcTrack-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Accounting Pro" && <div className={"pt-[79px]"}></div>}
        {type === "Accounting Pro" &&
          cardsData.individualsProfessional.functionsAccounting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcAcc-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px] min-h-[44px]  ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Accounting Pro" && <div className={"pt-[105px]"}></div>}
        {type === "Accounting Pro" &&
          cardsData.individualsProfessional.functionsReporting.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
        {type === "Accounting Pro" && <div className={"pt-[108px]"}></div>}
        {type === "Accounting Pro" &&
          cardsData.individualsProfessional.payPerUse.map(
            (core: any, i: any) => (
              <p
                key={`advanced-funcReport-${i}`}
                className={`pt-3.5 flex justify-center items-center  text-[14px] font-medium  ${
                  core.index === "coming soon"
                    ? "text-accent"
                    : "text-[#454545]"
                }  border-[#EDEEF3] text-center pb-2 border-b-[0.5px]  min-h-[44px] ${
                  core.index === "check" && "min-h-[44px]"
                }`}
              >
                <>{core.index === "check" ? <CheckIcon /> : core.index}</>
              </p>
            )
          )}
      </div>

      <PricePlanCalculationModal
        pricePlan={pricePlanId}
        handleToSignUp={handleToSignUp}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        key={Math.random()}
      />
    </div>
  );
};
