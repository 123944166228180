import {ServerIcon} from "../../components/icons/ServerIcon";
import {AddOnsIcon} from "../../components/icons/AddOnsIcon";
import {GuardIcon} from "../../components/icons/GuardIcon";
import {CloudIcon} from "../../components/icons/CloudIcon";
import {GraphIcon} from "../../components/icons/GraphIcon";
import {T} from "@tolgee/react";
import {FileIcon} from "../../components/icons/FileIcon";

function FeaturesDetailed() {
  const features = [
    {
      title: "dedicated_infrastructure",
      description: "dedicated_infrastructure_desc",
      icon: <ServerIcon />,
      text: "active",
      time: "",
      bgColor: "#1ab20017",
      color: "#1AB200",
    },
    {
      title: "trusted_staking_validators",
      description: "trusted_staking_validators_desc",
      icon: <GuardIcon />,
      text: "active",
      time: "",
      bgColor: "#1ab20017",
      color: "#1AB200",
    },
    {
      title: "crypto_fund_add_on",
      description: "crypto_fund_add_on_desc",
      icon: <FileIcon />,
      text: "active",
      time: "",
      bgColor: "#1ab20017",
      color: "#1AB200",
    },
    {
      title: "commission_calculator",
      description: "commission_calculator_desc",
      icon: <AddOnsIcon />,
      text: "active",
      time: "",
      bgColor: "#1ab20017",
      color: "#1AB200",
    },
    {
      title: "enterprise_api",
      description: "enterprise_api_desc",
      icon: <CloudIcon />,
      text: "planned",
      time: "(Q3, 2025)",
      bgColor: "#83899f1a",
      color: "#454545",
    },
    {
      title: "transaction_graph_viewer",
      description: "transaction_graph_viewer_desc",
      icon: <GraphIcon />,
      text: "planned",
      time: "(Q4, 2025)",
      bgColor: "#83899f1a",
      color: "#454545",
    }
  ];

  return (
    <div
      className="max-w-screen-xl box m-auto md:mb-16 lg:mb-32 mb-16 max-xl:p-5 pt-10"
      id="roadmap"
    >
      <h1 className="text-[24px] font-bold text-[#101828] mb-5">
        <T keyName={"special_extensions_for"} />{" "}
        <span className={"text-accent"}>
          "<T keyName={"power_users"} />"
        </span>
      </h1>
      <p
        className={
          "text-sm md:text-normal lg:text-lg font-medium text-[#667085]"
        }
      >
        <T keyName={"feature_main_subtitle"} /> 🚀
      </p>
      {/*<p className="font-medium text-slate-400 mb-10">*/}
      {/*Our tool grows organically. New features are constantly emerging and new blockchain protocols will be supported.<br/> To keep up the pace, we always have an eye on the needs of our customers. <br/>This is just the beginning of our Journey!*/}

      {/*</p>*/}
      <div className="flex justify-items-stretch mt-8 flex-wrap justify-center">
        {features.map((feature, i) => (
          <div
            className="flex flex-col items-center w-full sm:w-6/12 lg:w-3/12 mb-4"
            key={"problemCard-" + i}
          >
            <div
              className="flex flex-col items-center h-full justify-start p-7 mx-2 rounded-[16px] shadow-cards"
            >
              <div
                className={`flex p-4 rounded-md  ${
                  i === 1 || i === 3 || i === 4 || i === 6
                    ? "bg-accent"
                    : "bg-[#F0F5FF]"
                }`}
              >
                {feature.icon}
              </div>
              <h2 className="font-semibold text-[#061C3D] text-[18px] pt-4">
                <T keyName={feature.title} />
              </h2>
              <p className="text-[#42526B] text-[14px] pt-3 md:w-10/12 lg:w-full">
                <T keyName={feature.description} />
              </p>
              <div className={"flex my-auto items-end flex-1 pt-4"}>
                <p
                  className={`rounded-[4px] py-0.5 px-2.5 bg-[${feature.bgColor}] text-[${feature.color}] text-[12px] font-semibold`}
                  style={{
                    backgroundColor: feature.bgColor,
                    color: feature.color,
                  }}
                >
                  <T keyName={feature.text} /> {feature.time}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FeaturesDetailed;
