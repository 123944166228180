import React from "react";

export const DataRecordingIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="35" height="35" rx="8" fill="#F0F5FF" />
      <path
        d="M28.7 17.5L26.7005 19.5L24.7 17.5M26.9451 19C26.9814 18.6717 27 18.338 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27C20.8273 27 23.35 25.6963 25 23.6573M18 13V18L21 20"
        stroke="#317BFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
