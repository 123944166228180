import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { toast } from "react-toastify";
import HeroCarousel from "../../components/HeroCarousel";
import ReactGA from "react-ga";
import React from "react";
import { T, useTranslate } from "@tolgee/react";
import { CryptoFundsIcon } from "../../components/icons/CryptoFundsIcon";
import { IndividualIcon } from "../../components/icons/IndividualIcon";

function HeroSection() {
  const { trackEvent } = useMatomo();

  // TODO: Load this from a CMS
  const originLogos = [
    "DEMO/logos/madein.png",
    "DEMO/logos/gdpr.png",
  ];
  const videoComingSoon = () => {
    trackEvent({ category: "hero section", action: "video-available-soon" });
    ReactGA.event({
      category: "hero section",
      action: `video-available-soon`,
      label: "Video Comming soon",
      nonInteraction: true,
    });
    toast.info("The video of our product will be available soon.", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      progress: undefined,
      theme: "light",
    });
  };

  const goToSignUp = () => {
    ReactGA.event({
      category: "hero section",
      action: `go-to-sign-up`,
      label: "Sign Up - Hero",
      nonInteraction: true,
    });
    window.open("https://app.eu.3folio.finance/register", "_blank");
  };

  const goToPricingCryptoFunds = () => {
    ReactGA.event({
      category: "hero section",
      action: `go-to-pricing-crypto-funds`,
      label: "Crypto Funds - Hero",
      nonInteraction: true,
    });
    window.open("https://www.3folio.finance/pricing", "_blank");
  };

  const goToPricingIndividuals = () => {
    ReactGA.event({
      category: "hero section",
      action: `go-to-pricing-individuals`,
      label: "Individuals - Hero",
      nonInteraction: true,
    });
    window.open("https://www.3folio.finance/pricing", "_blank");
  };

  const t = useTranslate();

  return (
    <div className="px-10 h-full max-h-content pt-4 pb-6 md:pt-16 lg:pt-32 md:pb-16 lg:pb-20">
      <div className="max-w-screen-2xl mx-auto flex h-full justify-between items-center px-10">
        <div className={"w-full lg:w-1/2"}>
          <HeroCarousel />
          <h1 className="mb-2 text-[18px] md:text-[24px] lg:text-[37px] text-[#170F49] leading-[18px] md:leading-[40px] font-bold text-left max-md:text-center max-md:pt-4">
            <T keyName="header_enterprise" />
          </h1>

          <p className="text-[14px] lg:text-[18px] leading-[20px] md:leading-[26px] text-[#6F6C90] max-sm:w-10/12 max-sm:mx-auto text-left max-md:text-center">
            <T keyName="hero_desc" />
          </p>
          <div className="flex flex-col md:flex-row gap-2 md:gap-3 font-medium mt-[20px] md:mt-[40px] border-b pb-10 w-full md:w-fit">
            <div
                className="w-full md:w-fit flex max-h-[50px] min-h-[50px] cursor-pointer py-3 px-10 justify-center items-center gap-[10px] rounded-lg block mx-0 max-md:mx-auto transition duration-500 bg-transparent text-accent text-sm md:text-regular hover:bg-[#275EF71A] border border-blue-500 border-solid"
                onClick={goToPricingIndividuals}
            >
              <IndividualIcon color={"#257EF7"} />
              {<T keyName="register_whitelist_2" />}
            </div>
            <div
              className="w-full md:w-fit flex max-h-[50px] min-h-[50px] cursor-pointer py-3 px-10 justify-center items-center gap-[10px] bg-accent text-white text-sm md:text-regular rounded-lg block mx-0 max-md:mx-auto hover:bg-blue-700 transition duration-500"
              onClick={goToPricingCryptoFunds}
            >
              <CryptoFundsIcon color={"white"} />
              {<T keyName="register_whitelist" />}
            </div>
          </div>
          <div className="inline-block justify-center md:justify-left md:text-left w-full text-center pt-3 mt-2">
            {originLogos.map((logo, i) => (
              <img
                src={logo}
                alt="logo"
                className="inline grayscale w-auto max-h-[60px] mt-3 mr-6 ml-0"
                key={"originLogo-" + i}
              />
            ))}
          </div>
        </div>
        <div className="relative w-full lg:w-1/2 hidden lg:flex justify-end items-start">
          <img
            src="./hero-image.svg"
            className="max-w-[550px] w-full max-md:w-full max-md:px-10"
            alt="Product illustration"
          />
          {/*<img src="VideoButton.svg" className="absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-1/2 cursor-pointer hover:scale-105 active:scale-95 transition-transform" alt="Video button" onClick={videoComingSoon}/>*/}
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
