import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { ToggleWithText } from "../../components/ToggleWithText";

import { T } from "@tolgee/react";
import { CryptoFundsPriceList } from "../../components/CryptoFundsPriceList";
import { CryptoFundsIcon } from "../../components/icons/CryptoFundsIcon";
import { IndividualIcon } from "../../components/icons/IndividualIcon";
import { IndividualsPriceList } from "../../components/IndividualsPriceList";
declare global {
  interface Window {
    Rewardful: {
      referral: string | null;
      ready(callback: () => void): void;
    };
  }
}

export const PriceList = () => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [isIndividual, setIsIndividual] = useState(true);
  const [referralId, setReferralId] = useState("");

  const handleFormData = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    setIsMonthly(!isMonthly);
  };

  useEffect(() => {
    // Ensure that the Rewardful object is available in the global scope
    if (window.Rewardful) {
      window.Rewardful.ready(() => {
        if (window.Rewardful.referral) {
          console.log("Current referral ID: ", window.Rewardful.referral);
          setReferralId(window.Rewardful.referral);
        } else {
        }
      });
    } else {
      console.log("Rewardful not initialized.");
    }
  }, []);

  return (
    <>
      <Header />

      <div className="mx-auto max-w-screen-xl container w-full px-2 flex justify-center flex-col xl max-h-max max-lg:px-2 md:pb-20 pb-5 ">
        <h1 className="text-5xl flex md:flex-row flex-col justify-center text-center text-[#0A1B39] text-[24px] font-bold pt-40 -mt-[90px] font-tt max-md:text-3xl md:w-[70%] md:ml-[15%]">
          <T keyName={"pricing_plan_title"} />{" "}
          <span className={"pl-1.5 text-[#317BFF]"}>
            <T keyName={"pricing_plan_title_blue"} />
          </span>
        </h1>
        <div className={"text-center relative justify-center w-full"}>
          <div className={"flex justify-center "}>
            <p className="md:flex justify-center text-l text-center text-[18px] font-medium text-[#454545] px-2 max-sm:w-10/12 max-sm:mx-auto mt-3">
              <T keyName={"pricing_plan_subtitle"} />
            </p>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-center w-full gap-[6px] mt-5">
          <div className="w-full md:w-1/3"></div>
          <div className="w-full md:w-1/3 flex justify-center gap-2 grow">
            <div
                onClick={() => {
                  setIsIndividual(true);
                }}
                className={`md:min-w-fit flex items-center justify-center gap-2 rounded-[10px] cursor-pointer px-[20px] py-[10px] text-sm font-semibold ${
                    isIndividual
                        ? "bg-[#275EF7] text-white"
                        : "text-[#485066] shadow shadow-[4px_5px_40px_0_rgba(74, 105, 165, 0.12)]"
                }`}
            >
              <IndividualIcon color={isIndividual ? "white" : "#485066"} />
              <T keyName="features_individuals" />
            </div>
            <div
              onClick={() => {
                setIsIndividual(false);
              }}
              className={`md:min-w-fit flex items-center justify-center no-wrap gap-2 rounded-[10px] cursor-pointer px-[20px] py-[10px] text-sm font-semibold ${
                isIndividual
                  ? "text-[#485066] shadow shadow-[4px_5px_40px_0_rgba(74, 105, 165, 0.12)]"
                  : "bg-[#275EF7] text-white"
              }`}
            >
              <CryptoFundsIcon color={isIndividual ? "#485066" : "white"} />
              <T keyName="features_funds" />
            </div>
          </div>
          <div className="w-full md:w-1/3 flex justify-center md:justify-end mt-4 md:mt-0">
            <ToggleWithText
              firstOption="Monthly"
              secondOption="Annually"
              checked={isMonthly}
              id="paymentInterval"
              name="paymentInterval"
              onChange={handleFormData}
            />
          </div>
        </div>
      </div>

      {!isIndividual ? (
        <CryptoFundsPriceList referralId={referralId} isMonthly={isMonthly} />
      ) : (
        <IndividualsPriceList referralId={referralId} isMonthly={isMonthly} />
      )}

      <Footer />
    </>
  );
};
