import React from "react";

export const CustomDots = ({ onMove, index, onClick, active }) => {
  return (
    <div
      className={"px-1 text-center cursor-pointer"}
      onClick={() => onClick()}
    >
      <div
        className={`flex ${
          active == index ? "w-[32px] bg-[#0B63E5]" : "w-[8px] bg-[#CEE0FA]"
        } h-[8px] rounded-full `}
      ></div>
    </div>
  );
};
